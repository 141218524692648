import { store } from 'store';
import * as text from 'text-content';

export const agileHelper = {
  /**
   * Build a column id/deal phase from a column title.
   *
   * These are also used as deal phases, and we rebuild these to text in some places,
   * that's why we create readable ids.
   */
  buildColumnTitleToPhase: (name) => {
    try {
      return name
        .toString()
        .toLowerCase()
        .replace(/[^a-zåäö0-9 -]/gi, '')
        .trim()
        .replace(/\s+/g, '-----');
    } catch (err) {
      console.error('Error in buildColumnIdFromName', err);
      return null;
    }
  },
  /**
   * Build a column title from a deal phase.
   */
  buildPhaseToColumnTitle: (phase) => {
    if (!phase) {
      return '';
    }

    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    switch (phase) {
      case 'idle':
        // This phase/column should always exist in column structure, where all deals start before movement.
        return tc.idle;
      case 'todo':
        // This was a static column in bilprospekt-2.0.
        return tc.todo;
      case 'contacted':
        // This was a static column in bilprospekt-2.0.
        return tc.contacted;
      case 'negotiation':
        // This was a static column in bilprospekt-2.0.
        return tc.negotiation;
      case 'prospects':
        // This phase/column should always exist in column structure.
        return tc.prospects;
      case 'won':
        // We don't display these in Bearbeta columns, but still return static translation here.
        return tc.won;
      case 'lost':
        // We don't display these in Bearbeta columns, but still return static translation here.
        return tc.lost;
      case 'trash':
        // We don't display these in Bearbeta columns, but still return static translation here.
        return tc.trash;
      default:
        // The rest we decipher by buildColumnTitleToPhase() pattern.
        return (
          phase.charAt(0).toUpperCase() + phase.slice(1).replace(/-----/gi, ' ')
        );
    }
  },
  getColumnSortValues: [
    // "alphabeticAsc",
    // "alphabeticDesc",
    'createdAsc',
    'createdDesc',
    'updatedAsc',
    'updatedDesc',
  ],
  getLeadsUploadColumns: [
    'address',
    'brand',
    'campaign',
    'city',
    'company',
    'contactComment',
    'contactEmail',
    'contactName',
    'contactPhone',
    'email',
    'expireDate',
    'interestedOfModel',
    'lastContact',
    'leadsStatus',
    'leadsText',
    'leadsType',
    'name',
    'orgNr',
    'phone',
    'regNr',
    'retailer',
    'zipCode',
  ], // Should correlate to leads_schema in backend, but skip fields for internal use like "source" and "deleted".
  getLostDealReasons: [
    'did_lose_price',
    'did_lose_product',
    'did_lose_window',
    'did_lose_unknown',
    'did_lose_custom_reason',
  ],
  getLostDealReasonsReadable: (val) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    switch (val) {
      case 'did_lose_custom_reason':
        return tc.didLoseCustomReasonShort;
      case 'did_lose_price':
        return tc.didLosePriceShort;
      case 'did_lose_unknown':
        return tc.didLoseUnknownShort;
      case 'did_lose_window':
        return tc.didLoseWindowShort;
      case 'did_lose_product':
        return tc.didLoseProductShort;
      default:
        return null;
    }
  },
};
