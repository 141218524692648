import { store } from 'store';
import { activityHelper, agileHelper, request, requestBody } from 'helpers';
import personHelper from 'shared_helpers/person_helper';
import { agileActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getFleetSummary } from 'store/fleet/tasks';
import { getContacts } from 'store/contacts/tasks';
import { addEntityToContacts } from 'store/contacts/tasks';
import companyHelper from 'shared_helpers/company_helper';
import sharedAgileHelper from 'shared_helpers/agile_helper';
import { debounce } from 'debounce';
import * as text from 'text-content';

/**
 * Add activity to a deal. Either historic/performed or planned.
 *
 * @param payload.action - string
 * @param payload.comment - string (optional)
 * @param payload.dealId - string
 * @param payload.event_date - date
 * @param payload.performed - bool
 */
export const addActivity = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      !payload?.dealId ||
      !payload?.action ||
      !payload?.event_date
    ) {
      return console.error('Missing params in addActivity', payload);
    }

    let data = await request({
      data: {
        action: payload.action,
        comment: payload.comment,
        dealId: payload.dealId,
        event_date: payload.event_date,
        date_created: new Date(),
      },
      method: 'post',
      url: payload.performed ? '/deals/actions/' : 'deals/events',
    });

    if (data instanceof Error) {
      console.error('Could not add activity in addActivity', data);
    }

    showFlashMessage(tc.activityHasBeenSaved, 'success');

    return await getColumnsData({});
  } catch (err) {
    return console.error('Error in addActivity', err);
  }
};

/**
 * Check in db if a phase holds any deals.
 * Most likely used to check if we can safely remove a column in Bearbeta.
 *
 * @param payload.phase - string
 */
export const checkIfPhaseHasDeals = async (payload) => {
  try {
    if (!payload || !payload?.phase) {
      console.error('Missing params in checkIfPhaseHasDeals');
      return true; // If any problem, just return true so we dont think we can safely remove a column.
    }

    const data = await request({
      data: {
        phase: payload.phase,
      },
      method: 'get',
      url: '/agile/checkIfPhaseHasDeals/',
    });

    if (data instanceof Error) {
      console.error('Error in checkIfPhaseHasDeals', data);
      return true; // If any problem, just return true so we dont think we can safely remove a column.
    }

    return data.result;
  } catch (err) {
    console.error('Error in checkIfPhaseHasDeals', err);
    return true; // If any problem, just return true so we dont think we can safely remove a column.
  }
};

/**
 * Create a deal.
 *
 * @param payload.car - string (optional)
 * @param payload.description - string (optional)
 * @param payload.files - array (optional)
 * @param payload.listId - string (optional)- If created from a list, I.E. when a prospect is dragged in Bearbeta.
 * @param payload.potential - number (optional) - Possible monetary value for deal.
 * @param payload.prospectId
 * @param payload.prospectSource - string
 * @param payload.responsible - number (optional) - Defaults to the user that is logged in.
 */
export const createDeal = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  try {
    if (payload.car && !payload.prospectId) {
      const prospectId = await getProspectIdByRegnr(payload.car);
      if (prospectId) payload.prospectId = prospectId;
      else {
        throw new Error(
          'Could not find prospect id for vehicle: ',
          payload.car
        );
      }
    }

    const data = await request({
      data: {
        car: payload.car ? payload.car : null,
        comments: '', // Deprecated property
        contacts: [], // Deprecated property
        description: payload.description ? payload.description : '',
        files: payload.files ? payload.files : [],
        listId: payload.listId ? payload.listId : null,
        name: payload.name ? payload.name : null,
        phase: payload.target || 'idle',
        potential: payload.potential ? payload.potential : null,
        prospectId: payload.prospectId ? payload.prospectId : null,
        prospectSource: payload.prospectSource || null,
        responsible: payload.responsible ? payload.responsible : null,
      },
      method: 'post',
      url: '/deals/',
    });

    if (!data || data instanceof Error) {
      showFlashMessage(tc.couldNotCreateDeal, 'fail');
      return console.error('Could not create deal', data);
    }

    // If contacts was provided, add deal id to these contacts.
    if (payload.contacts && payload.contacts.length && data._id) {
      await addEntityToContacts({
        contacts: payload.contacts,
        entityId: data._id,
        entityType: 'deal',
      });
    }

    showFlashMessage(tc.dealWasCreated, 'success');
    return data;
  } catch (err) {
    showFlashMessage('Affär kunde inte skapas', 'fail');
    return console.error('Error in createDeal', err);
  }
};

/**
 * Filter columns data on search query.
 * This is a pure frontend filter, so we just set a 'hide' property to true on items not passing the filter.
 * We have a check for 'hide' in AgileKanbanBoardItemsList.
 */
const filterColumnsOnSearchQueryDebounced = async (query) => {
  try {
    let columns = JSON.parse(JSON.stringify(store.getState().agile.columns));

    if (!Array.isArray(columns)) {
      return;
    }

    columns.map((column) => {
      if (column.items?.length) {
        column.items.map((item) => {
          item.hide =
            !item?.name ||
            (item.name &&
              !item.name.toLowerCase().includes(query.toLowerCase()));
        });
      }
      return column;
    });

    return store.dispatch({
      type: agileActionTypes.SET_COLUMNS,
      payload: columns,
    });
  } catch (err) {
    return console.error('Error in filterColumnsOnSearchQuery', err);
  }
};

export const filterColumnsOnSearchQuery = debounce(
  filterColumnsOnSearchQueryDebounced,
  500
);

export const resetColumnsData = async () => {
  const agile = store.getState().agile;
  store.dispatch({
    type: agileActionTypes.SET_COLUMNS,
    payload: (agile?.columns || []).map((column) => {
      const { items, ...col } = column;
      return { ...col, items: [] };
    }),
  });
};

/**
 * Get columns for agile.
 * If no column structure in store state, get column structure from backend first, and then map deals and prospects accordingly.
 *
 * This is the main function we use to receive data, we also set columns data to store state here.
 * Basically we collect deals from backend, then we retrieve saved agile column structure, and then we retrieve saved sorting value.
 * After that we map columns where deal phase match column id. Lastly we sort the columns.
 *
 * @param payload.loading - bool (optional)
 */
export const getColumnsData = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (store.getState().agile.loading) {
      return;
    }

    if (payload.loading) {
      // When this is called from userLogin()/checkSession() we use a loading flag to avoid multiple calls from useEffects.
      store.dispatch({
        type: agileActionTypes.SET_LOADING,
        payload: true,
      });
    }

    // Get agile kanban board column structure.
    let columns = JSON.parse(JSON.stringify(store.getState().agile.columns));
    if (!columns || !columns?.length) {
      // No columns in store state, retrieve from backend.
      columns = await getColumnStructure();
    }

    const limit = columns?.find((col) => col.id === 'prospects').items?.length;

    resetColumnsData();

    // Get deals and prospects.
    const data = await request({
      method: 'get',
      url: `/agile/getAgile/?limit=${limit && limit > 100 ? limit : 100}`,
    });

    if (data instanceof Error) {
      console.error('Error in getColumnsData', data);
    }

    // Add/empty items array to each column.
    columns.map((column) => {
      column.items = [];
      return column;
    });

    // Place prospects in prospects column. Holds prospects that hasn't been turned into deals yet.
    if (data?.prospects?.data?.length) {
      const prospectColumn = columns.find((num) => num.id === 'prospects');
      prospectColumn.items = data.prospects.data;
    }

    if (data?.deals?.length) {
      data.deals.map((deal) => {
        if (deal.events && deal.events.length) {
          // Sort events by date.
          deal.events.sort((a, b) => {
            if (a.event_date < b.event_date) {
              return -1;
            } else if (a.event_date > b.event_date) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        if (!deal.name || deal.name === '' || deal.name === ' ') {
          // No name? Check for name in leads info.
          if (deal.meta?.leads?.name) {
            deal.name = deal.meta.leads.name;
          } else {
            deal.name = tc.nameMissing;
          }
        }
        return deal;
      });

      // Place deals in correct column.
      data.deals.forEach((deal) => {
        const column = columns.find((num) => num.id === deal.phase);
        if (column) {
          column.items.push(deal);
        } else {
          // If deals don't have a correlating column, we create a new one.
          // This should of course never be the case, but bugs happen and rather we have this check than customer missing deals.
          columns = columns.concat({
            id: deal.phase,
            items: [deal],
            title: agileHelper.buildPhaseToColumnTitle(deal.phase),
            visible: true,
          });
        }
      });

      // If missingColumn exists and is empty, it should be removed.
      const columnForDealsWithoutColumn = columns.find(
        (num) => num.id === 'missingColumn'
      );
      if (
        columnForDealsWithoutColumn &&
        !columnForDealsWithoutColumn.items?.length
      ) {
        columns = columns.filter((num) => num.id !== 'missingColumn');
      }

      const sortValue = await getSortValue();

      // Sort columns.
      columns = await sortColumns({
        sort: sortValue,
        columns: columns,
        skipUpdateState: false,
      });
    }

    store.dispatch({
      type: agileActionTypes.SET_LOADING,
      payload: false,
    });
    store.dispatch({
      type: agileActionTypes.SET_ALL_PROSPECTS_RECEIVED,
      payload: !data?.prospects?.more,
    });
    return store.dispatch({
      type: agileActionTypes.SET_COLUMNS,
      payload: columns,
    });
  } catch (err) {
    return console.error('Error in getColumnsData', err);
  }
};

/**
 * Get saved agile column structure.
 *
 * Note that we have gone from a static column structure in old frontend to a customizable column structure.
 * Deal phases correlates to column ids.
 *
 * Note:
 * This structure should always contain a 'prospects' and an 'idle' column.
 * This is because we display list prospects that hasn't been turned into deals yet in 'prospects' columns.
 * And we display new deals in 'idle' column.
 *
 * Every time a deal is created without specifying a phase (which is whenever a deal is created outside of dragging it in Bearbeta),
 * it gets the phase 'idle' by default. That's why we always need that column. We could force the user to select a phase/column every
 * time a deal is created, but we also might want to automatically create deals via API etc, where it might be hard to know which
 * column to put the deal into, so a static column is useful.
 */
export const getColumnStructure = async () => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    let data = await request({
      method: 'get',
      url: '/agile/columns/',
    });

    // No columns found in db.
    // This could be a user from old frontend (where users didn't have their own columns)
    // that is logging into the new frontend for the first time...
    if (data instanceof Error || !data) {
      console.error('Could not get columns in getColumnStructure', data);

      // ...so set default column structure with ids that correspond to deal phases in old frontend...
      data = [
        {
          id: 'prospects', // Don't add title for this column.
          visible: true,
        },
        {
          id: 'idle', // Don't add title for this column.
          visible: true,
        },
        {
          id: 'todo',
          title: tc.inContact,
          visible: true,
        },
        {
          id: 'contacted',
          title: tc.contacted,
          visible: true,
        },
        {
          id: 'negotiation',
          title: tc.negotiation,
          visible: true,
        },
      ];

      // ...and save to db.
      await updateColumnStructure(data);
    }

    // Extra guard, these two columns should always exist.
    if (!data.find((column) => column.id === 'prospects')) {
      data.push({
        id: 'prospects',
        visible: true,
      });
    }
    if (!data.find((column) => column.id === 'idle')) {
      data.push({
        id: 'idle',
        visible: true,
      });
    }

    // There have been instances where glitches caused multiple columns with the same id, so extra check.
    let dataUnique: Array<object> = [];
    data.forEach((column) => {
      const found = dataUnique.find((x: any) => x.id == column.id);
      if (!found) {
        dataUnique = dataUnique.concat([column]);
      }
    });

    // We do not save title in db for 'idle' and 'prospects' so add it here.
    // We don't save titles for these columns to db because we want the possibility to rename them in the future.
    dataUnique.map((column: any) => {
      if (column.id === 'prospects') {
        column.title = tc.prospects;
      }

      if (column.id === 'idle') {
        column.title = tc.idle;
      }

      if (!column.hasOwnProperty('visible')) {
        // We added this property later on, so update columns that does not have it.
        column.visible = true;
      }

      return column;
    });

    return dataUnique;
  } catch (err) {
    return console.error('Error in getColumnStructure', err);
  }
};

/**
 * Get agile filter.
 */
export const getAgileFilter = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/agile/getFilters/',
    });

    if (data instanceof Error || !data) {
      data = sharedAgileHelper.getDefaultFilters();
      data.map((num) => {
        num.active = false;
        return num;
      });
    }

    data = data.filter((num) => num.id !== 'include_colleagues'); // We deprecate this filter in 3.0.

    return store.dispatch({ type: agileActionTypes.SET_FILTER, payload: data });
  } catch (err) {
    return console.error('Error in getAgileFilter', err);
  }
};

/**
 * We only receive 100 prospects when collecting agile data from backend.
 * Use this to receive 100 more, exclude existing prospects.
 */
export const getPagedProspects = async () => {
  try {
    let columnsCloned = JSON.parse(
      JSON.stringify(store.getState().agile.columns)
    );
    const prospects = columnsCloned.find((column) => column.id === 'prospects');

    resetColumnsData();

    let data = await request({
      data: {
        excludeProspects: JSON.stringify(
          prospects.items.map((num) => num.prospectId)
        ),
      },
      method: 'post',
      url: '/agile/getAgilePagedProspects/',
    });

    if (data instanceof Error || !data) {
      return console.error('Could not get data in getPagedProspects', data);
    }

    columnsCloned.map((column) => {
      if (column.id === 'prospects') {
        column.items = column.items.concat(data.data);
      }
      return column;
    });

    store.dispatch({
      type: agileActionTypes.SET_ALL_PROSPECTS_RECEIVED,
      payload: !data.more,
    });
    return store.dispatch({
      type: agileActionTypes.SET_COLUMNS,
      payload: columnsCloned,
    });
  } catch (err) {
    return console.error('Error in getPagedProspects', err);
  }
};

/**
 * Get preview data for an item that exists in agile columns.
 * Either for a deal or for a prospect.
 *
 * (FYI we have a preview router in the backend. But we do not use this because it's slow,
 * overkill and cannot properly handle deals with multiple prospects.)
 *
 * @param payload.id - string - Deal id or prospect id.
 * @param payload.updateProspectInformation - bool - When we only want to re-retrieve prospect information. Used for when we have toggled GDPR-consent.
 * @param payload.getSettings - bool - use prospect settings to only get vehicles of certain types (selected in user settings)
 */
export const getPreviewData = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id) {
      console.error('Missing params in getPreviewData', payload);
      return showFlashMessage(tc.couldNotGetPreview, 'info');
    }
    const { getSettings } = payload;

    if (payload.updateProspectInformation) {
      // Only update prospect information for existing preview data.
      const previewData = JSON.parse(
        JSON.stringify(store.getState().agile.previewData)
      );
      if (!previewData?.item) {
        console.error('Could not find existing data in getPreviewData');
        return;
      }

      previewData.prospectInformation = await getPreviewProspectInformation(
        previewData.item,
        getSettings
      );
      return store.dispatch({
        type: agileActionTypes.SET_PREVIEW_DATA,
        payload: previewData,
      });
    }

    store.dispatch({ type: agileActionTypes.SET_PREVIEW_DATA, payload: null });

    let columns = JSON.parse(JSON.stringify(store.getState().agile.columns));
    let previewData: any = {};
    let item: any = {};

    // Get item data directly from agile columns.
    columns.forEach((column) => {
      if (
        column.id === 'prospects' &&
        column.items.find(
          (dealOrProspect) =>
            String(dealOrProspect.prospectId) === String(payload.id)
        )
      ) {
        item = column.items.find(
          (prospect) => String(prospect.prospectId) === String(payload.id)
        );
        item.type = 'prospect';
      }
    });

    columns.forEach((column) => {
      if (
        column.id !== 'prospects' &&
        column.items.find(
          (dealOrProspect) => String(dealOrProspect.id) === String(payload.id)
        )
      ) {
        item = column.items.find(
          (deal) => String(deal.id) === String(payload.id)
        );
        item.type = 'deal';
      }
    });

    if (!Object.keys(item).length) {
      console.error('Could not find item in getPreviewData', payload);
      return showFlashMessage(tc.couldNotGetPreview, 'info');
    }

    previewData.item = item;

    // Save to state to let component start render.
    store.dispatch({
      type: agileActionTypes.SET_PREVIEW_DATA,
      payload: previewData,
    });

    // Get list name.
    if (
      previewData.item.type === 'deal' &&
      previewData.item.meta &&
      previewData.item.meta.moved_from_list
    ) {
      const list = await request({
        method: 'get',
        url: '/lists/getList/' + previewData.item.meta.moved_from_list,
      });
      previewData.item.listName =
        list && !(list instanceof Error) ? list.name : tc.dataMissing;
    } else if (previewData.item.listId) {
      const list = await request({
        method: 'get',
        url: '/lists/getList/' + previewData.item.listId,
      });
      previewData.item.listName =
        list && !(list instanceof Error) ? list.name : tc.dataMissing;
    } else {
      previewData.item.listName = tc.doNotBelongToList;
    }

    // Save to state to let component render added information.
    store.dispatch({
      type: agileActionTypes.SET_PREVIEW_DATA,
      payload: previewData,
    });

    const entityType =
      previewData.item.type === 'deal'
        ? 'deal'
        : previewData.item.type === 'prospect'
        ? 'company'
        : 'car';

    const [prospectInfo, vehicleInfo] = await Promise.all([
      getPreviewProspectInformation(previewData.item),
      getPreviewVehicleInformation(previewData.item),
      getContacts({ entityId: payload.id, entityType }),
    ]);

    const contacts = store.getState().contacts.contacts;

    // Get prospect information.
    previewData.prospectInformation = await getPreviewProspectInformation(
      previewData.item,
      getSettings
    );

    // // Get vehicle information
    // previewData.vehicleInformation = await getPreviewVehicleInformation(
    //   previewData.item
    // );

    // // Get contacts.
    // await getContacts({ entityId: payload.id });
    // previewData.contacts = store.getState().contacts.contacts;

    return store.dispatch({
      type: agileActionTypes.SET_PREVIEW_DATA,
      payload: {
        ...previewData,
        prospectInformation: prospectInfo,
        vehicleInformation: vehicleInfo,
        contacts,
      },
    });
  } catch (err) {
    return console.error('Error in getPreviewData', err);
  }
};

/**
 * Helper function for getPreviewData.
 *
 * @param payload
 */
const getPreviewProspectInformation = async (payload, getSettings = false) => {
  try {
    let prospectPromises;

    if (payload.type === 'deal') {
      const { prospect_id: prospectId } = payload;
      if (companyHelper.isValidOrgNr(prospectId)) {
        prospectPromises = [
          request({
            method: 'get',
            url: '/company/companyBasicInfo/' + prospectId,
          }),
        ];
      } else {
        prospectPromises = [
          request({
            method: 'get',
            url: '/privatePerson/' + prospectId,
          }),
        ];
      }
    } else {
      if (companyHelper.isValidOrgNr(payload.prospectId)) {
        prospectPromises = [
          await request({
            method: 'get',
            url: '/company/companyBasicInfo/' + payload.prospectId,
          }),
        ];
      } else {
        prospectPromises = [
          await request({
            method: 'get',
            url: '/privatePerson/' + payload.prospectId,
          }),
        ];
      }
    }

    const data = await Promise.all(prospectPromises);
    let prospectData;

    if (!data?.length || data instanceof Error) {
      return [];
    } else {
      prospectData = data
        .map((num: any) => {
          if (num && num.person && num.person[0]) {
            let person = num.person[0].person;
            person.type = 'person';
            if (!person.name || person.name === '') {
              person.name = personHelper.buildPersonDefaultName(
                person.gender,
                person.birthYear,
                person.zipMuncipality
              );
            }
            return person;
          } else if (num) {
            num.type = 'company';
            return num;
          } else {
            return null;
          }
        })
        .filter((num) => num);
    }

    prospectData.map((prospect: any, i) => {
      prospect.fleet = null;
      return prospect;
    });

    return prospectData;
  } catch (err) {
    console.error('Error in getPreviewProspectInformation', err);
    return [];
  }
};

export const getPreviewProspectFleet = async ({ id, getSettings }) => {
  try {
    let columns = JSON.parse(JSON.stringify(store.getState().agile.columns));
    let item: any = {};

    // Get item data directly from agile columns.
    columns.forEach((column) => {
      if (
        column.id === 'prospects' &&
        column.items.find((num) => num.prospectId === id)
      ) {
        item = column.items.find((num) => num.prospectId === id);
        item.type = 'prospect';
      }
    });
    columns.forEach((column) => {
      if (
        column.id !== 'prospects' &&
        column.items.find((num) => num.id === id)
      ) {
        item = column.items.find((num) => num.id === id);
        item.type = 'deal';
      }
    });

    const prospectId =
      item?.prospects && item.prospects[0]
        ? item?.prospects[0]
        : item.prospectId
        ? item.prospectId
        : item.prospect_id
        ? item.prospect_id
        : null;

    if (prospectId) {
      const fleet = await getFleetSummary({
        prospectId: prospectId,
        getSettings: getSettings,
      });

      if (fleet instanceof Error) {
        console.error('Error when getting fleet in getPreviewData');

        return [];
      }

      return fleet;
    }
  } catch (err) {
    console.error('Error when getting fleet in getPreviewData');
  }
};

/**
 * For when a deal holds specific reg numbers, get info about vehicles.
 *
 * @param payload
 */
const getPreviewVehicleInformation = async (payload) => {
  try {
    let vehiclePromises;

    if (payload.type === 'deal' && Array.isArray(payload.cars)) {
      vehiclePromises = await payload.cars.map(async (regNum) => {
        return await request({
          method: 'get',
          url: '/car/' + regNum,
        });
      });
    } else {
      return [];
    }

    const data = await Promise.all(vehiclePromises);

    if (!data?.length || data instanceof Error) {
      return [];
    }

    return data;
  } catch (err) {
    console.error('Error in getPreviewVehicleInformation', err);
    return [];
  }
};

/**
 * Get saved value for sorting columns.
 */
const getSortValue = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/agile/sort/',
    });

    if (data instanceof Error || !data) {
      return 'updatedDesc';
    }

    return data;
  } catch (err) {
    return console.error('Error in getSortValue', err);
  }
};

/**
 * Move a deal or prospect to a new column/phase.
 * If it is a prospect - create deal first, then move it.
 * Should return deal _id.
 *
 * @param payload.comment - string (optional)
 * @param payload.id - string - Deal id or prospect id. (Only prospect id when a prospect is moved directly to trash.)
 * @param payload.listId - string
 * @param payload.prospectIds - string - String of prospectIds separated by comma.
 * @param payload.prospectSource - string - source of prospect for deal.
 * @param payload.reason - string - For now this is only used when target is 'lost';
 * @param payload.source - string - Previous column/phase
 * @param payload.target - string - New column/phase
 */
export const moveItem = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      !payload.id ||
      !payload.source ||
      !payload.target ||
      payload.target === 'prospects' // Cannot move to prospects column.
    ) {
      return console.error('Cannot moveItem', payload);
    }

    let dealId;
    let params;
    if (payload.itemType === 'prospect') {
      // This is a prospect, not a deal.
      if (payload.target === 'trash') {
        // Remove prospect from list, no need to create deal first.
        if (!payload?.listId) {
          return console.error('Missing params in moveItem', payload);
        }

        params = {
          comment: payload.comment,
          id: payload.id,
          isDeal: false,
          listId: payload.listId,
          prospectIds: '',
          source: 'idle',
          target: 'trash',
        };
      } else {
        // First create deal.
        let data = await createDeal({
          listId: payload.listId,
          prospectId: payload.id,
          prospectSource: payload.prospectSource,
          target: payload.target,
        });

        if (payload.target === 'idle') {
          // If its a prospect and target is 'idle' column we already have a deal with phase 'idle', so no movement need.
          dealId = data.id;
        } else {
          // Set params for deal movement call.
          params = {
            comment: payload.comment,
            id: data.id,
            isDeal: true,
            listId: payload.listId,
            prospectId: payload.prospectId,
            source: data.phase,
            target: payload.target,
          };
        }
      }
    } else {
      // This is a deal, so set params directly.
      params = {
        comment: payload.comment,
        id: payload.id,
        isDeal: true,
        listId: payload.listId,
        prospectId: payload.prospectId,
        reason: payload.reason ? payload.reason : '',
        source: payload.source,
        target: payload.target,
      };
    }

    if (!dealId) {
      const data = await request({
        data: params,
        method: 'put',
        url: '/agile/moveDeal/',
      });

      if (data instanceof Error) {
        console.error('Could not move deal in moveItem', data);
        return showFlashMessage(tc.couldNotMoveDeal, 'fail');
      }
      dealId = data.id;
    }

    await getColumnsData({});
    return dealId; // Return id so we can update addActivityItem in <Agile/>.
  } catch (err) {
    return console.error('Error in moveDeal', err);
  }
};

export const setPreviewId = (id) => {
  return store.dispatch({ type: agileActionTypes.SET_PREVIEW_ID, payload: id });
};

/**
 * Sort columns.
 *
 * Used in UI sorting menu, if so just provide payload.sort and it saves to store state and db.
 * Can also be used as a sorting helper without saving to db or store state.
 *
 * @param payload.columns - array (optional) - We can provide columns, otherwise retrieve it from store state.
 * @param payload.sort - string - Should match a value in agileHelper.getColumnSortValues.
 * @param payload.skipUpdateState - boolean (optional) - If we want to skip backend call with new sort value and return columns without saving to state.
 */
export const sortColumns = async (payload) => {
  let columns = payload.columns
    ? payload.columns
    : JSON.parse(JSON.stringify(store.getState().agile.columns));
  let sort = payload.sort;

  if (!Array.isArray(columns)) {
    return;
  }

  columns.map((column) => {
    if (
      column.id === 'prospects' &&
      !(sort === 'alphabeticAsc' || sort === 'alphabeticDesc')
    ) {
      // For prospect column it's only relevant to sort on name.
      return column;
    }

    switch (sort) {
      case 'alphabeticAsc':
        column.items = column.items.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case 'alphabeticDesc':
        column.items = column.items.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          } else if (a.name > b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        break;
      case 'createdAsc':
        column.items = column.items.sort((a, b) => {
          if (new Date(a.created) < new Date(b.created)) {
            return -1;
          } else if (new Date(a.created) > new Date(b.created)) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case 'createdDesc':
        column.items = column.items.sort((a, b) => {
          if (new Date(a.created) > new Date(b.created)) {
            return -1;
          } else if (new Date(a.created) < new Date(b.created)) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case 'updatedAsc':
        column.items = column.items.sort((a, b) => {
          if (new Date(a.updated) < new Date(b.updated)) {
            return -1;
          } else if (new Date(a.updated) > new Date(b.updated)) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      case 'updatedDesc':
        column.items = column.items.sort((a, b) => {
          if (new Date(a.updated) > new Date(b.updated)) {
            return -1;
          } else if (new Date(a.updated) < new Date(b.updated)) {
            return 1;
          } else {
            return 0;
          }
        });
        break;
      default:
        sort = 'updatedDesc';
        column.items = column.items.sort((a, b) => {
          if (new Date(a.updated) > new Date(b.updated)) {
            return -1;
          } else if (new Date(a.updated) < new Date(b.updated)) {
            return 1;
          } else {
            return 0;
          }
        });
    }

    return column;
  });

  if (payload.skipUpdateState) {
    store.dispatch({ type: agileActionTypes.SET_SORT, payload: sort });
    return columns;
  } else {
    // Save to state and db.
    store.dispatch({ type: agileActionTypes.SET_SORT, payload: sort });
    store.dispatch({ type: agileActionTypes.SET_COLUMNS, payload: columns });
    await updateSortValue(payload.sort);
    return columns;
  }
};

/**
 *@description Updates activity types saved in database.
 */
export const updateAgileActivityTypes = async (payload: {
  activityTypes: { active: string[]; inactive: string[] };
}) => {
  try {
    if (!payload || !payload.activityTypes) {
      return console.error('Missing params in updateAgilyActivityTypes');
    }

    const data = await request({
      data: {
        activityTypes: payload.activityTypes,
      },
      method: 'PUT',
      url: '/agile/settings/activityTypes',
    });
    if (data instanceof Error) {
      return console.error('Error in updateAgileActivityTypes: ', data);
    }
    store.dispatch({
      type: agileActionTypes.SET_ACTIVITY_TYPES,
      payload: payload.activityTypes,
    });

    const newData = await getAgileActivityTypes();
    return newData;
  } catch (e) {
    return console.error('Error in updateAgileActivityTypes', e);
  }
};

export const getAgileActivityTypes = async () => {
  try {
    const data = await request({
      method: 'GET',
      url: '/agile/settings/activityTypes',
    });
    if (!data || data instanceof Error) {
      return console.error('No data or error in getAgileActivityTypes: ', data);
    }

    store.dispatch({
      type: agileActionTypes.SET_ACTIVITY_TYPES,
      payload: data,
    });
  } catch (e) {
    return console.error('Error in updateAgileActivityTypes', e);
  }
};

/**
 * Update agile filter.
 *
 * @param payload.id - string
 * @param payload.name - string
 * @param payload.resetFilters - bool (optional) - When we want to reset all filters.
 * @param payload.type - string
 */
export const updateAgileFilter = async (payload) => {
  try {
    if (
      (!payload?.id || !payload?.name || !payload?.type) &&
      !payload?.resetFilters
    ) {
      return console.error('Missing params in updateAgileFilter');
    }

    let filtersArr;

    if (payload.resetFilters) {
      filtersArr = [];
    } else {
      filtersArr = JSON.parse(JSON.stringify(store.getState().agile.filter));

      if (filtersArr.find((num) => num.id === payload.id)) {
        filtersArr = filtersArr.filter((num) => num.id !== payload.id);
      } else {
        filtersArr.push({
          active: true,
          id: payload.id,
          name: payload.name,
          meta: {
            type: payload.type,
          },
        });
      }

      filtersArr = filtersArr.filter((num) => num.active);
    }

    const data = await request({
      data: {
        filter: filtersArr,
      },
      method: 'put',
      url: '/agile/settings',
    });

    if (data instanceof Error) {
      return console.error('Error in updateAgileFilter', data);
    }
    getColumnsData({ limit: payload.limit });
    return store.dispatch({
      type: agileActionTypes.SET_FILTER,
      payload: filtersArr,
    });
  } catch (err) {
    return console.error('Error in updateAgileFilter', err);
  }
};

/**
 * Update agile column structure in db.
 * We remove static prospects column before saving to backend.
 *
 * @param columns - Array
 */
export const updateColumnStructure = async (columns) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!columns || (columns && !columns.length)) {
      return console.error('Missing params in updateColumnStructure');
    }

    // Update state.
    store.dispatch({ type: agileActionTypes.SET_COLUMNS, payload: columns });

    // Clone.
    let columnStructure = JSON.parse(JSON.stringify(columns));

    columnStructure = columnStructure.map((column) => {
      // Only save what we need.
      return {
        id: column.id,
        title: column.title,
        visible: column.visible,
      };
    });

    // Extra check, user should never be able to remove this column.
    if (!columnStructure.find((num) => num.id === 'prospects')) {
      columnStructure.unshift({
        id: 'prospects',
        title: tc.prospectsMultiple,
        visible: true,
      });
    }

    // Extra check, user should never be able to remove this column.
    if (!columnStructure.find((num) => num.id === 'idle')) {
      columnStructure.unshift({
        id: 'idle',
        title: tc.idle,
        visible: true,
      });
    }

    const data = await request({
      data: {
        columns: columnStructure,
      },
      method: 'put',
      url: '/agile/columns/',
    });

    if (data instanceof Error) {
      return console.error('Error in updateColumnStructure', data);
    }
  } catch (err) {
    return console.error('Error in updateColumnStructure', err);
  }
};

/**
 * Update title for a column.
 *
 * @param payload.id - string
 * @param payload.title - string
 */
export const updateColumnTitle = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.id || !payload?.title) {
      return console.error('Missing params in updateColumnTitle');
    }

    if (payload.id === 'prospects' || payload.id === 'idle') {
      return console.error('Cannot make changes on prospects or idle columns');
    }

    let newColumns = JSON.parse(JSON.stringify(store.getState().agile.columns));

    const newId = agileHelper.buildColumnTitleToPhase(payload.title); // New id for column as well, since we rebuild these to text in Activity component.
    newColumns.map((column) => {
      if (column.id === payload.id) {
        column.id = newId;
        column.title = payload.title.replace(/[^a-zåäö0-9 -]/gi, '').trim(); // Replace special characters and trim.
      }
      return column;
    });

    // Update the phase on deals to the new column id, since column id and deal phase has to match.
    const successful = await updatePhaseOnDeals({
      oldPhase: payload.id,
      newPhase: newId,
    });

    if (successful) {
      await updateColumnStructure(newColumns);
      return getColumnsData({});
    } else {
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }
  } catch (err) {
    return console.error('Error in updateColumnStructure', err);
  }
};

/**
 * Update phase on deals with a specific phase, to a new phase.
 *
 * @param payload.oldPhase - string
 * @param payload.newPhase - string
 */
const updatePhaseOnDeals = async (payload) => {
  try {
    if (!payload || !payload?.oldPhase || !payload?.newPhase) {
      console.error('Missing params in updatePhaseOnDeals');
      return false;
    }

    const data = await requestBody({
      data: {
        oldPhase: payload.oldPhase,
        newPhase: payload.newPhase,
      },
      method: 'put',
      url: '/agile/updatePhaseOnDeals/',
    });

    if (data instanceof Error) {
      console.error('Error in updatePhaseOnDeals', data);
      return false;
    }

    return data.result;
  } catch (err) {
    console.error('Error in updatePhaseOnDeals', err);
    return false;
  }
};

/**
 * Update agile sort value to db.
 *
 * @param sort - string
 */
export const updateSortValue = async (sort) => {
  try {
    if (!sort) {
      return console.error('Missing params in updateSortValue');
    }
    store.dispatch({ type: agileActionTypes.SET_SORT, payload: sort });
    const data = await request({
      data: {
        sort: sort,
      },
      method: 'put',
      url: '/agile/sort/',
    });

    if (data instanceof Error) {
      console.error('Error in updateSortValue', data);
    }
  } catch (err) {
    return console.error('Error in updateSortValue', err);
  }
};

export const updateDealType = async (dealId, dealType) => {
  if (!dealId || !activityHelper.getDealTypes().includes(dealType)) {
    console.error('Incorrect params in updateDealType.');
    console.error(`dealid: ${dealId} --- dealType: ${dealType}`);
    return;
  }

  const data = await request({
    data: {
      dealId,
      dealType,
    },
    method: 'PUT',
    url: '/agile/updateDeal',
  });
};

export const getDealTypeById = async (dealId) => {
  if (!dealId) {
    return console.error(
      `Incorrect params in getDealTypeById -- dealId: ${dealId}`
    );
  }

  const data = await request({
    method: 'GET',
    url: `/deals/${dealId}`,
  });

  // "vehicle" is the default deal type
  return data && data.deal_type ? data.deal_type : 'vehicle';
};

export const shareDeal = async ({
  dealId,
  prospectId,
  newDealerId,
  newOwnerId,
  action,
  comment,
  event_date,
  listId,
  type,
}) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  try {
    let params = { newDealerId, newOwnerId, action, comment, event_date };
    if (!dealId && !prospectId) {
      return showFlashMessage(text.dealShareFailed, 'info');
    }
    if (type === 'deal') {
      params.dealId = dealId;
      params.type = 'deal';
    } else {
      params.prospectId = prospectId;
      params.type = 'prospect';
      params.listId = listId;
    }

    const data = await request({
      method: 'POST',
      url: `/deals/shareDeal`,
      data: params,
    });

    if (data instanceof Error) {
      throw data;
    }

    showFlashMessage(tc.dealSharedToColleague, 'success');
    await getColumnsData({});
  } catch (err) {
    console.error('Error while sharing deal: ', err);
    showFlashMessage(text.dealShareFailed, 'fail');
  }
};

export const getProspectIdByRegnr = async (regnr: string): Promise<string> => {
  try {
    if (!regnr || typeof regnr !== 'string') throw new Error('No regnr');
    const car = await request({ method: 'get', url: `/car/${regnr}` });
    if (!car || car instanceof Error) {
      throw new Error('No car found');
    }

    const prospectId = car.user_id;
    return prospectId;
  } catch (err) {
    console.error('Error fetching prospect id by reg nr: ', err);
    throw err;
  }
};
