import { store } from 'store';
import { request, requestBody } from 'helpers';
import { commentActionTypes } from './actions';
import { getActivities } from 'store/activities/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import * as text from 'text-content';

/**
 * Retrieve one Comment
 *
 * @param payload.id
 */
export const getComment = async (payload) => {
  if (!payload?.id) {
    return console.error('Missing params in getComment', +payload);
  }

  try {
    const comment = await request({
      method: 'get',
      url: '/comments/comment/' + payload.id,
    });

    if (!comment || comment instanceof Error) {
      console.error('No data in getComment');
      return store.dispatch({
        type: commentActionTypes.COMMENT_SET_COMMENT,
        payload: {},
      });
    }

    return await store.dispatch({
      type: commentActionTypes.COMMENT_SET_COMMENT,
      payload: { ...comment },
    });
  } catch (err) {
    return console.error('Error in getComment:', err);
  }
};

/**
 * Remove one comment.
 *
 * @param payload.comment
 * @param payload.id
 */
export const removeComment = async (payload) => {
  if (!payload?.id) {
    return console.error('Missing params in removeComment', payload);
  }

  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const comment = await requestBody({
      data: {
        comment_id: payload.id.toString(),
      },
      method: 'delete',
      url: '/comments/',
    });

    if (!comment || comment instanceof Error) {
      return console.error('Error in removeComment');
    }

    setCommentRemoveId(null);
    showFlashMessage(tc.commentWasRemoved, 'success');

    // Update activities.
    await getActivities({ type: 'last' });

    return;
  } catch (err) {
    return console.error('Error in removeComment:', err);
  }
};

/**
 * Save new comment.
 *
 * @param payload.comment
 * @param payload.target
 */
export const saveComment = async (payload) => {
  if (!payload?.target || !payload?.comment || !payload?.targetType) {
    return console.error('Missing params in saveComment', payload);
  }

  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const comment = await request({
      data: {
        comment: payload.comment,
        target_id: payload.target,
        targetType: payload.targetType,
      },
      method: 'post',
      url: '/comments/',
    });

    if (!comment || comment instanceof Error) {
      return console.error('Error in saveComment');
    }

    showFlashMessage(tc.commentWasSaved, 'success');
    // Update activities.
    await getActivities({ type: 'last' });

    return store.dispatch({
      type: commentActionTypes.COMMENT_SET_COMMENT,
      payload: {
        added: null,
        comment: null,
        dealer_id: null,
        id: null,
        target: null,
        user: null,
      },
    });
  } catch (err) {
    return console.error('Error in saveComment:', err);
  }
};

export const setCommentEditId = async (id) => {
  store.dispatch({ type: commentActionTypes.COMMENT_SET_EDIT_ID, payload: id });
};

export const setCommentRemoveId = async (id) => {
  store.dispatch({
    type: commentActionTypes.COMMENT_SET_REMOVE_ID,
    payload: id,
  });
};

/**
 * Update existing comment.
 *
 * @param payload.id
 * @param payload.comment
 */
export const updateComment = async (payload) => {
  if (!payload?.id || !payload?.comment) {
    return console.error('Missing params in updateComment', +payload);
  }

  try {
    const tc = store.getState().user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const comment = await request({
      data: {
        comment: payload.comment,
        comment_id: payload.id,
      },
      method: 'put',
      url: '/comments/',
    });

    if (!comment || comment instanceof Error) {
      return console.error('Error in updateComment');
    }

    setCommentEditId(null);
    showFlashMessage(tc.commentWasSaved, 'success');

    // Update activities.
    await getActivities({ type: 'last' });

    store.dispatch({
      type: commentActionTypes.COMMENT_SET_EDIT_ID,
      payload: null,
    });
    return store.dispatch({
      type: commentActionTypes.COMMENT_SET_COMMENT_COMMENT,
      payload: payload.comment,
    });
  } catch (err) {
    return console.error('Error in updateComment:', err);
  }
};
