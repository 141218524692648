import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';
import Info from 'components/info';
import Loading from 'components/loading';
import PopupHolder from 'components/popup_holder';
import TooltipComponent from 'components/tooltip';
import WidgetHeader from 'components/widget_header';
import { fetchRequest } from 'helpers/request_helper';
import LanguageContext from 'language-context';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showFlashMessage } from 'store/flash_messages/tasks';
import moment from 'moment';
import { useParams } from 'react-router-dom';

export default function ActivityPopupProspect({ prospectId, listId }) {
  const tc = useContext(LanguageContext);
  if (listId) return null;
  if (!prospectId) {
    console.warn('No prospect id provided to <ActivityPopupProspect />');
    return null;
  }

  return (
    <PopupHolder
      popupContent={
        <ConnectPopupContent prospectId={prospectId} listId={listId} />
      }
      popupHeader={<WidgetHeader headline={tc.activity} />}
      popupSize="small"
      visibleContent={
        <div className="tableCellIconHolder">
          <TooltipComponent
            horizontalDirection="left"
            tooltipContent={tc.clickToShowActivity}
          >
            <Icon val="list" />
          </TooltipComponent>
        </div>
      }
    />
  );
}

function PopupContent({ prospectId, listId, userId }) {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const currentListId = Number(id);

  const own = activities.filter(
    (activity) =>
      activity.userId === userId && activity.listId !== currentListId
  );
  const others = activities.filter(
    (activity) =>
      activity.userId !== userId && activity.listId !== currentListId
  );

  const tc = useContext(LanguageContext);

  useEffect(() => {
    if (!prospectId) return;
    if (listId) return;
    async function fetchActivity() {
      try {
        setLoading(true);
        const data = await fetchRequest('/activity/prospect/' + prospectId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        setLoading(false);
        if (!data.status === 200) {
          throw new Error('Invalid status from backend: ' + data.status);
        }

        const activity = data.data;
        setActivities(activity);
      } catch (err) {
        console.error(
          'Error fetching activities in ActivityPopupContent:',
          err
        );
        showFlashMessage(tc.activitiesGetFailed, 'fail');
        setActivities([]);
      }
    }
    fetchActivity();
  }, [prospectId, listId, tc]);
  return (
    <div className="popupHolderWrapper__popupHolder__popupContent__content__section">
      {loading ? <Loading /> : null}
      {!loading && !own.length && !others.length ? (
        <Info>
          <p>{tc.activitiesNobodyCurrently}</p>
        </Info>
      ) : null}
      {own.length ? (
        <>
          {own.map((activity) => {
            return (
              <div className="activity-card-border">
                <div
                  key={activity.listId + activity.listName + activity.userId}
                  className="popupHolderWrapper__popupHolder__popupContent__content__section__row"
                >
                  <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__left">
                    <IconStyled class="info" iconVal="list" />
                  </div>
                  <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__right">
                    <p className="label">{tc.prospectExistsInOwnList}</p>
                    <p>{activity.listName}</p>
                    <div>
                      <span>
                        ({moment(activity.listDate).format('YYYY-MM-DD')})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
      {others.length ? (
        <>
          {others.map((activity) => {
            return (
              <div className="activity-card-border">
                <div
                  key={activity.listId + activity.listName + activity.userId}
                  className="popupHolderWrapper__popupHolder__popupContent__content__section__row"
                >
                  <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__left">
                    <IconStyled class="info" iconVal="user" />
                  </div>
                  <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__right">
                    <p className="label">{tc.prospectIsWorkedByColleague}</p>
                    <p>
                      {activity.userName} <span>i listan </span>
                      {activity.listName}
                    </p>
                    <div>
                      <span style={{ fontSize: '13px', color: 'gray' }}>
                        ({moment(activity.listDate).format('YYYY-MM-DD')})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    userId: state.user.info?.id,
    prospectId: props.prospectId,
    listId: props.listId,
  };
};

const ConnectPopupContent = connect(mapStateToProps)(PopupContent);
