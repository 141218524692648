import { store } from "store";
import { request, requestBody } from "helpers";
import { contactsActionTypes } from "./actions";
import { showFlashMessage } from "store/flash_messages/tasks";
import carHelper from "shared_helpers/car_helper";
import companyHelper from "shared_helpers/company_helper";
import * as text from "text-content";
import { textHelper } from "helpers/text_helper";

/**
 * Add an entity to contacts 'savedTo' array.
 *
 * @param payload.companyId - string - When type is car or company, we want a value here.
 * @param payload.contacts - Array - Contacts that will receive new entity.
 * @param payload.entityId - string - The entity.
 * @param payload.entityType - string - 'car' / 'company' / 'deal'
 */
export const addEntityToContacts = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.contacts) ||
      (payload && !payload.entityId) ||
      (payload && !payload.entityType)
    ) {
      return console.error("Missing params in addEntityToContact", payload);
    }

    const promises = await payload.contacts.map(async (contact) => {
      if (
        !contact.savedTo ||
        (contact.savedTo && !Array.isArray(contact.savedTo))
      ) {
        contact.savedTo = [];
      }

      let entity: any = {
        companyId: payload.companyId,
        entityId: payload.entityId,
        entityType: payload.entityType,
      };

      if (payload.type === "car") {
        entity.entityName = payload.entityName
          ? payload.entityName
          : contact.entityId;
      }

      if (payload.entityType === "company" && !payload.entityName) {
        throw new Error(
          "Missing entityName for entityType company in addEntityToContacts."
        );
      }

      if (payload.entityType === "company") {
        entity.entityName = payload.entityName;
      }

      contact.savedTo.push(entity);

      return await request({
        data: {
          contactId: contact._id,
          updatedData: contact,
        },
        method: "put",
        url: "/contacts/",
      });
    });

    const data = await Promise.all(promises);

    if (!data) {
      return console.error("Error in addEntityToContacts");
    }

    showFlashMessage(tc.entityWasAddedToContact, "success");
  } catch (err) {
    return console.error("Error in addEntityToContact", err);
  }
};

/**
 * Get contacts for a specific entityId, which can be: a company org nr/a car reg nr/a deal id.
 *
 * @param payload.entityId - string
 */
export const getContacts = async (payload) => {
  try {
    if (!payload || !payload.entityId) {
      return console.error("Missing params in getContacts", payload);
    }

    store.dispatch({ type: contactsActionTypes.SET_CONTACTS, payload: [] });
    store.dispatch({
      type: contactsActionTypes.SET_ENTITY_ID,
      payload: payload.entityId,
    });

    let data: any = {};
    if (companyHelper.isValidOrgNr(payload.entityId)) {
      data.companyId = payload.entityId;
      data.entityType = "company";
    } else if (carHelper.isValidRegNumber(payload.entityId)) {
      data.entityId = payload.entityId;
      data.entityType = "car";
    } else {
      data.entityId = payload.entityId;
      data.entityType = "deal";
    }

    // Get contacts.
    let contacts = await request({
      data: data,
      method: "get",
      url: "/contacts/",
    });

    if (!contacts || contacts instanceof Error) {
      return store.dispatch({
        type: contactsActionTypes.SET_CONTACTS,
        payload: [],
      });
    } else {
      // Make sure to clear out faulty values.
      contacts = contacts.filter((num) => num);
      return store.dispatch({
        type: contactsActionTypes.SET_CONTACTS,
        payload: contacts,
      });
    }
  } catch (err) {
    return console.error("Error in getContacts", err);
  }
};

/**
 * Remove a footer_contact completely.
 *
 * @param payload.id - string - Contact id.
 */
export const removeContact = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || (payload && !payload.id)) {
      return console.error("Missing params in removeContact", payload);
    }

    const deletedContact = await request({
      data: {
        contactId: payload.id,
      },
      method: "delete",
      url: "/contacts/",
    });

    if (!deletedContact) {
      return console.error("Error in removeContact.");
    }

    showFlashMessage(tc.contactWasRemoved, "success");
  } catch (err) {
    return console.error("Error in removeContact", err);
  }
};

/**
 * Remove an entity (company/deal/car) from contacts 'savedTo' array.
 * Contact is not removed, even if the savedTo array becomes empty.
 *
 * @param payload.entityId - string
 * @param payload.id - string
 * @param payload.entityType - string
 */
export const removeEntityFromContact = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const { id, entityId, entityType } = payload;
    if (!payload || !id || !entityId || !entityType) {
      return console.error(
        "Missing params in removeEntityFromContact",
        payload
      );
    }

    const updated = await requestBody({
      data: {
        contactId: id,
        entityId,
        entityType,
      },
      method: "delete",
      url: "/contacts/removeFromEntity",
    });

    if (!updated) {
      return console.error("Error in removeEntityFromContact.");
    }

    showFlashMessage(tc.entityWasRemovedFromContact, "success");

    return await getContacts({
      entityId: payload.entityId,
      entityType: payload.entityType,
    });
  } catch (err) {
    return console.error("Error in removeEntityFromContact", err);
  }
};

/**
 * Save a new footer_contact.
 *
 * Note:
 * 'savedTo' array holds entity objects with the properties below.
 * (It has been a little confusing historically, and not really setup for what is has been trying to do.
 * So from now on, we're not gonna save companyId or entityName for when entity is a deal.)
 * An entity can be a car, company or deal. It's not possible to save a footer_contact to a person, but you can save a footer_contact to a car where the user is a person.
 * companyId - for company this is the company org nr / for car we save user id for the vehicle here / for deal we don't save companyId.
 * entityType - 'deal' / 'company' / 'car'.
 * entityId - company org nr / deal id / car reg nr.
 * entityName - company name / car name (brand, model and reg nr, example: 'DAF CF (EAD349)'), or just reg nr / for deals we don't save entityName.
 *
 *
 * @param payload.comment - string
 * @param payload.email - array
 * @param payload.name - string
 * @param payload.savedTo - array
 * @param payload.tele - array
 */
export const saveNewContact = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload) {
      return console.error("Missing params in saveNewContact", payload);
    }

    // Add properties to entity objects where missing.
    if (payload.savedTo && payload.savedTo.length) {
      payload.savedTo.map((num) => {
        if (!num.entityId) {
          throw new Error("Missing entityId for entity in saveNewContact.");
        }

        if (carHelper.isValidRegNumber(num.entityId)) {
          // Car
          if (!num.companyId) {
            throw new Error(
              'Missing companyId for entityType "car" in saveNewContact.'
            );
          }
          if (!num.entityType) {
            num.entityType = "car";
          }
          if (!num.entityName) {
            num.entityName = num.entityId;
          }
        } else if (companyHelper.isValidOrgNr(num.entityId)) {
          // Company
          if (!num.entityName) {
            throw new Error(
              'Missing entityName for entityType "company" in saveNewContact'
            );
          }
          if (!num.entityType) {
            num.entityType = "company";
          }
          if (!num.companyId) {
            num.companyId = num.entityId;
          }
        } else {
          // Deal
          num.entityType = "deal";
        }

        return num;
      });
    }

    if (!payload.email) {
      payload.email = [];
    } else {
      if (payload.email.find((num) => !textHelper.regexEmail.test(num))) {
        return showFlashMessage(tc.invalidEmail, "fail");
      }
    }

    if (!payload.tele) {
      payload.tele = [];
    }

    const contact = await request({
      data: payload,
      method: "post",
      url: "/contacts",
    });

    if (!contact || contact instanceof Error) {
      return console.error("Error in saveNewContact", contact);
    }

    showFlashMessage(tc.contactWasSaved, "success");
  } catch (err) {
    return console.error("Error in saveNewContact", err);
  }
};

/**
 * Update a new footer_contact
 *
 * @param payload.id - string - Id for footer_contact.
 *
 * @param payload.data.comment - string
 * @param payload.data.email - array
 * @param payload.data.name - string
 * @param payload.data.savedTo - array
 * @param payload.data.tele - array
 */
export const updateContact = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload.id || !payload.data) {
      return console.error("Missing params in updateContact", payload);
    }

    const updated = await request({
      data: {
        contactId: payload.id,
        updatedData: payload.data,
      },
      method: "put",
      url: "/contacts/",
    });

    if (!updated) {
      return console.error("Error in updateContact");
    }

    showFlashMessage(tc.contactWasUpdated, "success");
  } catch (err) {
    return console.error("Error in updateContact", err);
  }
};
