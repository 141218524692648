import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { resetPassword, userLogin } from 'store/user/tasks';
import LanguageContext from 'language-context';
import { textHelper } from 'helpers';
import cssVariables from '../../styles/_exported_variables.module.scss';
import headerGraphic from 'images/home_page/temp8.jpeg';
import Icon from 'components/icon';
import Popup from 'components/popup';

const HomeHeader = () => {
  const startingOpacityValue = 0.88;
  const whenOpacityFull = 1000;
  const [formInfo, setFormInfo] = useState({
    email: '',
    password: '',
  });
  const [headerTopOpacity, setHeaderTopOpacity] =
    useState(startingOpacityValue);
  const [height, setHeight] = useState(740);
  const [showLogin, setShowLogin] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [validation, setValidation] = useState({
    email: null,
    password: null,
  });
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [tooManyLogins, setTooManyLogins] = useState(false);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    const _validateForm = () => {
      if (formInfo.email.length && formInfo.password.length) {
        setValidation({
          email: !!textHelper.regexEmail.test(formInfo.email),
          password: true, // Note that passwords from old site might be only one character.
        });
      } else if (formInfo.email.length && !formInfo.password.length) {
        return setValidation({
          email: !!textHelper.regexEmail.test(formInfo.email),
          password: false,
        });
      } else if (!formInfo.email.length && formInfo.password.length) {
        return setValidation({
          email: false,
          password: true,
        });
      } else {
        return setValidation({
          email: false,
          password: false,
        });
      }
    };

    _validateForm();
  }, [formInfo]);

  useLayoutEffect(() => {
    _setHeight();
    window.addEventListener('resize', _setHeight);
    return () => window.removeEventListener('resize', _setHeight);
  });

  useEffect(() => {
    // Increase opacity on top menu as page scrolls.
    const _setMenuBackgroundOpacity = (e) => {
      const home = document.querySelector('.homeWrapper__home');
      const scrollTop = home?.scrollTop || 0;
      if (scrollTop > whenOpacityFull) {
        return setHeaderTopOpacity(1);
      }

      const value = whenOpacityFull - scrollTop;
      const diff = Math.abs(whenOpacityFull - value);
      const percentage = diff / whenOpacityFull;
      setHeaderTopOpacity(
        startingOpacityValue + percentage < 1
          ? startingOpacityValue + percentage
          : 1
      );
    };

    _setMenuBackgroundOpacity();
    document
      .querySelector('.homeWrapper__home')
      .addEventListener('scroll', _setMenuBackgroundOpacity);
    return () =>
      document
        .querySelector('.homeWrapper__home')
        .removeEventListener('scroll', _setMenuBackgroundOpacity);
  }, []);

  useEffect(() => {
    const _handleKey = async (e) => {
      if (e.keyCode === 13) {
        _handleLoginSubmit();
      }
    };

    window.addEventListener('keydown', _handleKey);
    return () => {
      window.removeEventListener('keydown', _handleKey);
    };
  }, [validation]); // Listen to validation.

  const _handleLoginSubmit = async () => {
    if (validation.email && validation.password) {
      const result = await userLogin({
        email: formInfo.email,
        password: formInfo.password,
      });
      setLoginAttempts((loginAttempts) => loginAttempts + 1);

      if (result && result.err === 'max_login') {
        setTooManyLogins(true);
      }
    }
  };

  const _handleOnChange = (val, type) => {
    if (type === 'email') {
      setFormInfo({
        ...formInfo,
        email: val,
      });
    }

    if (type === 'password') {
      setFormInfo({
        ...formInfo,
        password: val,
      });
    }
  };

  const _handleResetPassword = async () => {
    if (validation.email) {
      resetPassword({ email: formInfo.email });
      setValidation({
        email: false,
        password: false,
      });
      setShowResetPassword(false);
      setShowLogin(false);
      setTooManyLogins(false);
    }
  };

  const _setHeight = () => {
    if (window.innerWidth < +cssVariables.breakpoint1.replace('px', '')) {
      setHeight(null);
    } else if (height !== window.innerHeight) {
      setHeight(window.innerHeight + 'px');
    }
  };

  const _scrollToElement = (id) => {
    const el = document.querySelector(id);

    if (!el) {
      return;
    }

    document.querySelector('.homeWrapper__home').scroll(0, el.offsetTop - 70);
  };

  return (
    <div className="homeHeaderWrapper">
      <div className="homeHeaderWrapper__homeHeader">
        <div
          className={
            headerTopOpacity > 0.97
              ? 'homeHeaderWrapper__homeHeader__topWrapper scrollHeader'
              : 'homeHeaderWrapper__homeHeader__topWrapper'
          }
          style={{
            backgroundColor: 'rgba(255, 255, 255, ' + headerTopOpacity + ')',
          }}
        >
          <div className="homeHeaderWrapper__homeHeader__topWrapper__top">
            <div className="homeHeaderWrapper__homeHeader__topWrapper__top__left" />
            <div className="homeHeaderWrapper__homeHeader__topWrapper__top__right">
              <div
                className="homeHeaderWrapper__homeHeader__topWrapper__top__right__item"
                onClick={() => {
                  _scrollToElement('#home-page-section-header');
                }}
              >
                {tc.ourService}
              </div>
              <div
                className="homeHeaderWrapper__homeHeader__topWrapper__top__right__item"
                onClick={() => {
                  _scrollToElement('#home-page-section-team');
                }}
              >
                {tc.homeTeam}
              </div>
              <div
                className="homeHeaderWrapper__homeHeader__topWrapper__top__right__item"
                onClick={() => {
                  _scrollToElement('#home-page-section-contact');
                }}
              >
                {tc.homeContact}
              </div>
              <div
                className="homeHeaderWrapper__homeHeader__topWrapper__top__right__item login"
                onClick={() => {
                  setShowLogin(true);
                  setShowResetPassword(false);
                }}
              >
                <Icon val="login" />
                {tc.logon}
              </div>
            </div>
          </div>
        </div>
        <div
          className="homeHeaderWrapper__homeHeader__bottom"
          style={{ height: height }}
        >
          <div className="homeHeaderWrapper__homeHeader__bottom__left">
            <div className="homeHeaderWrapper__homeHeader__bottom__left__holder">
              <div className="homeHeaderWrapper__homeHeader__bottom__left__holder__elevatorPitch">
                <h2>{tc.homeHeader1}</h2>
                <h2 className="subHeading">{tc.homeHeader2.toLowerCase()}</h2>
                <h4>{tc.homeHeader3}</h4>
              </div>
              <div className="homeHeaderWrapper__homeHeader__bottom__left__holder__buttonWrapper">
                <div
                  className="homeHeaderWrapper__homeHeader__bottom__left__holder__buttonWrapper__button"
                  onClick={() => {
                    _scrollToElement('#home-page-section-contact');
                  }}
                >
                  {tc.homeHeaderButton}
                  <Icon val="doubleArrowDown" />
                </div>
              </div>
            </div>
          </div>
          <div className="homeHeaderWrapper__homeHeader__bottom__right">
            <img src={headerGraphic} alt={tc.ourService} />
          </div>
        </div>
      </div>
      {showLogin ? (
        <Popup
          close={() => {
            setShowResetPassword(false);
            setShowLogin(false);
            setLoginAttempts(0);
          }}
          size="small"
        >
          {showResetPassword ? (
            <div className="homeHeaderWrapper__homeHeader__login">
              <div className="homeHeaderWrapper__homeHeader__login__content">
                <div className="homeHeaderWrapper__homeHeader__login__content__inputs">
                  <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row">
                    <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row__label">
                      {tc.email}
                    </div>
                    <input
                      name="email"
                      className={validation.email ? 'valid' : 'invalid'}
                      type="text"
                      placeholder={tc.email}
                      onChange={(e) => {
                        _handleOnChange(e.target.value, 'email');
                      }}
                      value={formInfo.email}
                    />
                    <div
                      className={
                        validation.email
                          ? 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification valid'
                          : 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification invalid'
                      }
                    >
                      <Icon val="check" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="homeHeaderWrapper__homeHeader__login__footer">
                <button
                  onClick={() => {
                    setShowResetPassword(false);
                  }}
                >
                  {tc.cancel}
                </button>
                <button
                  className={`${!validation.email ? 'disabled' : ''}`}
                  onClick={_handleResetPassword}
                >
                  {tc.reset}
                </button>
              </div>
            </div>
          ) : (
            <div className="homeHeaderWrapper__homeHeader__login">
              <div className="homeHeaderWrapper__homeHeader__login__content">
                <div className="homeHeaderWrapper__homeHeader__login__content__inputs">
                  <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row">
                    <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row__label">
                      {tc.email}
                    </div>
                    <input
                      name="email"
                      className={validation.email ? 'valid' : 'invalid'}
                      type="text"
                      placeholder={tc.email}
                      onChange={(e) => {
                        _handleOnChange(e.target.value, 'email');
                      }}
                      value={formInfo.email}
                    />
                    <div
                      className={
                        validation.email
                          ? 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification valid'
                          : 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification invalid'
                      }
                    >
                      <Icon val="check" />
                    </div>
                  </div>
                  <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row">
                    <div className="homeHeaderWrapper__homeHeader__login__content__inputs__row__label">
                      {tc.password}
                    </div>
                    <input
                      name="password"
                      className={validation.email ? 'valid' : 'invalid'}
                      type="password"
                      placeholder={tc.password}
                      onChange={(e) => {
                        _handleOnChange(e.target.value, 'password');
                      }}
                      value={formInfo.password}
                    />
                    <div
                      className={
                        validation.password
                          ? 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification valid'
                          : 'homeHeaderWrapper__homeHeader__login__content__inputs__row__notification invalid'
                      }
                    >
                      <Icon val="check" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="homeHeaderWrapper__homeHeader__login__footer">
                <button
                  onClick={() => {
                    setShowResetPassword(true);
                  }}
                >
                  {tc.resetPassword}
                </button>
                <button
                  className={`${
                    !(validation.email && validation.password) || tooManyLogins
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={_handleLoginSubmit}
                >
                  {tc.logon}
                </button>
              </div>
              {tooManyLogins && (
                <span className="homeHeaderWrapper__homeHeader__login__contactInfo">
                  {tc.homeHeaderLoginProblemTooMany}
                </span>
              )}
              {loginAttempts >= 3 ? (
                <div className="homeHeaderWrapper__homeHeader__login__contactInfo">
                  <p>{tc.homeHeaderLoginProblem}</p>
                  <p>
                    {tc.homeHeaderLoginContactSupport}
                    <a href="mailto:support@bilvision.se">
                      {tc.homeContactWaysEmailValue}
                    </a>{' '}
                    {tc.homeHeaderLoginOrPhoneNumber}
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </Popup>
      ) : null}
    </div>
  );
};

export default HomeHeader;
