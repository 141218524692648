import { store } from "store";
import { request, requestBody, tableHelper } from "helpers";
import { listActionTypes } from "./actions";
import { debounce } from "debounce";
import { showFlashMessage } from "store/flash_messages/tasks";
import * as text from "text-content";

/**
 * Get data for a specific list, can be prospect data or vehicle data.
 *
 * @param payload.displayMode - 'prospects' | 'cars'
 * @param payload.listId - string - List id.
 * @param payload.page - number
 * @param payload.rowsPerPage - number
 * @param payload.searchQuery - string (optional)
 * @param payload.sort - object (optional) - {order: 'asc', orderBy: 'numberOfCars'} | {'order': 'desc', 'orderBy': 'numberOfCars'}
 */
export const getListData = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.hasOwnProperty("page")) ||
      (payload && !payload.rowsPerPage) ||
      (payload && !payload.displayMode)
    ) {
      return console.error("Missing params in getListData", payload);
    }

    //store.dispatch({ type: listActionTypes.LIST_SET_DATA, payload: null });

    let sort: any = "null";
    if (payload.sort) {
      sort = JSON.stringify({
        direction: payload.sort.order,
        type: payload.sort.orderBy,
      });
    }

    const columns =
      payload.displayMode === "prospects"
        ? tableHelper.getProspectColumns({}).map((num) => num.id)
        : tableHelper
          .getVehicleColumns({ prospectPreview: false })
          .map((num) => num.id);

    const data = await request({
      data: {
        columns: JSON.stringify(columns),
        activeFilters: payload.activeFilters,
        extraFilters: {}, // Legacy, endpoint break without this.
        page: payload.page,
        pageSize: payload.rowsPerPage,
        search: payload.searchQuery ? payload.searchQuery : null,
        sort: sort,
      },
      method: "get",
      url: "/lists/inspect/" + payload.listId + "/" + payload.displayMode,
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotGetData, "fail");
      return console.error("Error in getListData", data);
    }

    return store.dispatch({
      type: listActionTypes.LIST_SET_DATA,
      payload: data,
    });
  } catch (err) {
    return console.error("Error in getListData", err);
  }
};

export const resetListData = () => {
  store.dispatch({ type: listActionTypes.LIST_SET_DATA, payload: null });
};

/**
 * Move prospects to other lists.
 *
 * @param payload.fromList - string
 * @param payload.ids - array - User ids.
 * @param payload.listIds - array - When mode === 'savedLists'
 * @param payload.listName - string - When mode === 'newList'
 * @param payload.mode - 'newList' | 'savedLists'
 */
export const moveProspects = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.ids || !payload?.mode || !payload?.fromList) {
      showFlashMessage(tc.couldNotMoveProspects, "fail");
      return console.error("Missing params in moveProspects", payload);
    }

    if (
      (payload.mode === "newList" && !payload.listName) ||
      (payload.mode === "savedLists" && !payload.listIds)
    ) {
      showFlashMessage(tc.couldNotMoveProspects, "fail");
      return console.error("Missing params in moveProspects", payload);
    }

    let promises;
    if (payload.mode === "savedLists") {
      promises = payload.listIds.map(async (id) => {
        await request({
          data: {
            fromList: payload.fromList,
            ids: payload.ids,
            mode: "savedLists",
            toLists: id,
          },
          method: "post",
          url: "/lists/inspect/move_prospect_to_other_list",
        });
      });
    } else {
      promises = [
        await request({
          data: {
            fromList: payload.fromList,
            ids: payload.ids,
            mode: "newList",
            toLists: payload.listName,
          },
          method: "post",
          url: "/lists/inspect/move_prospect_to_other_list",
        }),
      ];
    }

    const data = await Promise.all(promises);
    const invalidResults = Array.isArray(data)
      ? data.filter((result) => result instanceof Error)
      : null;

    if (invalidResults === null || invalidResults.length) {
      showFlashMessage(tc.couldNotMoveProspects, "fail");
      return console.error("Error in moveProspects", data);
    } else {
      showFlashMessage(tc.prospectsHaveBeenMoved, "success");
    }

    return true;
  } catch (err) {
    return console.error("Error in moveProspects", err);
  }
};

/**
 * Remove prospects from a list.
 *
 * @param payload.ids - array
 * @param payload.listId - string
 */
export const removeProspectsFromList = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      (payload && !payload.ids) ||
      (payload && payload.ids && !payload.ids.length) ||
      (payload && !payload.listId)
    ) {
      return console.error("Missing params in removeProspectFromList", payload);
    }

    const data = await requestBody({
      data: {
        ids: payload.ids,
      },
      method: "delete",
      url: "/lists/inspect/" + payload.listId + "/ids",
    });

    if (data instanceof Error) {
      showFlashMessage(tc.couldNotRemoveProspects, "fail");
      return console.error("Error in removeProspectFromList", data);
    }

    return true;
  } catch (err) {
    return console.error("Error in removeProspectFromList", err);
  }
};

// Debounced (used for table search function).
export const getListDataDebounced = debounce(getListData, 500);
