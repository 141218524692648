import { store } from 'store';
import { request } from 'helpers';
import { carActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import * as text from 'text-content';

/**
 * Get car info.
 *
 * @param payload.regNr - string
 */
export const getCar = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || !payload?.regNr) {
      return console.error('Missing params in getCar', payload);
    }

    store.dispatch({ type: carActionTypes.CAR_SET_CAR, payload: null });
    store.dispatch({ type: carActionTypes.CAR_SET_RESPONSIBLE, payload: null });

    const car = [
      await request({
        method: 'get',
        url: '/car/' + payload.regNr,
      }),
    ];

    const responsible = [
      await request({
        method: 'get',
        url: '/responsibility/' + payload.regNr,
      }),
    ];

    const data = await Promise.all(car.concat(responsible));

    if (!data || data instanceof Error) {
      console.error('No data in getCar', data);
      showFlashMessage(tc.couldNotGetData, 'fail');
      return store.dispatch({
        type: carActionTypes.CAR_SET_CAR,
        payload: null,
      });
    }

    // Set data to state so components can start render.
    store.dispatch({
      type: carActionTypes.CAR_SET_RESPONSIBLE,
      payload: data[1],
    });
    store.dispatch({ type: carActionTypes.CAR_SET_CAR, payload: data[0] });

    // Get deals for vehicle in background.
    const deals = await request({
      method: 'get',
      url: '/car/getDeals/' + payload.regNr,
    });

    if (deals instanceof Error) {
      console.error('Error when getting deals in getCar', deals);
      return store.dispatch({
        type: carActionTypes.CAR_SET_DEALS,
        payload: [],
      });
    }

    store.dispatch({
      type: carActionTypes.CAR_SET_DEALS,
      payload: deals,
    });
    return data[0];
  } catch (err) {
    return console.error('Error in getCar', err);
  }
};

/**
 * Set responsible user for a vehicle.
 *
 * @param payload.entityId -  string
 * @param payload.responsibleUserId - number
 */
export const setResponsibility = async (payload) => {
  try {
    if (
      !payload ||
      (payload && !payload.entityId) ||
      (payload && !payload.responsibleUserId)
    ) {
      return console.error('Missing params in setResponsibility', payload);
    }

    const data = await request({
      data: {
        entityId: payload.entityId,
        responsibleUserId: Number(payload.responsibleUserId),
      },
      method: 'post',
      url: '/responsibility/',
    });

    if (data instanceof Error) {
      return console.error('Could not change setResponsibility', data);
    }

    return store.dispatch({
      type: carActionTypes.CAR_SET_RESPONSIBLE,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in setResponsibility', err);
  }
};
