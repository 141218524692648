import LanguageContext from 'language-context';
import { useContext } from 'react';

const ProspectSource = ({ source }) => {
  const sourcesToRender = [
    'prospect_search',
    'leads',
    'upload',
    'shared',
    'API provköra',
    'moved_from_other_list',
  ];
  const tc = useContext(LanguageContext);

  if (!source || !sourcesToRender.includes(source)) return null;

  const convertSourceToCardClass = (source) => {
    switch (source) {
      case 'prospect_search':
        return 'purple';
      case 'leads':
        return 'brown';
      case 'upload':
        return 'brown';
      case 'shared':
        return 'orange';
      case 'API provköra':
        return 'green';
      case 'moved_from_other_list':
        return 'violet';

      default:
        return '';
    }
  };

  const convertSourceToCardText = (source) => {
    switch (source) {
      case 'prospect_search':
        return tc.prospectSearch;
      case 'leads':
        return tc.leads;
      case 'upload':
        return tc.createPdfByUpload;
      case 'shared':
        return tc.sharedWith.split(' ')[0];
      case 'API provköra':
        return tc.testRideService;
      case 'moved_from_other_list':
        return tc.fromOtherList;

      default:
        return null;
    }
  };

  return (
    <div>
      <p className={`card-source-tag ${convertSourceToCardClass(source)}`}>
        {convertSourceToCardText(source)}
      </p>
    </div>
  );
};

export default ProspectSource;
