import { store } from 'store';
import { fileHelper, request } from 'helpers';
import { ordersActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getLists } from 'store/lists/tasks';
import * as text from 'text-content';
import history from 'router-history';

/**
 * Create an order for list of types 'name', 'phone', 'management', 'hubspot'.
 * We use a different endpoint for mailings orders.
 *
 * @param payload.orderManagement - bool
 * @param payload.orderName - bool
 * @param payload.orderPhone - bool
 * @param payload.orderWaystar - bool
 * @param payload.orderHubspot - bool
 */
export const createListOrder = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!store.getState().orders.list || !payload) {
      console.error('Missing data in createListOrder');
      return showFlashMessage(tc.couldNotOrderList, 'fail');
    }

    const data = await request({
      data: {
        lists: [store.getState().orders.list.id],
        phone: payload.orderPhone ? 1 : 0,
        orderPerson: payload.orderName ? 1 : 0,
        orderCompany: payload.orderManagement ? 1 : 0,
        orderWaystar: payload.orderWaystar ? 1 : 0,
        orderHubspot: payload.orderHubspot ? 1 : 0,
      },
      method: 'post',
      url: '/lists/order',
    });

    if (data instanceof Error) {
      console.error('Error in createListOrder', data);
      return showFlashMessage(tc.couldNotOrderList, 'fail');
    }

    showFlashMessage(tc.listOrderIsPlaced, 'success');
    setActiveOrderStage('selectList');
    setActiveOrderType('name');
    setListToOrder(null);
    history.push('/listor');
    return await getLists({
      orderInformation: true,
    });
  } catch (err) {
    console.error('Error in createListOrder', err);
    return showFlashMessage(tc.couldNotOrderList, 'fail');
  }
};

/**
 * Create a mailings order for list.
 *
 * @param payload.orderName - bool
 */
export const createMailingsOrder = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!store.getState().orders.list) {
      console.error('Missing data in createMailingsOrder');
      return showFlashMessage(tc.couldNotOrderList, 'fail');
    }

    const { orders: orderState } = store.getState();
    const { mailingsPostage, mailingsInput, mailingsType } = orderState;

    const data = await request({
      data: {
        input: {
          farg: mailingsPostage.color,
          pdf: mailingsInput.pdf,
          porto: mailingsPostage.postage,
          s3Key: mailingsInput.s3Key,
          SimplexDuplex: mailingsPostage.simplexDuplex,
          typ: mailingsPostage.type === 'postcard' ? 'Vykort' : 'Brev',
        },
        listId: store.getState().orders.list.id,
        orderPerson: payload?.orderName,
        mailingsType,
      },
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8', // Important
      method: 'post',
      url: '/mailings/createMailingsOrder',
    });

    if (data instanceof Error) {
      console.error('Error in createMailingsOrder: ', data);
      return showFlashMessage(tc.couldNotOrderList, 'fail');
    }

    if (data && data.order) {
      showFlashMessage(tc.listOrderIsPlaced, 'success');
    }

    resetMailings();
    setActiveOrderStage('selectList');
    setActiveOrderType('name');
    setListToOrder(null);
    history.push('/listor');
    return await getLists({
      orderInformation: true,
    });
  } catch (err) {
    console.error('Error in createMailingsOrder', err);
    return showFlashMessage(tc.couldNotOrderList, 'fail');
  }
};

/**
 * Creates an example preview for a mailings order and save blob to redux state.
 */
export const createMailingsPreview = async () => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    setMailingsPdfPreview({
      type: 'blob',
      val: null,
    });

    const mailingsPostage = store.getState().orders.mailingsPostage;
    const mailingsInput = store.getState().orders.mailingsInput;

    const base64 = await request({
      data: {
        input: {
          farg: mailingsPostage.color,
          pdf: mailingsInput.pdf,
          porto: mailingsPostage.postage,
          s3Key: mailingsInput.s3Key,
          SimplexDuplex: mailingsPostage.simplexDuplex,
          typ: mailingsPostage.type === 'postcard' ? 'Vykort' : 'Brev',
        },
      },
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8', // Important
      method: 'post',
      url: '/mailings/createMailingsPreview',
    });

    if (base64?.response?.status === 400) {
      console.error(
        'Could not generate preview in createMailingsPreview',
        base64
      );
      return showFlashMessage(tc.mailingsPreviewInvalidPDF, 'fail');
    } else if (base64 instanceof Error) {
      console.error('Error in createMailingsPreview', base64);
      return showFlashMessage(tc.couldNotGetPreview, 'fail');
    } else if (base64.sizeError) {
      return setMailingsPdfPreview({
        type: 'blob',
        val: {
          sizeError: true,
        },
      });
    }

    const blob = fileHelper.base64toBlob(base64);
    setMailingsPdfPreview({
      type: 'blob',
      val: blob,
    });
  } catch (err) {
    console.error('Error in createMailingsPreview', err);
    return showFlashMessage(tc.couldNotGetPreview, 'fail');
  }
};

/**
 * Creates an example preview for a mailings order and returns a blob directly.
 * Very similar to createMailingsPreview(), but uses payload params and doesn't
 * save anything to redux state.
 *
 * @params payload.color - bool
 * @params payload.pdf - PDF file
 * @params payload.postage - "A" | "B"
 * @params payload.s3Key - string
 * @params payload.simplexDuplex - "D" | "S"
 * @params payload.type - "envelope" | "postcard"
 */
export const createAndReturnMailingsBlob = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload ||
      !payload.hasOwnProperty('color') ||
      !payload?.pdf ||
      !payload?.postage ||
      !payload?.simplexDuplex ||
      !payload?.type
    ) {
      console.error('Missing params in createAndReturnMailingsBlob', payload);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    const base64 = await request({
      data: {
        input: {
          farg: payload.color,
          pdf: payload.pdf,
          porto: payload.postage,
          s3Key: payload.s3Key,
          SimplexDuplex: payload.simplexDuplex,
          typ: payload.type === 'postcard' ? 'Vykort' : 'Brev',
        },
      },
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8', // Important
      method: 'post',
      url: '/mailings/createMailingsPreview',
    });

    if (base64?.response?.status === 400) {
      console.error(
        'Could not generate preview in createAndReturnMailingsBlob',
        base64
      );
      return showFlashMessage(tc.mailingsPreviewInvalidPDF, 'fail');
    } else if (base64 instanceof Error) {
      console.error('Error in createAndReturnMailingsBlob', base64);
      return showFlashMessage(tc.couldNotGetPreview, 'fail');
    } else if (base64.sizeError) {
      return {
        sizeError: true,
      };
    }

    return fileHelper.base64toBlob(base64);
  } catch (err) {
    console.error('Error in createAndReturnMailingsBlob', err);
    return showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

/**
 * Get price for each mailing.
 */
export const getMailingsPrice = async () => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!store.getState().orders.mailingsPostage) {
      return console.error('No info in getMailingsPrice');
    }

    const data = await request({
      data: {
        type: store.getState().orders.mailingsPostage.type,
        farg: store.getState().orders.mailingsPostage.color,
        porto: store.getState().orders.mailingsPostage.postage,
        simplexDuplex: store.getState().orders.mailingsPostage.simplexDuplex,
      },
      method: 'get',
      url: '/mailings/price',
    });

    if (data instanceof Error) {
      return console.error('Error in getMailingsPrice', data);
    }

    return store.dispatch({
      type: ordersActionTypes.ORDERS_SET_MAILINGS_PRICE,
      payload: +data.Pris,
    });
  } catch (err) {
    console.error('Error in getMailingsPrice', err);
    return showFlashMessage(tc.couldNotOrderList, 'fail');
  }
};

export const mailingsIsUntouched = () => {
  const state = store.getState().orders;
  return (
    !state.mailingsInput?.pdf?.length &&
    !state.mailingsInput?.s3Key?.length &&
    !state.mailingsIsReady
  );
};

/**
 * Reset mailings.
 */
export const resetMailings = () => {
  return store.dispatch({ type: ordersActionTypes.ORDERS_RESET_MAILINGS });
};

export const setActiveOrderStage = (val) => {
  store.dispatch({
    type: ordersActionTypes.ORDERS_SET_ACTIVE_ORDER_STAGE,
    payload: val,
  });
};

export const setActiveOrderType = (val) => {
  store.dispatch({
    type: ordersActionTypes.ORDERS_SET_ACTIVE_ORDER_TYPE,
    payload: val,
  });
};

/**
 * Set values to mailings input object.
 *
 * @param payload - object
 */
export const setMailingsInput = (payload) => {
  const input = {
    name: payload.hasOwnProperty('pdf')
      ? payload.name
      : store.getState().orders.mailingsInput.name,
    pdf: payload.hasOwnProperty('pdf')
      ? payload.pdf
      : store.getState().orders.mailingsInput.pdf,
    s3Key: payload.hasOwnProperty('s3Key')
      ? payload.s3Key
      : store.getState().orders.mailingsInput.s3Key,
    s3SignedUrl: payload.hasOwnProperty('s3SignedUrl')
      ? payload.s3SignedUrl
      : store.getState().orders.mailingsInput.s3SignedUrl,
  };

  return store.dispatch({
    type: ordersActionTypes.ORDERS_SET_MAILINGS_INPUT,
    payload: input,
  });
};

/**
 * Set mailingsIsReady property.
 *
 * @param payload - bool
 */
export const setMailingsIsReady = (payload) => {
  return store.dispatch({
    type: ordersActionTypes.ORDERS_SET_MAILINGS_IS_READY,
    payload: payload,
  });
};

/**
 * Set mailings pdf preview object.
 *
 * @param payload.val - arraybuffer | number
 * @param payload.type - 'pages' | 'arrayBuffer'
 */
export const setMailingsPdfPreview = (payload) => {
  store.dispatch({
    type: ordersActionTypes.ORDERS_SET_MAILINGS_PDF_PREVIEW,
    payload: payload,
  });
};

/**
 * Set mailings postage object to state.
 *
 * @param payload.color - bool
 * @param payload.postage - String - 'A' | 'B'
 * @param payload.simplexDuplex - String - 'D' | 'S'
 * @param payload.type - String - 'envelope' | 'postcard'
 */
export const setMailingsPostage = (payload) => {
  const mailingsPostage = store.getState().orders.mailingsPostage;
  let postage = {
    color: payload.hasOwnProperty('color')
      ? payload.color
      : mailingsPostage.color,
    postage: payload.hasOwnProperty('postage')
      ? payload.postage
      : mailingsPostage.postage,
    simplexDuplex: payload.hasOwnProperty('simplexDuplex')
      ? payload.simplexDuplex
      : mailingsPostage.simplexDuplex,
    type: payload.hasOwnProperty('type') ? payload.type : mailingsPostage.type,
  };

  if (postage.type === 'postcard') {
    // Currently for postcard supplier postage is always "B".
    postage.postage = 'B';
  }

  return store.dispatch({
    type: ordersActionTypes.ORDERS_SET_MAILINGS_POSTAGE,
    payload: postage,
  });
};

export const setMailingsType = (payload) => {
  return store.dispatch({
    type: ordersActionTypes.ORDERS_SET_MAILINGS_TYPE,
    payload: payload.mailingsType,
  });
};

export const getListMailingsPrice = async (payload) => {
  const price = await request({
    url: '/lists/getListPrice',
    data: { listId: payload.listId, price: payload.price, type: payload.type },
    method: 'POST',
  });
  return price;
};

export const getListWaystarPrice = async (payload) => {
  const price = await request({
    url: '/lists/getListPrice',
    data: { listId: payload.listId, price: payload.price, type: payload.type },
    method: 'POST',
  });
  return price;
};

/**
 * Set a list to state.
 *
 * @param list
 */
export const setListToOrder = (list) => {
  store.dispatch({ type: ordersActionTypes.ORDERS_SET_LIST, payload: list });
};
