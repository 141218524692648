import { store } from "store";
import { request } from "helpers";
import { excelActionTypes } from "./actions";
import { showFlashMessage } from "store/flash_messages/tasks";
import * as text from "text-content";
import axios from "axios";

/**
 * Get excel download.
 *
 * @param payload.haveActiveUserColumns - bool - If user columns is selected and type === "car", we will display price for both vehicle rows and prospect rows.
 * @param payload.koncern - bool (optional) - Optional to provide when origin === "fleet".
 * @param payload.listIds - array - Provide when origin === "list".
 * @param payload.origin - "fleet" | "list"
 * @param payload.prospectId - number - Provide when origin === "fleet".
 * @param payload.removeProspectsWithoutNames - bool - Provide when origin === "list", we currently don't allow this option for fleet.
 * @param payload.columns - array
 * @param payload.type - "car" | "user"
 */
export const getExcelDownload = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload.prospectId && !payload.listIds) {
      console.error("Missing params in getExcelDownload", payload);
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    if (!payload.origin || !payload.type || !payload.columns) {
      console.error("Missing params in getExcelDownload", payload);
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    const signedUrl = await request({
      data: {
        haveActiveUserColumns: payload.haveActiveUserColumns,
        koncern: payload.origin === "fleet" ? !!payload.koncern : null,
        listIds: payload.origin === "list" ? payload.listIds : null,
        origin: payload.origin,
        prospectId: payload.origin === "fleet" ? payload.prospectId : null,
        removeProspectsWithoutNames:
          payload.origin === "list"
            ? !!payload.removeProspectsWithoutNames
            : null,
        columns: JSON.stringify(payload.columns),
        type: payload.type,
      },
      method: "post",
      url: "/excel/getExcelDownload/",
    });

    if (signedUrl instanceof Error) {
      console.error("Error in convertListsToExcel ", signedUrl);
      return showFlashMessage(tc.couldNotExportToExcel, "fail");
    }

    setExcelDownloadUrl(signedUrl);
  } catch (err) {
    return console.error("Error in getExcelDownload", err);
  }
};

/**
 * Get price for excel download.
 *
 * @param payload.haveActiveUserColumns - bool - If user columns is selected and type === "car", we will display price for both vehicle rows and prospect rows.
 * @param payload.koncern - bool (optional) - Optional to provide when origin === "fleet".
 * @param payload.listIds - array - Provide when origin === "list".
 * @param payload.origin - "fleet" | "list"
 * @param payload.prospectId - number - Provide when origin === "fleet".
 * @param payload.removeProspectsWithoutNames - bool - Provide when origin === "list", we currently don't allow this option for fleet.
 * @param payload.type - "car" | "user"
 */
export const getPriceForExcelDownload = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === "en"
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    resetExcel();

    if (!payload.prospectId && !payload.listIds) {
      console.error("Missing params in getPriceForExcelDownload", payload);
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    if (!payload.origin || !payload.type) {
      console.error("Missing params in getPriceForExcelDownload", payload);
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    const data = await request({
      data: {
        haveActiveUserColumns: payload.haveActiveUserColumns,
        koncern: payload.origin === "fleet" ? !!payload.koncern : null,
        listIds: payload.origin === "list" ? payload.listIds : null,
        prospectId: payload.origin === "fleet" ? payload.prospectId : null,
        removeProspectsWithoutNames:
          payload.origin === "list"
            ? !!payload.removeProspectsWithoutNames
            : null,
        origin: payload.origin,
        type: payload.type,
      },
      method: "post",
      url: "/excel/getPriceForExcelDownload/",
    });

    if (data instanceof Error) {
      console.error("Error in getPriceForExcelDownload ", data);
      return showFlashMessage(tc.genericFailMessage, "fail");
    }

    setExcelRowsOrderedManagement(
      Number.isFinite(data.rowsOrderedManagement)
        ? data.rowsOrderedManagement
        : 0
    );
    setExcelRowsOrderedName(
      Number.isFinite(data.rowsOrderedName) ? data.rowsOrderedName : 0
    );
    setExcelRowsProspects(data.rowsProspects);
    setExcelRowsVehicles(data.rowsVehicles);
    setExcelRowPriceProspect(data.rowPriceProspect);
    setExcelRowPriceVehicle(data.rowPriceVehicle);
    setExcelTotalCost(data.totalCost);
  } catch (err) {
    return console.error("Error in getPriceForExcelDownload", err);
  }
};

export const resetExcel = () => {
  setExcelDownloadUrl(null);
  setExcelRowsOrderedManagement(null);
  setExcelRowsOrderedName(null);
  setExcelRowsProspects(null);
  setExcelRowsVehicles(null);
  setExcelRowPriceProspect(null);
  setExcelRowPriceVehicle(null);
  setExcelTotalCost(null);
};

/**
 * Set downloadUrl.
 *
 * @param val
 */
export const setExcelDownloadUrl = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_DOWNLOAD_URL,
    payload: val,
  });
};

/**
 * Set orderedManagement.
 *
 * @param val
 */
export const setExcelRowsOrderedManagement = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWS_ORDERED_MANAGEMENT,
    payload: val,
  });
};

/**
 * Set orderedName.
 *
 * @param val
 */
export const setExcelRowsOrderedName = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWS_ORDERED_NAME,
    payload: val,
  });
};

/**
 * Set rowPrice.
 *
 * @param val
 */
export const setExcelRowPriceProspect = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWPRICE_PROSPECT,
    payload: val,
  });
};

/**
 * Set rowPrice.
 *
 * @param val
 */
export const setExcelRowPriceVehicle = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWPRICE_VEHICLE,
    payload: val,
  });
};

/**
 * Set rows.
 *
 * @param val
 */
export const setExcelRowsProspects = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWS_PROSPECTS,
    payload: val,
  });
};

/**
 * Set rows.
 *
 * @param val
 */
export const setExcelRowsVehicles = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_ROWS_VEHICLES,
    payload: val,
  });
};

/**
 * Set totalCost.
 *
 * @param val
 */
export const setExcelTotalCost = (val) => {
  store.dispatch({
    type: excelActionTypes.SET_EXCEL_TOTALCOST,
    payload: val,
  });
};
