import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { tableHelper } from 'helpers';
import LanguageContext from 'language-context';
import { connect } from 'react-redux';
import history from 'router-history';
import { updateSettings } from 'store/user/tasks';
import {
  getFleet,
  getFleetDebounced,
  getColumnOptions,
  setFleetDisplayHistoric,
  getColumnOptionsDebounced,
} from 'store/fleet/tasks';
import { TablePropsManaged } from 'components/table';
import Icon from 'components/icon';
import Info from 'components/info';
import Loading from 'components/loading';
import WidgetHeader from 'components/widget_header';

/**
 * Render a fleet table.
 *
 * When search is used a loading property is set to show Loading component until result from backend is set to store.
 * When loading is not true and there is no rows in data, we show Info component.
 * @param state.props.historic - bool (optional) - Get historic fleet or current.
 * @param state.props.koncern - bool (optional) - Get fleet for koncern.
 * @param state.props.prospectId - string - TS user id to get fleet for.
 * @param state.props.isMinimized - A boolean indicating whether the component is minimized or not.
 * @param state.props.onMinimizeChange - Callback function invoked when the minimize state changes.
 */
const FleetWidget = (state) => {
  const [fleet, setFleet] = useState({ total: 0, amount: 0, count: 0 });
  const [minimize, setMinimize] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState(null);
  const [sort, setSort] = useState({
    order: 'asc',
    orderBy: '',
  });
  const [isApplyingFilters, setIsApplyingFilters] = useState(false);
  const tc = useContext(LanguageContext);

  const firstLoad = useRef(true);
  const vehicleSettings = useMemo(() => {
    return state.user?.settings?.settings.prospect.vehicleTypes ?? undefined;
  }, [state.user?.settings?.settings.prospect.vehicleTypes]);

  useEffect(() => {
    setMinimize(state.props.isMinimized);
  }, [state.props.isMinimized]);
  useEffect(() => {
    const buildActiveFilters = () => {
      if (firstLoad.current || !state.isInitialized) {
        const result = {
          type: Object.entries(vehicleSettings ?? {}).reduce(
            (acc, [vehicleType, active]) => {
              if (active) return acc.concat({ key: vehicleType });
              else return acc;
            },
            []
          ),
        };
        return result;
      } else {
        return state.activeFilters;
      }
    };
    if (query?.length) {
      getFleetDebounced({
        koncern: !!state.props.koncern,
        historic: !!state.props.historic,
        page: page,
        prospectId: state.props.prospectId,
        rowsPerPage: rowsPerPage,
        query: query,
        sort: sort,
        activeFilters: buildActiveFilters(),
      });
    } else {
      getFleetDebounced({
        koncern: !!state.props.koncern,
        historic: !!state.props.historic,
        page: page,
        prospectId: state.props.prospectId,
        rowsPerPage: rowsPerPage,
        query: null,
        sort: sort,
        activeFilters: buildActiveFilters(),
      });
    }
    firstLoad.current = false; // otherwise fetching happens before filters from another table are reset, and you get the wrong results
  }, [
    page,
    rowsPerPage,
    sort,
    query,
    state.props.koncern,
    state.props.historic,
    state.props.prospectId,
    state.isInitialized,
    state.activeFilters,
    vehicleSettings,
  ]);

  useEffect(() => {
    // Determine if we should hide content initially (if no data, we hide).
    if (state.props.historic && state.fleet.fleetHistoric) {
      setFleet(state.fleet.fleetHistoric);
      if (
        !state.fleet.fleetHistoric?.data ||
        !state.fleet.fleetHistoric?.data?.length
      ) {
        setMinimize(true);
      } else {
        setMinimize(false);
      }
    } else if (!state.props.historic && state.fleet?.fleet) {
      setFleet(state.fleet.fleet);
      if (!state.fleet?.fleet?.data || !state.fleet?.fleet?.data?.length) {
        setMinimize(true);
      } else {
        setMinimize(false);
      }
    }
  }, [state.fleet.fleet, state.fleet.fleetHistoric, state.props.historic]);

  useEffect(() => {
    if (fleet && fleet.total && page * rowsPerPage > fleet.total) {
      setPage(0);
    }
  }, [rowsPerPage, page, fleet]);

  const _reloadData = () => {
    setQuery('');
    // Reload data clean, can be necessary when search query results in no rows.
    getFleet({
      koncern: !!state.props.koncern,
      historic: !!state.props.historic,
      page: 0,
      prospectId: state.props.prospectId,
      rowsPerPage: 25,
    });
  };

  const reloadFleet = async (activeFilters) => {
    return await getFleet({
      koncern: !!state.props.koncern,
      historic: !!state.props.historic,
      page: page,
      prospectId: state.props.prospectId,
      rowsPerPage: rowsPerPage,
      query: null,
      sort: sort,
      activeFilters,
    });
  };

  const _stateCheck = () => {
    const bool = !!(fleet && !fleet.loading);
    return bool;
  };

  return (
    <div
      className="fleetWidgetWrapper"
      style={{
        overflowX: minimize
          ? 'hidden'
          : state.props.isCompanyPage
          ? 'auto'
          : 'hidden',
      }}
    >
      <div className="fleetWidgetWrapper__fleetWidget">
        <div className="fleetWidgetWrapper__fleetWidget__header">
          <WidgetHeader
            dashboardItems={
              state.props.isCompanyPage
                ? null
                : _stateCheck()
                ? [
                    {
                      disabled: !minimize,
                      icon: 'maximize',
                      label: tc.maximize,
                      onClick: () => {
                        setMinimize(false);
                      },
                    },
                    {
                      disabled: minimize,
                      icon: 'reset',
                      label: tc.reload,
                      onClick: _reloadData,
                    },
                    {
                      disabled: minimize,
                      icon: 'minimize',
                      label: tc.minimize,
                      onClick: () => {
                        setMinimize(true);
                        if (state.props.onMinimizeChange) {
                          state.props.onMinimizeChange(true);
                        }
                      },
                    },
                  ]
                : null
            }
            headline={state.props.historic ? tc.fleetHistoric : tc.fleet}
            headlineSub={
              _stateCheck() || (!_stateCheck() && isApplyingFilters) ? (
                fleet?.data && fleet?.data?.length === 0 ? (
                  tc.noVehicles
                ) : query && query.length ? (
                  `${tc.showing} ${tc.searchResult.toLowerCase()}: ${
                    fleet.amount
                  } ${tc.vehicles.toLowerCase()}`
                ) : fleet.amount === 1000 && state.props.historic ? (
                  `${
                    tc.showing
                  } ${tc.maximum.toLowerCase()} 1000 ${tc.vehicles.toLowerCase()}`
                ) : fleet.amount === 10000 && !state.props.historic ? (
                  `${
                    tc.showing
                  } ${tc.maximum.toLowerCase()} 10 000 ${tc.vehicles.toLowerCase()}`
                ) : (
                  `${tc.total} ${
                    fleet.total ? fleet.total : 0
                  } ${tc.vehicles.toLowerCase()}`
                )
              ) : (
                <Loading small={true} />
              )
            }
          />
        </div>
        <div
          className={
            minimize
              ? 'fleetWidgetWrapper__fleetWidget__content minimize'
              : 'fleetWidgetWrapper__fleetWidget__content'
          }
        >
          {!window.location.pathname.includes('vagnpark') && (
            <div className="fleetWidgetWrapper__fleetWidget__content__navigate">
              <p
                onClick={() => {
                  if (state.props.koncern) {
                    setFleetDisplayHistoric(!!state.props.historic);
                    history.push('/vagnpark/koncern/' + state.props.prospectId);
                  } else {
                    setFleetDisplayHistoric(!!state.props.historic);
                    history.push('/vagnpark/' + state.props.prospectId);
                  }
                }}
              >
                {tc.navigateToFleet}
                <Icon val="navigateNext" />
              </p>
            </div>
          )}

          <>
            {isApplyingFilters ? <Loading /> : null}

            <TablePropsManaged
              activeFilters={state.activeFilters}
              optionsInitialized={state.isInitialized}
              reloadData={async (activeFilters) => {
                setIsApplyingFilters(true);
                await reloadFleet(activeFilters);
                setIsApplyingFilters(false);
              }}
              allowColumnFilters={
                state.props.historic || state.props.koncern ? false : true
              }
              columns={tableHelper.getVehicleColumns({
                activeColumns: state.props.historic
                  ? state.user?.settings?.settings?.tables?.vehiclesHistoric
                      ?.columns
                  : state.user?.settings?.settings?.tables?.vehicles?.columns,
                historic: !!state.props.historic,
                koncern: !!state.props.koncern,
                isForCompanyFleet: true,
              })}
              onColumnsChange={(columns) => {
                if (state.user?.settings?.settings?.tables) {
                  const type = state.props.historic
                    ? 'vehiclesHistoric'
                    : 'vehicles';

                  let settings = {
                    ...state.user.settings.settings,
                    tables: {
                      ...state.user.settings.settings.tables,
                      [type]: {
                        ...state.user.settings.settings.tables[type],
                        columns: columns,
                      },
                    },
                  };

                  updateSettings({
                    settings: settings,
                  });
                }
              }}
              order={sort.order}
              orderBy={sort.orderBy}
              page={page}
              query={query}
              pageChange={(val) => {
                setPage(val);
              }}
              rows={tableHelper.getVehicleRows({
                rows: fleet?.data ?? [],
                historic: state.props.historic,
                koncern: !!state.props.koncern,
              })}
              rowsPerPage={rowsPerPage}
              rowsPerPageChange={(val) => {
                setRowsPerPage(val);
              }}
              search={(val) => {
                setQuery(val);
              }}
              sort={(val) => {
                setSort(val);
              }}
              total={fleet.total > fleet.amount ? fleet.total : fleet.amount}
              vehicleSettings={
                state.user?.settings?.settings.prospect.vehicleTypes
              }
              getColumnOptions={async (
                columns,
                activeFilters,
                activeSearch
              ) => {
                if (!columns) return [];

                return await getColumnOptions({
                  targetId: state.props.prospectId,
                  columns,
                  activeFilters,
                  activeSearch,
                });
              }}
            />
          </>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    fleet: state.fleet,
    props: props,
    user: state.user,
    activeFilters: state.table.activeFilters,
    isInitialized: state.table.isInitialized,
  };
};

export default connect(MapStateToProps)(FleetWidget);
