import React, { useContext, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  savePassword,
  updateSettings,
  updateUser,
  userLogout,
} from 'store/user/tasks';
import { showFlashMessage } from 'store/flash_messages/tasks';
import ReactS3Uploader from 'react-s3-uploader';
import LanguageContext from 'language-context';
import ActionButton from 'components/action_button';
import Icon from 'components/icon';
import Loading from 'components/loading';
import ToggleOption from 'components/toggle_option';
import Tooltip from 'components/tooltip';
import UserImage from 'components/user_image';
import SettingsResponsibleSeller from 'components/settings/settings_responsible_seller';
/**
 * Universal settings section.
 *
 * Note: If we ever want to add language to settings component, we already have support for swe/en:
 * updateUser({ lang: state.user.info.lang === "swe" ? "en" : "swe" });
 */
const SettingsUniversal = (state) => {
  const [editPhone, setEditPhone] = useState(null);
  const [passwordHint, setPasswordHint] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const phoneInputRef = useRef(null);
  const tc = useContext(LanguageContext);

  useEffect(() => {
    phoneInputRef?.current && phoneInputRef.current.focus();
  }, [editPhone]);

  const _finishUpload = async (e, f) => {
    if (e?.filename?.length) {
      await updateUser({
        img: `https://s3.eu-central-1.amazonaws.com/bilp-test/${e.filename}`,
      });
    }
  };

  const _removeUserImage = async () => {
    await updateUser({
      img: '',
    });
  };

  /**
   * Save password.
   */
  const _savePassword = async () => {
    if (password1 !== password2) {
      // Check if match.
      return setPasswordHint(tc.settingsPasswordHintNotMatching);
    } else if (password1.length < 6 || password2.length < 6) {
      // Check if short.
      return setPasswordHint(tc.settingsPasswordHintTooShort);
    }

    // Save.
    const payload = { password: password1 };
    setPassword1('');
    setPassword2('');
    setPasswordHint('');
    await savePassword(payload);
    showFlashMessage(tc.settingsPasswordHintSaved, 'success');
  };

  const _savePhone = () => {
    // We don't need a check for valid phone no, they should be able to put in empty string "00000" etc?
    updateUser({
      phone: editPhone,
    });
    setEditPhone(null);
  };

  const _startFileUpload = () => {
    document.querySelector('#s3UploaderSettingsUniversal').click();
  };

  const _stateCheck = () => {
    return !!state.user?.settings?.settings;
  };

  /**
   * Toggle email notifications.
   */
  const _toggleEmail = async (key) => {
    let settings = state.user.settings.settings;
    settings.email[key] = !settings.email[key];
    return await updateSettings({ settings: settings });
  };

  const _uploadError = (message) => {
    console.error('S3 file upload error:', message);
    showFlashMessage(tc.couldNotUploadFile, 'fail');
  };

  const _validateFileType = (file, next) => {
    const maxSize = 1024 * 1024 * 2;
    const acceptedFileTypes = ['jpg', 'jpeg', 'png'];
    const splits = file.name.split('.');
    const fileType = splits[splits.length - 1];

    if (file.size > maxSize) {
      console.error('Too large file size');
      return showFlashMessage(tc.couldNotUploadFile, 'fail');
    } else if (!acceptedFileTypes.includes(fileType)) {
      console.error('Wrong file type');
      return showFlashMessage(tc.couldNotUploadFile, 'fail');
    } else {
      next(file);
    }
  };

  return _stateCheck() ? (
    <div className="settingsPageWrapper">
      <div className="settingsPageWrapper__settingsPage">
        <div className="settingsPageWrapper__settingsPage__section" id="user">
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.user}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <div className="settingsPageWrapper__settingsPage__section__content__flexHolder userInfoContainer">
              <div className="settingsPageWrapper__settingsPage__section__content__flexHolder__third userImageHolder">
                <UserImage type="settings" />
                {state.user?.info?.img?.length ? (
                  <div className="userImageBtn" onClick={_removeUserImage}>
                    <Icon val="remove" />
                  </div>
                ) : (
                  <div className="userImageBtn" onClick={_startFileUpload}>
                    <Icon val="add" />
                  </div>
                )}
              </div>
              <div className="settingsPageWrapper__settingsPage__section__content__flexHolder__twoThirds">
                <div className="userInfoRow">
                  <div className="userInfoRow__left">{tc.name}:</div>
                  <div className="userInfoRow__right">
                    <span>
                      <strong>{state.user.info.name}</strong>
                    </span>
                  </div>
                </div>
                <div className="userInfoRow">
                  <div className="userInfoRow__left">{tc.facility}:</div>
                  <div className="userInfoRow__right">
                    <span>{state.user.info.dealerName}</span>
                  </div>
                </div>
                <div className="userInfoRow">
                  <div className="userInfoRow__left">{tc.email}:</div>
                  <div className="userInfoRow__right">
                    <span>{state.user.info.email}</span>
                  </div>
                </div>
                <div className="userInfoRow">
                  <div className="userInfoRow__left">{tc.phone}:</div>
                  <div className="userInfoRow__right">
                    {/*Historically phone has contained ""/null/"0000" etc, hence the checks.*/}
                    <form
                      autoComplete="off"
                      className={`${editPhone === null ? 'hidden' : ''}`}
                    >
                      <input
                        type="text"
                        onChange={(e) => {
                          setEditPhone(e.target.value);
                        }}
                        ref={phoneInputRef}
                        value={editPhone === null ? '' : editPhone}
                      />
                    </form>
                    <div className={`${editPhone === null ? 'hidden' : ''}`}>
                      <Tooltip tooltipContent={tc.cancel}>
                        <Icon
                          val="clear"
                          onClick={() => {
                            setEditPhone(null);
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={`${editPhone === null ? 'hidden' : ''}`}>
                      <Tooltip tooltipContent={tc.save}>
                        <Icon val="save" onClick={_savePhone} />
                      </Tooltip>
                    </div>
                    {!state.user.info?.phone?.length ||
                    (state.user.info?.phone &&
                      state.user.info.phone.includes('000')) ? (
                      <span className={`${editPhone === null ? '' : 'hidden'}`}>
                        {tc.dataMissing}
                      </span>
                    ) : (
                      <span className={`${editPhone === null ? '' : 'hidden'}`}>
                        {state.user.info.phone}
                      </span>
                    )}
                    <div className={`${editPhone === null ? '' : 'hidden'}`}>
                      <Tooltip tooltipContent={tc.editPhone}>
                        <Icon
                          val="edit"
                          onClick={() => {
                            if (
                              !state.user.info?.phone?.length ||
                              (state.user.info?.phone &&
                                state.user.info.phone.includes('000'))
                            ) {
                              setEditPhone('');
                            } else {
                              setEditPhone(state.user.info.phone);
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {state.user?.connections?.length ? (
                  <div className="userInfoRow connections">
                    <div className="userInfoRow__left">{tc.connectedTo}:</div>
                    <div className="userInfoRow__right">
                      <span>
                        {state.user.connections
                          .map((num) => num.name)
                          .join(', ')}
                      </span>
                    </div>
                  </div>
                ) : null}
                {!state.user.moduleMode ? (
                  <div className="userInfoRow logout">
                    <div className="userInfoRow__left" />
                    <div className="userInfoRow__right">
                      <ActionButton
                        label={tc.logout}
                        onClick={() => {
                          userLogout();
                        }}
                        type="highlight"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="editPassword"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.editPassword}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <form className="formStyled password">
              <div className="formStyled__row half noMarginBottom">
                <div className="label">{tc.writePassword}</div>
              </div>
              <div className="formStyled__row half noMarginBottom">
                <div className="label">{tc.repeatPassword}</div>
              </div>
              <div className="formStyled__row half">
                <input
                  type="password"
                  onChange={(e) => {
                    setPassword1(e.target.value);
                  }}
                  value={password1}
                />
              </div>
              <div className="formStyled__row half">
                <input
                  type="password"
                  onChange={(e) => {
                    setPassword2(e.target.value);
                  }}
                  value={password2}
                />
              </div>
              {passwordHint?.length ? (
                <div className="formStyled__row hint">
                  <Icon val="infoOutlined" />
                  {passwordHint}
                </div>
              ) : null}
              <div className="formStyled__row buttons">
                <div
                  onClick={() => {
                    setPassword1('');
                    setPassword2('');
                    setPasswordHint('');
                  }}
                >
                  {tc.reset}
                </div>
                <div
                  className={`saveButton ${
                    password1?.length && password2?.length ? '' : 'disabled'
                  }`}
                  onClick={_savePassword}
                >
                  {tc.savePassword}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="emailNotifications"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.emailNotifications}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <div className="toggleOptionsHolder">
              <div className="toggleOptionsHolder__item">
                <ToggleOption
                  active={!!state.user?.settings?.settings?.email?.listActivity}
                  label={tc.emailNotificationsListActivity}
                  labelSub={tc.emailNotificationsListActivity2}
                  onClick={() => {
                    _toggleEmail('listActivity');
                  }}
                />
              </div>
              <div className="toggleOptionsHolder__item">
                <ToggleOption
                  active={
                    !!state.user?.settings?.settings?.email?.plannedEvents
                  }
                  label={tc.emailNotificationsPlannedEvents}
                  labelSub={tc.emailNotificationsPlannedEvents2}
                  onClick={() => {
                    _toggleEmail('plannedEvents');
                  }}
                />
              </div>
              <div className="toggleOptionsHolder__item">
                <ToggleOption
                  active={!!state.user?.settings?.settings?.email?.reminders}
                  label={tc.emailNotificationsReminders}
                  labelSub={tc.emailNotificationsReminders2}
                  onClick={() => {
                    _toggleEmail('reminders');
                  }}
                />
              </div>
              <div className="toggleOptionsHolder__item">
                <ToggleOption
                  active={!!state.user?.settings?.settings?.email?.hotLeads}
                  label={tc.activityDeals}
                  labelSub={tc.notificationOnDealShared}
                  onClick={() => {
                    _toggleEmail('hotLeads');
                  }}
                />
              </div>
              <div className="toggleOptionsHolder__item">
                <ToggleOption
                  active={
                    !!state.user?.settings?.settings?.email
                      ?.email_order_mailings
                  }
                  label={tc.mailingsNotice}
                  labelSub={tc.mailingsNoticeProcessing}
                  onClick={() => {
                    _toggleEmail('email_order_mailings');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="contactUs"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.contactUs}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            <SettingsResponsibleSeller dealerOwnerId={9408} />
            {tc.contactUsInfo}
          </div>
        </div>
        <div
          className="settingsPageWrapper__settingsPage__section"
          id="termsAndConditions"
        >
          <div className="settingsPageWrapper__settingsPage__section__header">
            {tc.termsAndConditions}
          </div>
          <div className="settingsPageWrapper__settingsPage__section__content">
            {tc.seeOurInformationSecurity},{' '}
            <a
              className="linkInText"
              target="_blank"
              href="/informationssakerhet"
            >
              {tc.clickHere.toLowerCase()}
            </a>
            .
          </div>
        </div>
      </div>
      <div className="hidden">
        <ReactS3Uploader
          id="s3UploaderSettingsUniversal"
          signingUrl="/s3/sign"
          onFinish={_finishUpload}
          onError={_uploadError}
          preprocess={_validateFileType}
          contentDisposition="auto"
          scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
          uploadRequestHeaders={{ 'x-amz-acl': 'private' }}
        />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(MapStateToProps)(SettingsUniversal);
