import React from 'react';
import { agileHelper, textHelper, vehicleHelper } from 'helpers';
import companyHelper from 'shared_helpers/company_helper';
import moment from 'moment';
import listHelper from 'shared_helpers/list_helper';
import { setActiveOrderStage, setListToOrder } from 'store/orders/tasks';
import { activityHelper } from 'helpers';
import { store } from 'store';
import * as text from 'text-content';
import Icon from 'components/icon';
import IconStyled from 'components/icon_styled';
import Tooltip from 'components/tooltip';
import PopupHolder from 'components/popup_holder';
import WidgetHeader from 'components/widget_header';
import TooltipComponent from 'components/tooltip/tooltip';
import ActivitiesFlow from 'components/activities/activities_flow';
import ActivityPopupProspect from 'components/activity/activity_popup_prospect';

/**
 * These methods help us format data for use in Table component.
 *
 * Keep id for columns consistent with what is sent from backend, since that is what we send back to sort on columns.
 * Note that it doesn't work to name a column "id", this wont work: { id: "id", numeric: false, label: tc.actionType },
 */
export const tableHelper = {
  descendingComparator: (a, b, orderBy) => {
    if (
      orderBy === 'created' ||
      orderBy === 'created_at' ||
      orderBy === 'createdAt' ||
      orderBy === 'updated' ||
      orderBy === 'updated_at' ||
      orderBy === 'updatedAt'
    ) {
      if (new Date(b[orderBy]) < new Date(a[orderBy])) {
        return -1;
      } else if (new Date(b[orderBy]) > new Date(a[orderBy])) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      } else if (b[orderBy] > a[orderBy]) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  // getActionTypesColumns: (displayMode, historic) => {
  //   const tc = store?.getState()?.user?.info?.lang
  //     ? store.getState().user.info.lang === "en"
  //       ? text.english
  //       : text.swedish
  //     : text.swedish;
  //
  //   const actionTypes = activityHelper.getActionTypes(!historic);
  //
  //   actionTypes.sort((a, b) => {
  //     if (tc[a].toLowerCase() < tc[b].toLowerCase()) {
  //       return -1;
  //     } else if (tc[a].toLowerCase() > tc[b].toLowerCase()) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //
  //   return displayMode === "user" || displayMode === "deal"
  //     ? [
  //         {
  //           id: displayMode === "user" ? "user" : "deal",
  //           numeric: false,
  //           label: displayMode === "user" ? tc.user : tc.deal,
  //           visible: true,
  //         },
  //       ]
  //         .concat(
  //           actionTypes.map((action) => {
  //             if (displayMode === "deal" && action === "comment") {
  //               // No comments column for displayMode "deal", because we don't have deal name for these (which is fine).
  //               return null;
  //             }
  //             return {
  //               id: action,
  //               numeric: true,
  //               label: tc[action],
  //               visible: true,
  //             };
  //           })
  //         )
  //         .filter((num) => num)
  //     : [
  //         { id: "action", numeric: false, label: tc.actionType, visible: true },
  //         { id: "amount", numeric: false, label: tc.amount, visible: true },
  //         { id: "users", numeric: false, label: tc.users, visible: true },
  //       ];
  // },
  // getActionTypesRows: (rows, displayMode, historic) => {
  //   const tc = store?.getState()?.user?.info?.lang
  //     ? store.getState().user.info.lang === "en"
  //       ? text.english
  //       : text.swedish
  //     : text.swedish;
  //
  //   if (!rows || !rows?.length) {
  //     return [];
  //   }
  //
  //   if (displayMode === "action") {
  //     rows = rows.map((num) => {
  //       if (!num.action) {
  //         num.action = "comment";
  //       }
  //       return num;
  //     });
  //     let grouped = _.groupBy(rows, "action");
  //     const result = [];
  //
  //     Object.keys(grouped).map((key) => {
  //       let users = _.chain(grouped[key])
  //         .map((num) => num.user?.name)
  //         .uniq()
  //         .value()
  //         .join(", ");
  //
  //       result.push({
  //         action: tc[key],
  //         amount: grouped[key].length,
  //         users: users,
  //       });
  //     });
  //
  //     return result.sort((a, b) => {
  //       if (!a.action || !b.action) {
  //         return -1;
  //       }
  //       if (a.action.toLowerCase() < b.action.toLowerCase()) {
  //         return -1;
  //       } else if (a.action.toLowerCase() > b.action.toLowerCase()) {
  //         return 1;
  //       } else {
  //         return 0;
  //       }
  //     });
  //   } else if (displayMode === "user") {
  //     // For this display mode we sort users on dealer.
  //     // Add row for dealer total sum, and if multiple dealers total sum for all dealers.
  //     let types = {};
  //     activityHelper
  //       .getActionTypes(!historic)
  //       .sort((a, b) => {
  //         if (tc[a].toLowerCase() < tc[b].toLowerCase()) {
  //           return -1;
  //         } else if (tc[a].toLowerCase() > tc[b].toLowerCase()) {
  //           return 1;
  //         } else {
  //           return 0;
  //         }
  //       })
  //       .forEach((key) => {
  //         types[key] = "";
  //       });
  //
  //     // Group by dealer, every dealer have a rows array.
  //     let dealers = {};
  //     rows.forEach((num) => {
  //       if (!dealers[num?.user?.dealer_id]) {
  //         const grouped = _.groupBy(
  //           rows.filter((x) => x?.user?.dealer_id === num.user.dealer_id),
  //           "user_id"
  //         );
  //
  //         const dealerRows = [];
  //         const typesObj = { ...types };
  //
  //         Object.keys(grouped).map((key) => {
  //           Object.keys(typesObj).map((type) => {
  //             const value = rows.filter((row) => {
  //               if (!row.action && type === "comment" && row.comment) {
  //                 return +key === +row.user.id;
  //               } else {
  //                 return row.action === type && +row.user.id === +key;
  //               }
  //             }).length;
  //
  //             // typesObj[type] = value > 0 ? value : "";
  //             typesObj[type] = value;
  //           });
  //
  //           dealerRows.push({
  //             user: rows.find((x) => +x.user_id === +key).user.name,
  //             ...typesObj,
  //           });
  //
  //           dealers[num.user.dealer_id] = {
  //             name: num.user.dealerName,
  //             rows: dealerRows.sort((a, b) => (a.user > b.user ? 1 : -1)),
  //           };
  //         });
  //       }
  //     });
  //
  //     // Put rows for each dealer into result array.
  //     // If more than one user, add a row for each dealer with total sum values.
  //     let result = [];
  //     for (const key in dealers) {
  //       result = result.concat(dealers[key].rows);
  //
  //       if (Object.keys(dealers).length > 1 || dealers[key].rows.length > 1) {
  //         const typesObj = { ...types };
  //         let dealerRow = {
  //           user: dealers[key].name,
  //         };
  //
  //         Object.keys(typesObj).map((type) => {
  //           const value = rows.filter((row) => {
  //             return (
  //               +row.user.dealer_id === +key &&
  //               ((!row.action && type === "comment" && row.comment) ||
  //                 row.action === type)
  //             );
  //           }).length;
  //           // dealerRow[type] = value > 0 ? value : "-";
  //           dealerRow[type] = value;
  //         });
  //
  //         result = result.concat(dealerRow);
  //       }
  //     }
  //
  //     return result.flat();
  //   } else if (displayMode === "deal") {
  //     rows = rows
  //       .map((num) => {
  //         if (
  //           num.action !== "comment" &&
  //           ((num.target && id.isValid(num.target)) ||
  //             (num.dealId && id.isValid(num.dealId)) ||
  //             (num.deal?._id && id.isValid(num.deal._id)) ||
  //             (num.deal_id && id.isValid(num.deal_id)))
  //         ) {
  //           // displayMode === "deal", so only display where target is a deal.
  //           // It's a bit inconsistent where we store the deal id, hence the check for different properties.
  //           // Also display no comments column for this displayMode.
  //
  //           // Add a property to store target deal id.
  //           num.targetDeal = num.deal?._id
  //             ? num.deal._id
  //             : num.deal_id
  //             ? num.deal_id
  //             : num.target
  //             ? num.target
  //             : num.dealId;
  //
  //           return num;
  //         } else {
  //           return null;
  //         }
  //       })
  //       .filter((num) => num);
  //
  //     let grouped = _.groupBy(rows, "targetDeal");
  //     const result = [];
  //     let types = {};
  //     const actionTypes = activityHelper.getActionTypes(!historic);
  //
  //     actionTypes
  //       .sort((a, b) => {
  //         if (tc[a].toLowerCase() < tc[b].toLowerCase()) {
  //           return -1;
  //         } else if (tc[a].toLowerCase() > tc[b].toLowerCase()) {
  //           return 1;
  //         } else {
  //           return 0;
  //         }
  //       })
  //       .forEach((key) => {
  //         types[key] = "";
  //       });
  //
  //     Object.keys(grouped).map((key) => {
  //       Object.keys(types).map((type) => {
  //         const value = rows.filter(
  //           (row) => row.action === type && row.targetDeal === key
  //         ).length;
  //         types[type] = value > 0 ? value : "";
  //       });
  //
  //       const row = rows.find((num) => num.targetDeal === key);
  //       const deal = (
  //         <NavLink exact to={"/affar/" + key}>
  //           {row.deal?.name
  //             ? row.deal.name
  //             : row.name
  //             ? row.name
  //             : tc.nameMissing}
  //         </NavLink>
  //       );
  //
  //       result.push({
  //         deal: deal,
  //         ...types,
  //       });
  //     });
  //
  //     return result;
  //   }
  // },
  getComparator: (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => tableHelper.descendingComparator(a, b, orderBy)
      : (a, b) => -tableHelper.descendingComparator(a, b, orderBy);
  },
  // getFleetColumns: (historic, koncern) => {
  //   const tc = store?.getState()?.user?.info?.lang
  //     ? store.getState().user.info.lang === "en"
  //       ? text.english
  //       : text.swedish
  //     : text.swedish;
  //
  //   let allColumns = [
  //     { id: "brand", numeric: false, label: tc.brand, visible: true },
  //     { id: "model", numeric: false, label: tc.model, visible: true },
  //     { id: "type", numeric: false, label: tc.type, visible: true },
  //     {
  //       id: "reg_number",
  //       numeric: false,
  //       label: tc.regNumberShort,
  //       visible: true,
  //     },
  //     !!koncern
  //       ? {
  //           id: "orgnr",
  //           numeric: true,
  //           label: tc.orgNumberShort,
  //           visible: true,
  //         }
  //       : null,
  //     { id: "new", numeric: false, label: tc.bought, visible: true },
  //     {
  //       id: "seller_name",
  //       numeric: false,
  //       label: tc.sellerName,
  //       visible: false,
  //     },
  //   ];
  //
  //   if (historic) {
  //     allColumns = allColumns.concat([
  //       { id: "real_date_sold", numeric: false, label: tc.sold, visible: true },
  //       {
  //         id: "holdingPeriod",
  //         numeric: false,
  //         label: tc.holdingPeriod,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "acquiredAges",
  //         numeric: false,
  //         label: tc.acquiredAges,
  //         hideSort: true,
  //         visible: true,
  //       },
  //     ]);
  //   } else {
  //     allColumns = allColumns.concat([
  //       {
  //         id: "possession",
  //         numeric: false,
  //         label: tc.acquiredAges,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "date_registered",
  //         numeric: false,
  //         label: tc.regDate,
  //         visible: true,
  //       },
  //       {
  //         id: "financed_by",
  //         numeric: false,
  //         label: tc.financed,
  //         visible: true,
  //       },
  //       {
  //         id: "car_status",
  //         numeric: false,
  //         label: tc.inService,
  //         visible: false,
  //       },
  //       { id: "fuel", numeric: false, label: tc.fuel, visible: true },
  //       {
  //         id: "climate_classification",
  //         numeric: false,
  //         label: tc.climateClassification,
  //         visible: false,
  //       },
  //       {
  //         id: "submission",
  //         numeric: false,
  //         label: tc.submission,
  //         visible: false,
  //       },
  //     ]);
  //   }
  //
  //   return allColumns.filter((num) => num);
  // },
  // getFleetRows: (rows, historic, koncern) => {
  //   if (rows && rows.length) {
  //     return historic
  //       ? getFleetRowsHistoric(rows, koncern)
  //       : getFleetRowsCurrent(rows, koncern);
  //   } else {
  //     return [];
  //   }
  // },
  getInspectColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'phase', numeric: false, label: tc.status, visible: true },
      { id: 'name', numeric: false, label: tc.name, visible: true },
      { id: 'userName', numeric: false, label: tc.owner, visible: true },
      { id: 'created', numeric: false, label: tc.created, visible: true },
      { id: 'updated', numeric: false, label: tc.updated, visible: true },
      {
        id: 'comments',
        numeric: false,
        label: tc.activity,
        hideSort: true,
        visible: true,
      },
    ];
  },
  getInspectRows: (rows) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const columns = tableHelper.getInspectColumns();
    return rows.map((row, i) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'created') {
          obj[column.id] = moment(new Date(row.created), 'DD/MM/YYYY').format(
            'LL HH:mm'
          );
        } else if (column.id === 'updated') {
          obj[column.id] = moment(new Date(row.updated), 'DD/MM/YYYY').format(
            'LL HH:mm'
          );
        } else if (column.id === 'comments' && row.id) {
          // Don't display "move" activities here.
          const activities = Array.isArray(row.activity)
            ? row.activity.filter((num) => num.action !== 'move')
            : null;

          // Render a popup holder for comments.
          obj[column.id] = activities?.length ? (
            <PopupHolder
              popupContent={
                <>
                  <ActivitiesFlow
                    activityTypesToDisplay={activityHelper
                      .getActionTypes(false)
                      .filter((t) => t !== 'move')}
                    allRows={true}
                    data={activities}
                    embedded={true}
                    hideEdit={true}
                  />
                </>
              }
              popupHeader={
                <WidgetHeader
                  headline={tc.activity}
                  headlineSub={tc.allActivitiesIncludingComments}
                />
              }
              popupSize="medium"
              visibleContent={
                <div className="tableCellIconHolder">
                  <Tooltip
                    horizontalDirection="left"
                    tooltipContent={tc.clickToShowActivity}
                  >
                    <Icon val="comment" />
                  </Tooltip>
                </div>
              }
            />
          ) : null;
        } else if (column.id === 'phase') {
          obj[column.id] = agileHelper.buildPhaseToColumnTitle(row[column.id]);
        } else if (column.id === 'name') {
          obj[column.id] =
            row[column.id] && row[column.id].length
              ? row[column.id]
              : tc.nameMissing;
        } else if (column.id === 'userName') {
          obj[column.id] =
            row.user?.name && row.user?.name?.length
              ? row.user.name
              : tc.nameMissing;
        } else {
          obj[column.id] = row[column.id];
        }
      });

      obj.url = '/affar/' + row.id;
      obj.id = row.id;
      return obj;
    });
  },
  getKoncernStructureColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'name', numeric: false, label: tc.name, visible: true },
      {
        id: 'orgnr',
        numeric: false,
        label: tc.orgNumberShort,
        hideSort: true,
        visible: true,
      },
      { id: 'numberOfCars', numeric: true, label: tc.total, visible: true },
      {
        id: 'percentage',
        numeric: false,
        label: '%',
        hideSort: true,
        visible: true,
      },
      { id: 'numberOfCarsATR', numeric: true, label: tc.ATR, visible: true },
      { id: 'numberOfCarsATV', numeric: true, label: tc.ATV, visible: true },
      { id: 'numberOfCarsBU', numeric: true, label: tc.BU, visible: true },
      { id: 'numberOfCarsHB', numeric: true, label: tc.HB, visible: true },
      { id: 'numberOfCarsHV', numeric: true, label: tc.HV, visible: true },
      { id: 'numberOfCarsLB', numeric: true, label: tc.LB, visible: true },
      { id: 'numberOfCarsMC', numeric: true, label: tc.MC, visible: true },
      { id: 'numberOfCarsMP', numeric: true, label: tc.MP, visible: true },
      { id: 'numberOfCarsMR', numeric: true, label: tc.MR, visible: true },
      { id: 'numberOfCarsPB', numeric: true, label: tc.PB, visible: true },
      { id: 'numberOfCarsSS', numeric: true, label: tc.SS, visible: true },
      { id: 'numberOfCarsSV', numeric: true, label: tc.SV, visible: true },
      { id: 'numberOfCarsTLB', numeric: true, label: tc.TLB, visible: true },
    ];
  },
  getKoncernStructureRows: (structure, total, disabledRows) => {
    if (!structure || structure.length === 0) {
      return [];
    }

    const columns = tableHelper.getKoncernStructureColumns();
    return structure.map((row, i) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'percentage') {
          if (disabledRows.includes(row.id)) {
            // Row is disabled, don't show any value.
            obj[column.id] = '';
          } else {
            const share =
              row[column.id] === 0
                ? 0
                : Math.round((row['numberOfCars'] / total) * 100);
            obj[column.id] = `${share}%`;
          }
        } else if (column.id === 'name') {
          // Add divs with classes based on row level as well as level of next row.
          const nextRow = structure[i + 1];
          let space;
          switch (row.level) {
            case 1:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel1'
                      : 'koncernStructureItem__koncernStructureLevel1'
                  }
                />
              );
              break;
            case 2:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel2'
                      : 'koncernStructureItem__koncernStructureLevel2'
                  }
                />
              );
              break;
            case 3:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel3'
                      : 'koncernStructureItem__koncernStructureLevel3'
                  }
                />
              );
              break;
            case 4:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel4'
                      : 'koncernStructureItem__koncernStructureLevel4'
                  }
                />
              );
              break;
            case 5:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel5'
                      : 'koncernStructureItem__koncernStructureLevel5'
                  }
                />
              );
              break;
            case 6:
              space = (
                <div
                  className={
                    nextRow && nextRow.level === row.level
                      ? 'koncernStructureItem__koncernStructureSameLevel6'
                      : 'koncernStructureItem__koncernStructureLevel6'
                  }
                />
              );
              break;
            default:
              space = '';
          }
          obj[column.id] = (
            <div className="koncernStructureItem">
              {space}
              {row[column.id]}
            </div>
          );
        } else if (column.id === 'orgnr') {
          obj[column.id] = row.id;
        } else {
          obj[column.id] =
            !row[column.id] && row[column.id] !== 0 ? '-' : row[column.id];
        }
      });

      obj.id = row.id;
      obj.url = '/foretag/' + row.id;

      return obj;
    });
  },
  getLeadsColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'createdAt', numeric: false, label: tc.received, visible: true },
      { id: 'name', numeric: false, label: tc.name, visible: true },
      // { id: "company", numeric: false, label: tc.company, visible: true },
      { id: 'city', numeric: false, label: tc.city, visible: true },
      { id: 'leadsType', numeric: false, label: tc.leadsType, visible: true },
      // { id: "campaign", numeric: false, label: tc.campaign, visible: true },
      // { id: "leadsText", numeric: false, label: tc.leadsText, visible: true },
      // { id: "interestedOfModel", numeric: false, label: tc.interestedOfModel, visible: true },
      { id: 'orgNr', numeric: false, label: tc.orgNumberShort, visible: true },
      { id: 'regNr', numeric: false, label: tc.regNumberShort, visible: true },
      { id: 'expireDate', numeric: false, label: tc.expireDate, visible: true },
    ];
  },
  getLeadsRows: (rows, onClick) => {
    const columns = tableHelper.getLeadsColumns();

    return rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'createdAt' || column.id === 'expireDate') {
          obj[column.id] = row[column.id]
            ? moment(new Date(row[column.id]), 'DD/MM/YYYY').fromNow()
            : '';
        } else {
          obj[column.id] = row[column.id];
        }
      });

      obj.onClick = onClick;
      obj.id = row.id;
      return obj;
    });
  },
  getLeadsWidgetColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'name', numeric: false, label: tc.name, visible: true },
      { id: 'received', numeric: false, label: tc.received, visible: true },
      { id: 'leadsType', numeric: false, label: tc.leadsType, visible: true },
      { id: 'leadsText', numeric: false, label: tc.leadsText, visible: true },
      {
        id: 'contactName',
        numeric: false,
        label: tc.contactName,
        visible: true,
      },
      { id: 'city', numeric: false, label: tc.city, visible: true },
    ];
  },
  getLeadsWidgetRows: (rows) => {
    const columns = tableHelper.getLeadsWidgetColumns();
    return rows.map((row) => {
      const obj = {};
      columns.forEach((column, i) => {
        if (column.id === 'received') {
          let date = row.createdAt;
          obj[column.id] = moment(date).fromNow();
        } else {
          obj[column.id] = row[column.id];
        }
      });

      return obj;
    });
  },
  // getListColumns: (type) => {
  //   const tc = store?.getState()?.user?.info?.lang
  //     ? store.getState().user.info.lang === "en"
  //       ? text.english
  //       : text.swedish
  //     : text.swedish;
  //
  //   if (type === "prospects") {
  //     return [
  //       {
  //         id: "fullName",
  //         numeric: false,
  //         label: tc.vehicleUser,
  //         hideSort: true,
  //         visible: true,
  //       }, // Backend does not handle sort on this value, should fix that.
  //       {
  //         id: "orgnr",
  //         numeric: false,
  //         label: tc.orgNumberShort,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "address",
  //         numeric: false,
  //         label: tc.address,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "zipMuncipality",
  //         numeric: false,
  //         label: tc.zipMuncipality,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "numberOfCars",
  //         numeric: true,
  //         label: tc.amountVehicles,
  //         visible: true,
  //       },
  //       {
  //         id: "numberOfMatchingCars",
  //         numeric: true,
  //         label: tc.amountMatchingVehicles,
  //         hideSort: true,
  //         visible: true,
  //       },
  //       {
  //         id: "activity",
  //         numeric: false,
  //         label: tc.activity,
  //         hideSort: true,
  //         visible: true,
  //       },
  //     ];
  //   } else if (type === "cars") {
  //     return [
  //       { id: "brand", numeric: false, label: tc.brand, visible: true },
  //       { id: "ts_model", numeric: false, label: tc.model, visible: true },
  //       {
  //         id: "reg_number",
  //         numeric: false,
  //         label: tc.regNumber,
  //         visible: true,
  //       },
  //       {
  //         id: "possession",
  //         numeric: true,
  //         label: tc.acquiredAges,
  //         visible: true,
  //       },
  //       {
  //         id: "fullName",
  //         numeric: false,
  //         label: tc.vehicleUser,
  //         hideSort: true,
  //         visible: true,
  //       }, // Backend does not handle sort on this value, should fix that.
  //       // { id: "activity", numeric: false, label: tc.activity, hideSort: true, visible: true },
  //     ];
  //   }
  // },
  // getListRows: (rows, type, listId = "", onClick) => {
  //   if (type === "prospects") {
  //     // Prospects table.
  //     const columns = tableHelper.getListColumns(type);
  //
  //     return rows.map((row, i) => {
  //       const obj = {};
  //       columns.forEach((column) => {
  //         if (column.id === "activity") {
  //           obj[column.id] = getActivityPopup(row.activity_and_list, listId);
  //         } else if (column.id === "orgnr") {
  //           obj[column.id] = companyHelper.isValidOrgNr(row[column.id])
  //             ? row[column.id]
  //             : "";
  //         } else if (column.id === "zipMuncipality") {
  //           row[column.id] = row[column.id].toLowerCase();
  //           obj[column.id] =
  //             row[column.id].charAt(0).toUpperCase() + row[column.id].slice(1);
  //         } else {
  //           obj[column.id] = row[column.id];
  //         }
  //
  //         // Sometimes we want onClick instead of navigational link for row click, for example when opening up a preview window.
  //         if (typeof onClick === "function") {
  //           obj.onClick = onClick;
  //         } else {
  //           obj.url = companyHelper.isValidOrgNr(row.user_id)
  //             ? "/foretag/" + row.user_id
  //             : "/person/" + row.user_id;
  //         }
  //
  //         obj.id = row.user_id;
  //       });
  //
  //       return obj;
  //     });
  //   } else if (type === "cars") {
  //     // Vehicles table.
  //     const columns = tableHelper.getListColumns(type);
  //
  //     return rows.map((row, i) => {
  //       const obj = {};
  //       columns.forEach((column) => {
  //         obj[column.id] = row[column.id];
  //
  //         // Sometimes we want onClick instead of navigational link for row click, for example when opening up a preview window.
  //         if (typeof onClick === "function") {
  //           obj.onClick = onClick;
  //         } else {
  //           obj.url = "/fordon/" + row.reg_number;
  //         }
  //
  //         obj.id = row.reg_number;
  //       });
  //
  //       return obj;
  //     });
  //   }
  // },
  getListsColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      {
        id: 'name',
        editable: true,
        numeric: false,
        label: tc.name,
        visible: true,
      },
      { id: 'total', numeric: true, label: tc.amount, visible: true },
      { id: 'created', numeric: false, label: tc.created, visible: true },
      {
        id: 'criterias',
        numeric: false,
        label: tc.searchCriterias,
        hideSort: true,
        visible: true,
      },
      {
        id: 'complement',
        numeric: false,
        label: tc.complement,
        hideSort: true,
        visible: true,
      },
      {
        id: 'sharedWith',
        numeric: false,
        label: tc.sharedWith,
        hideSort: true,
        visible: true,
      },
      { id: 'creatorName', numeric: false, label: tc.createdBy, visible: true },
      { id: 'dealerName', numeric: false, label: tc.company, visible: true },
    ];
  },
  getListsRows: (rows) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const columns = tableHelper.getListsColumns();
    return rows.map((row, i) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'created') {
          obj[column.id] = moment(
            new Date(row[column.id]),
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD');
        } else if (column.id === 'complement') {
          obj[column.id] = (
            <div className="tableCellIconHolder">
              {row.orderHistory?.name && row.orderHistory?.name?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.waitingForNameOrder}
                >
                  <Icon class="notification pending" val="person" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.name &&
              row.orderHistory?.name?.isAvailable &&
              !row.orderHistory?.name?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.orderedName}
                >
                  <Icon class="notification" val="person" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.name &&
              !row.orderHistory?.name?.isAvailable &&
              !row.orderHistory?.name?.isPending &&
              row.orderHistory?.name?.isDelivered ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.haveOldNameOrder}
                >
                  <Icon class="notification inactive" val="person" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.phone && row.orderHistory?.phone?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.waitingForPhoneOrder}
                >
                  <Icon class="notification pending" val="phone" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.phone &&
              row.orderHistory?.phone?.isAvailable &&
              !row.orderHistory?.phone?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.orderedPhone}
                >
                  <Icon class="notification" val="phone" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.phone &&
              !row.orderHistory?.phone?.isAvailable &&
              !row.orderHistory?.phone?.isPending &&
              row.orderHistory?.phone?.isDelivered ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.haveOldPhoneOrder}
                >
                  <Icon class="notification inactive" val="phone" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.mailings &&
              row.orderHistory?.mailings?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.waitingForMailingsOrder}
                >
                  <Icon class="notification pending" val="mail" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.mailings &&
              !row.orderHistory?.mailings?.isPending &&
              row.orderHistory?.mailings?.isDelivered ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.orderedMailings}
                >
                  <Icon class="notification" val="mail" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.company &&
              row.orderHistory?.company?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.waitingForCompanyOrder}
                >
                  <Icon class="notification pending" val="company" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.waystar &&
              row.orderHistory?.waystar?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={'Väntar på Waystar-beställning'}
                >
                  <Icon class="notification pending" val="alerts" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.waystar &&
              row.orderHistory?.waystar?.isAvailable &&
              !row.orderHistory?.waystar?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={'Finns Waystarflaggning'}
                >
                  <Icon class="notification" val="alerts" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.hubspot?.isAvailable &&
              !row.orderHistory?.hubspot?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.hubspotExportDone}
                >
                  <Icon class="notification" val="hub" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.hubspot?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.hubspotExportWaiting}
                >
                  <Icon class="notification pending" val="alerts" />
                </Tooltip>
              ) : null}

              {row.orderHistory?.company &&
              row.orderHistory?.company?.isAvailable &&
              !row.orderHistory?.company?.isPending ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.orderedCompany}
                >
                  <Icon class="notification" val="company" />
                </Tooltip>
              ) : null}
              {row.orderHistory?.company &&
              !row.orderHistory?.company?.isAvailable &&
              !row.orderHistory?.company?.isPending &&
              row.orderHistory?.company?.isDelivered ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.haveOldCompanyOrder}
                >
                  <Icon class="notification inactive" val="company" />
                </Tooltip>
              ) : null}
              {row.meta &&
              row.meta.subscription_ids &&
              row.meta.subscription_ids.length ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.subscription}
                >
                  <Icon class="notification" val="subscription" />
                </Tooltip>
              ) : null}
            </div>
          );
        } else if (column.id === 'criterias') {
          obj[column.id] = (
            <div className="tableCellIconHolder">
              {row.meta &&
              row.meta.criterias &&
              Object.keys(row.meta.criterias).length ? (
                <Tooltip
                  horizontalDirection="right"
                  tooltipContent={tc.listHaveSearchCriterias}
                >
                  <Icon class="notification" val="check" />
                </Tooltip>
              ) : null}
            </div>
          );
        } else if (column.id === 'sharedWith') {
          if (row.userNames && row.userNames.length) {
            let string = '';
            row.userNames.forEach((num, i) => {
              if (i === row.userNames.length - 1) {
                string += num;
              } else {
                string += `${num}, `;
              }
            });

            obj[column.id] = (
              <div className="tableCellIconHolder">
                <Tooltip horizontalDirection="left" tooltipContent={string}>
                  <Icon class="notification" val="team" />
                </Tooltip>
              </div>
            );
          } else {
            obj[column.id] = null;
          }
        } else {
          obj[column.id] = row[column.id];
        }

        obj.id = row.id;
        obj.url = '/lista/' + row.id;
      });

      return obj;
    });
  },
  getListsOrdersColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'name', numeric: false, label: tc.listName, visible: true },
      { id: 'total', numeric: true, label: tc.amount, visible: true },
      { id: 'created', numeric: false, label: tc.created, visible: true },
      { id: 'creatorName', numeric: false, label: tc.createdBy, visible: true },
      { id: 'nameOrder', numeric: false, label: tc.name, visible: true },
      { id: 'phoneOrder', numeric: false, label: tc.phone, visible: true },
      {
        id: 'mailingsOrder',
        numeric: false,
        label: tc.mailings,
        visible: true,
      },
      {
        id: 'managementOrder',
        numeric: false,
        label: tc.management,
        visible: true,
      },
      { id: 'hubspotOrder', numeric: false, label: tc.hubspot, visible: true },
    ];
  },
  getListsOrdersRows: (rows) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;
    const columns = tableHelper.getListsOrdersColumns();

    return rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'created') {
          obj[column.id] = moment(
            new Date(row[column.id]),
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD');
        } else if (column.id === 'nameOrder') {
          const status = row.orderHistory?.name?.isAvailable ? (
            <Tooltip horizontalDirection="left" tooltipContent={tc.orderedName}>
              <Icon class="available" val="available" />
            </Tooltip>
          ) : row.orderHistory?.name?.isPending ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderIsPending}
            >
              <Icon class="pending" val="pending" />
            </Tooltip>
          ) : (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.notAvailable}
            >
              <Icon class="notAvailable" val="notAvailable" />
            </Tooltip>
          );

          obj[column.id] = <div className="tableCellIconHolder">{status}</div>;
        } else if (column.id === 'phoneOrder') {
          const status = row.orderHistory?.phone?.isAvailable ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderedPhone}
            >
              <Icon class="available" val="available" />
            </Tooltip>
          ) : row.orderHistory?.phone?.isPending ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderIsPending}
            >
              <Icon class="pending" val="pending" />
            </Tooltip>
          ) : (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.notAvailable}
            >
              <Icon class="notAvailable" val="notAvailable" />
            </Tooltip>
          );

          obj[column.id] = <div className="tableCellIconHolder">{status}</div>;
        } else if (column.id === 'mailingsOrder') {
          const status = row.orderHistory?.mailings?.isAvailable ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderedMailings}
            >
              <Icon class="available" val="available" />
            </Tooltip>
          ) : row.orderHistory?.mailings?.isPending ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderIsPending}
            >
              <Icon class="pending" val="pending" />
            </Tooltip>
          ) : (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.notAvailable}
            >
              <Icon class="notAvailable" val="notAvailable" />
            </Tooltip>
          );

          obj[column.id] = <div className="tableCellIconHolder">{status}</div>;
        } else if (column.id === 'managementOrder') {
          const status = row.orderHistory?.company?.isAvailable ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderedCompany}
            >
              <Icon class="available" val="available" />
            </Tooltip>
          ) : row.orderHistory?.company?.isPending ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderIsPending}
            >
              <Icon class="pending" val="pending" />
            </Tooltip>
          ) : (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.notAvailable}
            >
              <Icon class="notAvailable" val="notAvailable" />
            </Tooltip>
          );

          obj[column.id] = <div className="tableCellIconHolder">{status}</div>;
        } else if (column.id === 'hubspotOrder') {
          const status = row.orderHistory?.hubspot?.isAvailable ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.hubspotExportedTo}
            >
              <Icon class="available" val="available" />
            </Tooltip>
          ) : row.orderHistory?.hubspot?.isPending ? (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.orderIsPending}
            >
              <Icon class="pending" val="pending" />
            </Tooltip>
          ) : (
            <Tooltip
              horizontalDirection="left"
              tooltipContent={tc.notAvailable}
            >
              <Icon class="notAvailable" val="notAvailable" />
            </Tooltip>
          );

          obj[column.id] = <div className="tableCellIconHolder">{status}</div>;
        } else {
          obj[column.id] = row[column.id];
        }

        obj.onClick = () => {
          setListToOrder(row);
          setActiveOrderStage('listOrder');
        };
        obj.id = row.id;
      });

      return obj;
    });
  },
  getListsCompactColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      {
        id: 'name',
        editable: true,
        numeric: false,
        label: tc.name,
        visible: true,
      },
      { id: 'total', numeric: true, label: tc.amount, visible: true },
      { id: 'created', numeric: false, label: tc.created, visible: true },
      { id: 'creatorName', numeric: false, label: tc.createdBy, visible: true },
      // { id: "dealerName", numeric: false, label: tc.company, visible: true },
    ];
  },
  getListsCompactRows: (rows) => {
    const columns = tableHelper.getListsCompactColumns();
    return rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'created') {
          obj[column.id] = moment(
            new Date(row[column.id]),
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD');
        } else {
          obj[column.id] = row[column.id];
        }

        obj.id = row.id;
        obj.url = '/lista/' + row.id;
      });

      return obj;
    });
  },
  getListsSubscriptionsColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'prefix_name', numeric: false, label: tc.name, visible: true },
      { id: 'type', numeric: false, label: tc.subscriptionType, visible: true },
      {
        id: 'sharedWith',
        numeric: false,
        label: tc.sharedWith,
        hideSort: true,
        visible: true,
      },
    ];
  },
  getListsSubscriptionsRows: (rows) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const columns = tableHelper.getListsSubscriptionsColumns();
    return rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'type') {
          let types = [];

          if (row.subscription_flag & listHelper.subscriptionFlags.name) {
            types.push(tc.name);
          }
          if (row.subscription_flag & listHelper.subscriptionFlags.phone) {
            types.push(tc.phoneNumbers);
          }
          if (row.subscription_flag & listHelper.subscriptionFlags.mailings) {
            types.push(tc.mailings);
          }
          if (row.subscription_flag & listHelper.subscriptionFlags.hubspot) {
            types.push(tc.hubspot);
          }

          if (
            row.subscription_flag &
            listHelper.subscriptionFlags.excludeProspects
          ) {
            types.push(tc.excludeProspects);
          }

          if (row.options && row.options.interval) {
            types.push(tc[row.options.interval]);
          }

          let string = '';
          types.forEach((type, i) => {
            if (i === types.length - 1) {
              string += type;
            } else {
              string += `${type}, `;
            }
          });

          obj[column.id] = string;
        } else if (column.id === 'sharedWith') {
          if (row.userNames && row.userNames.length) {
            let string = '';
            row.userNames.forEach((num, i) => {
              if (i === row.userNames.length - 1) {
                string += num;
              } else {
                string += `${num}, `;
              }
            });

            obj[column.id] = (
              <div className="tableCellIconHolder">
                <Tooltip horizontalDirection="left" tooltipContent={string}>
                  <Icon class="notification" val="team" />
                </Tooltip>
              </div>
            );
          } else {
            obj[column.id] = null;
          }
        } else {
          obj[column.id] = row[column.id];
        }

        obj.id = row.id;
      });

      return obj;
    });
  },
  generateProspectColumn: (id = '', payload = {}, options = {}) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;
    const defaultOptions = {
      numeric: false,
      matchingLabel: '',
      hideSort: payload.hasOwnProperty('hideAllSort') ? true : false,
      defVisibility: true,
    };
    const optionsToUse = { ...defaultOptions, ...options };
    const { numeric, hideSort, matchingLabel, defVisibility, visible } =
      optionsToUse;
    return {
      id,
      numeric,
      visible:
        visible !== undefined
          ? visible
          : Array.isArray(payload.activeColumns)
          ? payload.activeColumns.includes(id)
          : defVisibility,
      label: matchingLabel ? tc[matchingLabel] : tc[id] ?? id,
      hideSort,
    };
  },
  /**
   * Return generic columns for prospects (persons/companies).
   * Use in all tables where we display prospect rows.
   *
   * Note that if we remove "hideSort: true" for a column we need to make sure backend handles sorting on that column
   * for all places that uses these columns (search results, prospects results... etc.).
   *
   * @param payload.activeColumns - array (optional) - Array with strings for which columns should be active.
   * @param payload.numberOfMatchingCars - bool (optional)
   * @example
   * // Default values
   * {
   * numeric: false,
   * matchingLabel: tc[id],
   * hideSort: payload.hasOwnProperty("hideAllSort"),
   * visible: payload.activeColumns.includes(id),
   * }
   * @description For every column, pass in an ID and optionally override other default options.
   * If passing in a `visible` property, that will be used to determine whether the column is shown.
   * If instead passing a `defVisibility` property, that will be used as a fallback if `payload.activeColumns` does not include the specified ID.
   * If the tc property for the label is different than the specified ID, you can pass in `matchingLabel` that will be used instead of the ID.
   */
  getProspectColumns: (payload) => {
    const generate = (item) => {
      if (!item) return null;
      return tableHelper.generateProspectColumn(item.id, payload, item);
    };
    const data = [
      { id: 'fullName', matchingLabel: 'vehicleUser', hideSort: true },
      { id: 'orgnr', matchingLabel: 'orgNumberShort', hideSort: true },
      { id: 'address', hideSort: true },
      { id: 'zipMuncipality', hideSort: true },
      { id: 'numberOfCars', numeric: true, matchingLabel: 'amountVehicles' },
      { id: 'responsible', matchingLabel: 'responsibleParty', hideSort: true },
      payload?.numberOfMatchingCars
        ? {
            id: 'numberOfMatchingCars',
            numeric: true,
            hideSort: true,
            visible: true,
            matchingLabel: 'amountMatchingVehicles',
          }
        : null,
      ...['numberOfCarsPB', 'numberOfCarsLB', 'numberOfCarsTLB'].map((id) => ({
        id,
        numeric: true,
        matchingLabel: `${id}Compact`,
        defVisibility: true,
      })),
      ...[
        'numberOfCarsATR',
        'numberOfCarsATV',
        'numberOfCarsBU',
        'numberOfCarsHB',
        'numberOfCarsHV',
        'numberOfCarsMC',
        'numberOfCarsMP',
        'numberOfCarsMR',
        'numberOfCarsSS',
        'numberOfCarsSV',
        'numberOfCarsTR',
      ].map((id) => ({
        id,
        numeric: true,
        matchingLabel: `${id}Compact`,
      })),
      { id: 'activity', hideSort: true },
    ];

    return data.map(generate).filter((num) => num);
  },
  /**
   * Return generic rows for prospects (persons/companies).
   * Use in all tables where we display prospect rows.
   *
   * @param payload.listId - string (optional) - When listId provided, don't display activity for that list.
   * @param payload.onClick - func (optional) - Sometimes we want onClick instead of navigational link for row click, for example when opening up a preview window.
   * @param payload.numberOfMatchingCars - bool (optional)
   * @param payload.rows - array
   */
  getProspectRows: (payload) => {
    const columns = tableHelper.getProspectColumns({
      numberOfMatchingCars: payload.numberOfMatchingCars,
    });
    return payload.rows.map((row, i) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'activity') {
          // Either row.activity_and_list or row.activityMapped.
          obj[column.id] = (
            <ActivityPopupProspect
              listId={row.listId}
              prospectId={row.user_id}
            />
          );
        } else if (column.id === 'orgnr') {
          obj[column.id] = companyHelper.isValidOrgNr(row[column.id])
            ? row[column.id]
            : '';
        } else if (column.id === 'zipMuncipality') {
          row[column.id] = row[column.id].toLowerCase();
          obj[column.id] =
            row[column.id].charAt(0).toUpperCase() + row[column.id].slice(1);
        } else {
          obj[column.id] = row[column.id];
        }

        if (typeof payload.onClick === 'function') {
          obj.onClick = payload.onClick;
        } else {
          obj.url = companyHelper.isValidOrgNr(row.user_id)
            ? '/foretag/' + row.user_id
            : '/person/' + row.user_id;
        }

        obj.id = row.user_id;
      });

      return obj;
    });
  },
  getSearchResultsColumns: (type) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    if (type === 'prospects') {
      return [
        {
          id: 'fullName',
          numeric: false,
          label: tc.vehicleUser,
          hideSort: true,
          visible: true,
        },
        {
          id: 'orgnr',
          numeric: false,
          label: tc.orgNumberShort,
          hideSort: true,
          visible: true,
        },
        {
          id: 'address',
          numeric: false,
          label: tc.address,
          hideSort: true,
          visible: true,
        },
        { id: 'city', numeric: false, label: tc.zipMuncipality, visible: true },
        {
          id: 'numberOfCars',
          numeric: true,
          label: tc.amountVehicles,
          visible: true,
        },
        {
          id: 'abv_hgrupp',
          numeric: true,
          label: tc.lineOfBusiness,
          visible: true,
        },
        {
          id: 'activity',
          numeric: false,
          label: tc.activity,
          hideSort: true,
          visible: true,
        },
      ];
    } else if (type === 'cars') {
      return [
        { id: 'brand', numeric: false, label: tc.brand, visible: true },
        { id: 'ts_model', numeric: true, label: tc.model, visible: true },
        {
          id: 'reg_number',
          numeric: false,
          label: tc.regNumber,
          visible: true,
        },
        {
          id: 'possession',
          numeric: true,
          label: tc.acquiredAges,
          visible: true,
        },
        {
          id: 'fullName',
          numeric: false,
          label: tc.vehicleUser,
          hideSort: true,
          visible: true,
        },
        // { id: "activity", numeric: false, label: tc.activity, hideSort: true, visible: true },
      ];
    }
  },
  getSearchResultsRows: (rows, type) => {
    if (type === 'prospects') {
      // Prospects table.
      const columns = tableHelper.getSearchResultsColumns(type);

      return rows.map((row, i) => {
        const obj = {};
        columns.forEach((column) => {
          if (column.id === 'activity') {
            obj[column.id] = (
              <ActivityPopupProspect
                listId={row.listId}
                prospectId={row.user_id}
              />
            );
          } else if (column.id === 'orgnr') {
            obj[column.id] = companyHelper.isValidOrgNr(row[column.id])
              ? row[column.id]
              : '';
          } else if (column.id === 'zipMuncipality') {
            row[column.id] = row[column.id].toLowerCase();
            obj[column.id] =
              row[column.id].charAt(0).toUpperCase() + row[column.id].slice(1);
          } else {
            obj[column.id] = row[column.id];
          }

          obj.id = row.user_id;
          obj.url = companyHelper.isValidOrgNr(row.user_id)
            ? '/foretag/' + row.user_id
            : '/person/' + row.user_id;
        });

        return obj;
      });
    } else if (type === 'cars') {
      // Vehicles table.
      const columns = tableHelper.getSearchResultsColumns(type);

      return rows.map((row, i) => {
        const obj = {};
        columns.forEach((column) => {
          // if (column.id === "activity") {
          //   obj[column.id] = getActivityPopup(row.activityMapped, null);
          // } else {
          obj[column.id] = row[column.id];
          // }

          obj.id = row.reg_number;
          obj.url = '/fordon/' + row.reg_number;
        });

        return obj;
      });
    }
  },
  getSupertempWidgetColumns: () => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    return [
      { id: 'name', numeric: false, label: tc.vehicleUser, visible: true },
      { id: 'brand', numeric: false, label: tc.brand, visible: true },
      { id: 'price', numeric: true, label: tc.price, visible: true },
      { id: 'published', numeric: false, label: tc.published, visible: true },
      {
        id: 'number_of_owners',
        numeric: false,
        label: tc.numberOfOwners,
        visible: true,
      },
      {
        id: 'reg_number',
        numeric: false,
        label: tc.regNumberShort,
        visible: true,
      },
    ];
  },
  getSupertempWidgetRows: (rows) => {
    const columns = tableHelper.getSupertempWidgetColumns();
    return rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'published') {
          obj[column.id] = row.supertemp?.published
            ? moment(new Date(row.supertemp.published), 'DD/MM/YYYY').fromNow()
            : '';
        } else if (column.id === 'price') {
          obj[column.id] = row.supertemp?.price ? row.supertemp?.price : '';
        } else if (column.id === 'name') {
          obj[column.id] = row.name.fullName;
        } else {
          obj[column.id] = row[column.id];
        }

        obj.url = `/fordon/${row.reg_number}`;
      });

      return obj;
    });
  },
  generateVehicleColumn: (id = '', payload = {}, options = {}) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;
    const defaultOptions = {
      numeric: false,
      matchingLabel: '',
      hideSort: payload.hasOwnProperty('hideAllSort') ? true : false,
      defVisibility: true,
    };
    const optionsToUse = { ...defaultOptions, ...options };
    const { numeric, hideSort, matchingLabel, defVisibility, searchable } =
      optionsToUse;
    return {
      id,
      numeric,
      visible: Array.isArray(payload.activeColumns)
        ? payload.activeColumns.includes(id)
        : defVisibility,
      label: matchingLabel ? tc[matchingLabel] : tc[id] ?? id,
      hideSort,
      searchable,
    };
  },
  /**
   * Return generic columns for vehicles.
   * Use in all tables where we display vehicle rows.
   *
   * Note that if we remove "hideSort: true" for a column we need to make sure backend handles sorting on that column
   * for all places that uses these columns (search results, prospects results... etc.).
   *
   * @param payload.activeColumns - array (optional) - Array with strings for which columns should be active.
   * @param payload.hideAllSort - bool (optional) - If this is true, we hide sorting for all columns.
   *                        Applicable when columns is used in a place where backend doesn't handle sorting.
   *                        For example, in FleetWidget backend handles sorting on a lot of columns, but in SearchResults backend cannot sort on any column.
   *                        And we use getVehicleColumns in both places.
   * @param payload.historic - bool (optional)
   * @param payload.koncern - bool (optional)
   */
  getVehicleColumns: (payload) => {
    const generate = (item) => {
      if (!item) return null;
      return tableHelper.generateVehicleColumn(item.id, payload, item);
    };
    const baseData = [
      { id: 'brand', searchable: true },
      { id: 'model', searchable: true },
      { id: 'fullName', matchingLabel: 'vehicleUser', hideSort: 'true' },
      { id: 'type', searchable: true },
      { id: 'reg_number', matchingLabel: 'regNumberShort', searchable: true },
      !!payload?.koncern
        ? { id: 'orgNr', numeric: true, matchingLabel: 'orgNumberShort' }
        : null,
      payload?.prospectPreview === false
        ? { id: 'waystarFlag', matchingLabel: 'waystarFlag' }
        : null,
      { id: 'new', matchingLabel: 'bought', hideSort: true },
      { id: 'seller_name', matchingLabel: 'sellerName', hideSort: true },
      { id: 'real_model', matchingLabel: 'real_model', hideSort: true },
      { id: 'real_trade_name', matchingLabel: 'realTradeName', hideSort: true },
      { id: 'model_series', matchingLabel: 'modelSeries', hideSort: true },
      {
        id: 'engine_strength_hk',
        matchingLabel: 'engineStrengthHk',
        hideSort: true,
      },
      {
        id: 'max_extra_weight',
        matchingLabel: 'maxExtraWeight',
        hideSort: true,
      },
      !payload?.isForCompanyFleet
        ? {
            id: 'responsible',
            matchingLabel: 'responsibleParty',
            hideSort: true,
          }
        : null,
    ];

    const historicData = [
      { id: 'real_date_sold', matchingLabel: 'sold' },
      { id: 'holdingPeriod' },
      { id: 'acquiredAges' },
    ];

    let nonHistoricData = [
      { id: 'possession', matchingLabel: 'acquiredAges' },
      {
        id: 'date_registered',
        matchingLabel: 'regDate',
        defVisibility: false,
      },
      { id: 'financed_by', matchingLabel: 'financed', hideSort: true },
      { id: 'leasing_owner', matchingLabel: 'leasingOwner', hideSort: true },
      { id: 'car_status', matchingLabel: 'inService' },
      { id: 'fuel' },
      {
        id: 'climate_classification',
        defVisibility: false,
        matchingLabel: 'climateClassification',
        hideSort: true,
      },
      { id: 'submission', defVisibility: false, hideSort: true },
      { id: 'dack_fram', defVisibility: false },
      { id: 'dack_bak', defVisibility: false },
      { id: 'falg_fram', defVisibility: false },
      { id: 'falg_bak', defVisibility: false },
      { id: 'shaft_amount', matchingLabel: 'shaftAmount', hideSort: true },
      { id: 'cylinder_volume', defVisibility: false },
    ];

    let allColumns = baseData
      .concat(payload?.historic ? historicData : nonHistoricData)
      .map(generate);

    return allColumns.filter((num) => num);
  },
  /**
   * Return generic rows for vehicles.
   * Use in all tables where we display vehicle rows.
   *
   * @param payload.onClick - When row click should be something else than a navigation to vehicle page.
   * @param payload.rows - array
   * @param payload.historic - bool
   * @param payload.koncern - bool
   */
  getVehicleRows: (payload) => {
    if (payload?.rows?.length) {
      return payload.historic
        ? tableHelper.getVehicleRowsHistoric({
            rows: payload.rows,
            koncern: payload.koncern,
            onClick: payload.onClick,
          })
        : tableHelper.getVehicleRowsCurrent({
            rows: payload.rows,
            koncern: payload.koncern,
            onClick: payload.onClick,
          });
    } else {
      return [];
    }
  },
  getVehicleRowsCurrent: (payload) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const columns = tableHelper.getVehicleColumns({
      koncern: !!payload.koncern,
      prospectPreview: false,
    });

    return payload.rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'date_registered') {
          obj[column.id] = row[column.id]
            ? row[column.id].toString().substring(0, 4) +
              '-' +
              row[column.id].toString().substring(4, 6) +
              '-' +
              row[column.id].toString().substring(6, 8)
            : tc.dataMissing;
        } else if (column.id === 'new') {
          obj[column.id] = !(row[column.id] & 2) ? tc.used : tc.new;
        } else if (column.id === 'possession') {
          obj[column.id] = `${row[column.id]} ${tc.months.toLowerCase()}`;
        } else if (column.id === 'type') {
          obj[column.id] = vehicleHelper.getVehicleTypeFromAbbr(row[column.id]);
        } else if (column.id === 'climate_classification') {
          obj[column.id] = textHelper.formatText(row[column.id]);
        } else if (column.id === 'leasing_owner') {
          obj[column.id] = row[column.id] ?? tc.noLeasingOwner;
        } else if (column.id === 'waystarFlag') {
          const flag = row[column.id];
          const translation = {
            0: 'green',
            1: 'yellow',
            2: 'yellow',
            3: 'red',
          };
          if (!translation[flag]) obj[column.id] = tc.dataMissing;
          else {
            const color = translation[flag];

            obj[column.id] = (
              <div style={{ width: '100%', height: '100%' }}>
                <TooltipComponent
                  tooltipContent={
                    tc[
                      'waystarFlag' +
                        color.slice(0, 1).toUpperCase() +
                        color.slice(1).toLowerCase()
                    ]
                  }
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      val="circle"
                      color={color}
                      class={`waystarFlag ${color}`}
                    />
                  </div>
                </TooltipComponent>
              </div>
            );
          }
        } else if (column.id === 'responsible') {
          if (!row[column.id]) obj[column.id] = tc.dataMissing;
          else obj[column.id] = row[column.id];
        } else {
          obj[column.id] = row[column.id];
        }
      });

      if (typeof payload.onClick === 'function') {
        obj.onClick = payload.onClick;
      } else {
        obj.url = obj.url = '/fordon/' + row.reg_number;
      }

      obj.id = row.reg_number;

      return obj;
    });
  },
  getVehicleRowsHistoric: (payload) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store.getState().user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const columns = tableHelper.getVehicleColumns({
      historic: true,
      koncern: !!payload.koncern,
    });

    return payload.rows.map((row) => {
      const obj = {};
      columns.forEach((column) => {
        if (column.id === 'holdingPeriod') {
          obj[column.id] = row.date_acquired + ' - ' + row.date_sold;
        } else if (column.id === 'acquiredAges') {
          obj[column.id] =
            row[column.id] && row[column.id].months
              ? row[column.id].months + ' ' + tc.months.toLowerCase()
              : null;
        } else if (column.id === 'new') {
          obj[column.id] = !(row[column.id] & 2) ? tc.used : tc.new;
        } else if (column.id === 'type') {
          obj[column.id] = vehicleHelper.getVehicleTypeFromAbbr(row[column.id]);
        } else {
          obj[column.id] = row[column.id];
        }
      });

      if (typeof payload.onClick === 'function') {
        obj.onClick = payload.onClick;
      } else {
        obj.url = obj.url = '/fordon/' + row.reg_number;
      }

      return obj;
    });
  },
  stableSort: (array, comparator, query) => {
    // First filter on search query.
    if (query && query.length) {
      array = array.filter((row) => {
        let hit = false;
        for (const prop in row) {
          if (
            typeof row[prop] === 'string' &&
            row[prop].toLowerCase().includes(query.toLowerCase())
          ) {
            hit = true;
          }
        }

        return hit;
      });
    }

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  },
};

// /**
//  * Private helper function.
//  */
// const getFleetRowsCurrent = (rows, koncern) => {
//   const tc = store?.getState()?.user?.info?.lang
//     ? store.getState().user.info.lang === "en"
//       ? text.english
//       : text.swedish
//     : text.swedish;
//
//   const columns = tableHelper.getFleetColumns(false, koncern);
//
//   return rows.map((row) => {
//     const obj = {};
//     columns.forEach((column) => {
//       if (column.id === "date_registered") {
//         obj[column.id] = row[column.id]
//           ? row[column.id].toString().substring(0, 4) +
//             "-" +
//             row[column.id].toString().substring(4, 6) +
//             "-" +
//             row[column.id].toString().substring(6, 8)
//           : tc.dataMissing;
//       } else if (column.id === "new") {
//         obj[column.id] = !(row[column.id] & 2) ? tc.used : tc.new;
//       } else if (column.id === "possession") {
//         obj[column.id] = `${row[column.id]} ${tc.months.toLowerCase()}`;
//       } else if (column.id === "type") {
//         obj[column.id] = vehicleHelper.getVehicleTypeFromAbbr(row[column.id]);
//       } else if (column.id === "climate_classification") {
//         obj[column.id] = textHelper.formatText(row[column.id]);
//       } else {
//         obj[column.id] = row[column.id];
//       }
//     });
//
//     obj.url = "/fordon/" + row.reg_number;
//
//     return obj;
//   });
// };

// /**
//  * Private helper function.
//  */
// const getFleetRowsHistoric = (rows, koncern) => {
//   const tc = store?.getState()?.user?.info?.lang
//     ? store.getState().user.info.lang === "en"
//       ? text.english
//       : text.swedish
//     : text.swedish;
//
//   const columns = tableHelper.getFleetColumns(true, koncern);
//
//   return rows.map((row) => {
//     const obj = {};
//     columns.forEach((column) => {
//       if (column.id === "holdingPeriod") {
//         obj[column.id] = row.date_acquired + " - " + row.date_sold;
//       } else if (column.id === "acquiredAges") {
//         obj[column.id] =
//           row[column.id] && row[column.id].months
//             ? row[column.id].months + " " + tc.months.toLowerCase()
//             : null;
//       } else if (column.id === "new") {
//         obj[column.id] = !(row[column.id] & 2) ? tc.used : tc.new;
//       } else if (column.id === "type") {
//         obj[column.id] = vehicleHelper.getVehicleTypeFromAbbr(row[column.id]);
//       } else {
//         obj[column.id] = row[column.id];
//       }
//     });
//
//     obj.url = "/fordon/" + row.reg_number;
//
//     return obj;
//   });
// };

const getActivityPopup = (activityArr, listId) => {
  const tc = store?.getState()?.user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  let arr = [];

  if (Array.isArray(activityArr)) {
    arr = arr.concat(
      activityArr
        .map((activity) => {
          if (listId && activity.list_id === listId) {
            // For list rows.
            return null;
          } else {
            return {
              name: activity.taken_by,
              list: activity.list,
              taken: !!activity.taken_by,
            };
          }
        })
        .filter((num) => num)
    );
  }

  if (!arr.length) {
    return '';
  }

  const own = [];
  const others = [];
  arr.forEach((num) => {
    if (!num.taken && num.list && !own.find((x) => x.list === num.list)) {
      own.push(num);
    } else if (
      num.taken &&
      num.name &&
      !others.find((x) => x.name === num.name)
    ) {
      others.push(num);
    }
  });

  let result = (
    <div className="popupHolderWrapper__popupHolder__popupContent__content__section">
      {own.length ? (
        <>
          {own.map((num) => {
            return (
              <div
                key={num.list}
                className="popupHolderWrapper__popupHolder__popupContent__content__section__row"
              >
                <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__left">
                  <IconStyled class="info" iconVal="list" />
                </div>
                <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__right">
                  <p className="label">{tc.prospectExistsInOwnList}</p>
                  <p>{num.list}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
      {others.length ? (
        <>
          {others.map((num) => {
            return (
              <div
                key={num.name}
                className="popupHolderWrapper__popupHolder__popupContent__content__section__row"
              >
                <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__left">
                  <IconStyled class="info" iconVal="user" />
                </div>
                <div className="popupHolderWrapper__popupHolder__popupContent__content__section__row__right">
                  <p className="label">{tc.prospectIsWorkedByColleague}</p>
                  <p>{num.name}</p>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );

  return (
    <PopupHolder
      popupContent={result}
      popupHeader={<WidgetHeader headline={tc.activity} />}
      popupSize="small"
      visibleContent={
        <div className="tableCellIconHolder">
          <Tooltip
            horizontalDirection="left"
            tooltipContent={tc.clickToShowActivity}
          >
            <Icon val="list" />
          </Tooltip>
        </div>
      }
    />
  );
};
