import { store } from 'store';
import { request, requestBody } from 'helpers';
import { widgetsActionTypes } from './actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { debounce } from 'debounce';
import * as text from 'text-content';
import { widgetHelper } from 'helpers/widget_helper';

/**
 * Create a new widget.
 *
 * @param payload.dashboards - object - Example: {analyse: {active: true, minimized: false, position: 3}}
 * @param payload.data - object - When type === 'monitorList' or 'pipeline'
 * @param payload.name - string
 * @param payload.skipGetWidgets - bool (optional) - If we do not want to get widgets from backend when finished.
 * @param payload.type - string - 'activities' | 'leads' |  'monitorList' | 'notifications' | 'pipeline'
 */
export const createWidget = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload?.name ||
      !payload?.type ||
      typeof payload.dashboards !== 'object'
    ) {
      return console.error('Missing params in createWidget', payload);
    }

    if (
      (payload.type === 'pipeline' || payload.type === 'monitorList') &&
      !payload.data
    ) {
      return console.error('Missing param data in createWidget', payload);
    }

    for (const prop in payload.dashboards) {
      if (!payload.dashboards[prop].hasOwnProperty('active')) {
        return console.error(
          'Missing dashboards properties in createWidget',
          payload
        );
      }
      if (!payload.dashboards[prop].hasOwnProperty('minimized')) {
        return console.error(
          'Missing dashboards properties in createWidget',
          payload
        );
      }
      if (!payload.dashboards[prop].hasOwnProperty('position')) {
        return console.error(
          'Missing dashboards properties in createWidget',
          payload
        );
      }
    }

    if (
      (payload.type === 'analyseSales' || payload.type === 'analyseCar') &&
      payload.data?.query?.order
    ) {
      // Convert order to array since mongo doesn't allow dot notation.
      // When getting widgets we convert back to object.
      const order: any = [];

      for (const prop in payload.data.query.order) {
        order.push([prop, payload.data.query.order[prop]]);
      }

      payload.data = {
        ...payload.data,
        query: {
          ...payload.data.query,
          order: order,
        },
      };
    }

    const data = await request({
      data: {
        dashboards: payload.dashboards,
        data: payload.data,
        name: payload.name,
        type: payload.type,
      },
      method: 'post',
      url: '/widgets/',
    });

    if (data instanceof Error) {
      console.error('Could not update items in createWidget', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    if (payload.skipGetWidgets) {
      return true;
    } else {
      showFlashMessage(tc.widgetHasBeenCreated, 'success');
      return await getWidgets();
    }
  } catch (err) {
    return console.error('Error in createWidget', err);
  }
};

/**
 * Get settings/widgets for dashboard.
 */
export const getWidgets = async () => {
  try {
    let data = await request({
      method: 'get',
      url: '/widgets/',
    });

    if (!data || data instanceof Error) {
      return console.error('Could not get data in getWidgets', data);
    }

    if (data?.widgets?.length) {
      data.widgets = data.widgets
        .map((widget) => {
          if (widget.type === 'analyseSales' || widget.type === 'analyseCar') {
            if (Array.isArray(widget?.data?.query?.order)) {
              // Mongo doesn't allow dot notation used in cubeJS so we transform order object in data query to an array when saving to db.
              // Here we convert order back to original form.
              const order = {};
              widget.data.query.order.map((x) => (order[x[0]] = x[1]));

              return {
                ...widget,
                data: {
                  ...widget.data,
                  query: {
                    ...widget.data.query,
                    order,
                  },
                },
              };
            }
          }

          return widget;
        })
        .filter((widget) => widget.type !== 'monitorList'); // Tar bort supertemp widgets som var problematiska (bevakning av t.ex fordon ute för försäljning, ta bort filtrering om eller när det ska användas igen.)
      // Ta bort igen när vi vill kunna använda bevakningswidgets
    }

    return store.dispatch({
      type: widgetsActionTypes.SET_WIDGETS_DATA,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getWidgets', err);
  }
};

/**
 * Remove widget.
 *
 * @param payload.id
 */
export const removeWidget = async (payload, displayMessage = true) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload?.id ||
      widgetHelper.defaultWidgetTypes.includes(payload.type)
    ) {
      // Dont remove default widgets.
      return console.error('Cannot remove widget in removeWidget', payload);
    }

    const data = await requestBody({
      data: {
        id: payload.id,
      },
      method: 'delete',
      url: '/widgets/',
    });

    if (data instanceof Error) {
      return console.error('Could not remove widget in removeWidget', data);
    }

    if (displayMessage) {
      showFlashMessage(tc.widgetHasBeenRemoved, 'success');
    }

    return await getWidgets();
  } catch (err) {
    return console.error('Error in removeWidget', err);
  }
};

export const fixNewUserWidgets = async (widgets: any[] = []) => {
  const id = widgetHelper.DEFAULT_GRID_VIEW_ID;
  const updatedWidgets = widgets
    .filter((widget) => widgetHelper.widgetTypes.includes(widget.type))
    .map((widget) => {
      return {
        ...widget,
        dashboards: {
          ...widget.dashboards,
          [id]: { active: widget.dashboards?.dashboard?.active ? true : false },
        },
      };
    });

  const result = await request({
    data: {
      widgets: updatedWidgets,
    },
    method: 'put',
    url: '/widgets/',
  });

  await createDefaultGridView(updatedWidgets);
  getWidgets();
};

export const createDefaultGridView = async (widgets) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const id = widgetHelper.DEFAULT_GRID_VIEW_ID;
    const sizes = ['lg', 'md', 'sm', 'xs', 'xxs'];
    const defaultLayouts = sizes.reduce((acc, size) => {
      return {
        ...acc,
        [size]: widgetHelper.generateDefaultLayouts(widgets, id, size),
      };
    }, {});
    // const defaultLayouts = {
    //   lg: widgetHelper.generateDefaultLayouts(widgets, id, "lg"),
    //   md: widgetHelper.generateDefaultLayouts(widgets, id, "md"),
    // };
    const defaultView = {
      id: id,
      name: tc.defaultView,
      layouts: defaultLayouts,
      active: true,
    };

    const result = await updateGridLayouts(defaultLayouts, id);
  } catch (e) {
    console.error('Error in createDefaultGridView(): ', e);
  }
};

export const toggleGridWidget = async (widgetToToggle, configuration) => {
  try {
    function _bottom(layout) {
      let max = 0,
        bottomY;
      for (let i = 0, len = layout.length; i < len; i++) {
        bottomY = layout[i].y + layout[i].h;
        if (bottomY > max) max = bottomY;
      }
      return max;
    }

    function _stripWidgetFromLayouts(layoutsObject, widgetToStrip) {
      let layoutBreakpoints = Object.entries(layoutsObject);

      return layoutBreakpoints.reduce((acc, breakpoint) => {
        let [size, data] = breakpoint;
        let filteredData = data.filter(
          (widget) => widget.i !== widgetToStrip.id
        );
        return { ...acc, [size]: filteredData };
      }, {});
    }

    function _addWidgetToLayouts(layoutsObject, widgetToAdd) {
      let layoutBreakpoints = Object.entries(layoutsObject || {});
      const limits = widgetHelper.getWidgetLimits(widgetToAdd.type, 'lg');

      if (layoutBreakpoints.length === 0) {
        let fixing = {
          lg: [
            {
              i: widgetToAdd._id,
              x: 0,
              y: 0,
              w: limits.defaultW,
              h: limits.defaultH,
            },
          ],
        };

        return fixing;
      }

      return layoutBreakpoints.reduce((acc, breakpoint) => {
        let [size, data] = breakpoint;
        let newWidgetObject = {
          i: widgetToAdd._id,
          x: 0,
          y: _bottom(data),
          w: limits.defaultW,
          h: limits.defaultH,
        };
        return { ...acc, [size]: [...data, newWidgetObject] };
      }, {});
    }

    let allWidgets = store.getState().widgets.data.widgets;
    let gridViews = store.getState().widgets.data.gridViews;
    let updatedDashboards;

    // let's check if the widget is already explicitly in the grid view for this configuration
    let widgetIndex = allWidgets.findIndex(
      (widget) => widget._id === widgetToToggle._id
    );

    let gridViewIndex = gridViews.findIndex(
      (view) => view.id === configuration
    );

    let widgetObjectAlreadyExistsForThisView =
      allWidgets[widgetIndex].dashboards.hasOwnProperty(configuration);

    if (widgetObjectAlreadyExistsForThisView) {
      let currentStatus =
        allWidgets[widgetIndex].dashboards[configuration].active;

      updatedDashboards = {
        ...allWidgets[widgetIndex].dashboards,
        [configuration]: {
          ...allWidgets[widgetIndex].dashboards[configuration],
          active: !currentStatus,
        },
      };

      if (currentStatus) {
        // it was active, so we need to remove the widget from the layouts in the gridView for this configuration
        gridViews[gridViewIndex].layouts = _stripWidgetFromLayouts(
          gridViews[gridViewIndex].layouts,
          widgetToToggle
        );
      } else {
        gridViews[gridViewIndex].layouts = _addWidgetToLayouts(
          gridViews[gridViewIndex].layouts,
          widgetToToggle
        );
      }
      // ******* check if we are adding a default layouts object when we make a new gridView otherwise we will fail finding gridViewIndex in line 218
    } else {
      updatedDashboards = {
        ...allWidgets[widgetIndex].dashboards,
        [configuration]: { active: true },
      };
      gridViews[gridViewIndex].layouts = _addWidgetToLayouts(
        gridViews[gridViewIndex].layouts,
        widgetToToggle
      );
    }

    allWidgets[widgetIndex].dashboards = updatedDashboards;

    const p1 = requestBody({
      data: {
        layouts: gridViews,
      },
      method: 'put',
      url: '/widgets/layouts/',
    });

    const p2 = request({
      data: {
        widgets: allWidgets,
      },
      method: 'put',
      url: '/widgets/',
    });
    await Promise.all([p1, p2]);
    await getWidgets();
  } catch (err) {
    console.error('Error in toggleGridWidget: ', err);
  }
};

export const updateGridLayouts = async (
  layouts,
  configuration,
  name = null,
  completeObject = false
) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;
  try {

    if (completeObject) {
      if (Array.isArray(layouts)) {
        layouts = layouts.filter((layout) => {
          return (
            layout.id && layout.name !== null && layout.layouts
            // && Object.keys(layout.layouts)?.length > 0
          );
        });
        if (!layouts.some((layout) => layout.active)) {
          layouts = layouts.map((layout) => {
            if (layout.id === widgetHelper.DEFAULT_GRID_VIEW_ID) {
              return { ...layout, active: true };
            }
            return { ...layout };
          });
        }
      }

      store.dispatch({
        type: widgetsActionTypes.SET_LAYOUTS,
        payload: { layouts: layouts },
      });

      const data = await requestBody({
        data: {
          layouts: layouts,
        },
        method: 'put',
        url: '/widgets/layouts/',
      });

      if (data instanceof Error) {
        console.error(
          'Could not update grid layout in updateGridLayouts, completeObject===true',
          data
        );
        showFlashMessage(tc.genericFailMessage, 'fail');
        return false;
      }
      return true;
    }

    var currentData = [];
    if (store.getState().widgets.data.hasOwnProperty('gridViews')) {
      // let's update our state with the new data
      currentData = store.getState().widgets.data.gridViews;

      let index = currentData?.findIndex((view) => view.id === configuration);

      if (index >= 0) {
        currentData[index].layouts = layouts;
      } else {
        // if we don't find the configuration, we add it
        currentData = [];
        currentData.push({
          id: configuration,
          name:
            configuration === widgetHelper.DEFAULT_GRID_VIEW_ID
              ? tc.defaultView
              : name,
          layouts: layouts,
        });
      }
    } else {
      currentData.push({
        id: configuration,
        name: tc.defaultView,
        layouts: layouts,
        active: true,
      });
    }

    store.dispatch({
      type: widgetsActionTypes.SET_LAYOUTS,
      payload: { layouts: currentData },
    });

    const data = await requestBody({
      data: {
        layouts: currentData,
      },
      method: 'put',
      url: '/widgets/layouts/',
    });

    if (data instanceof Error) {
      return console.error(
        'Could not update grid layout in updateGridLayouts',
        data
      );
    }
    return currentData;
  } catch (err) {
    return console.error('Error in updateGridLayouts', err);
  }
};

export const debouncedUpdateGridLayouts = debounce(updateGridLayouts, 800);

export const setupNewGridUser = async (widgets) => {
  // we have to add widgetHelper.DEFAULT_GRID_VIEW_ID objects to the appropriate widgets.dashboards objects
  // let updatedWidgets = widgets.map(widget=>(
  //   {
  //     ...widget,
  //     dashboards: {
  //       ...widget.dashboards,
  //       grid_default: {
  //         active: true,
  //       }
  //     }
  //   }
  //   )
  // );
  // WE ARE updating the dashboards object ( which is already fixed from the backend )
  // So now insted we should make a basic layouts object and save it to the backend!!!!!!!!!!!!!

  // const data = await request({
  //   data: {
  //     widgets: updatedWidgets,
  //   },
  //   method: "put",
  //   url: "/widgets/",
  // });

  if (data instanceof Error) {
    return console.error('Could not update items in setupNewGridUser', data);
  }
  getWidgets();
};
/**
 * Update the items in dashboard.
 *
 * @param payload.widgets
 */
export const updateWidgets = async (payload) => {
  try {
    if (!payload || !payload?.widgets) {
      return console.error('Missing params in updateWidgets');
    }

    const widgets = store.getState().widgets.data.widgets;

    let mappedWidgets = widgets.map((num) => {
      if (
        (num.type === 'analyseSales' || num.type === 'analyseCar') &&
        num.data?.query?.order
      ) {
        // Convert order to array since mongo doesn't allow dot notation.
        // When getting widgets we convert back to object.
        const order: any = [];

        for (const prop in num.data.query.order) {
          order.push([prop, num.data.query.order[prop]]);
        }

        num.data = {
          ...num.data,
          query: {
            ...num.data.query,
            order: order,
          },
        };
      }

      return num;
    });

    mappedWidgets = mappedWidgets.map((num) => {
      // Merge payload with redux state to get all widgets.
      const found = payload.widgets.find((x) => x._id === num._id);
      return found ? found : num;
    });

    const data = await request({
      data: {
        widgets: mappedWidgets,
      },
      method: 'put',
      url: '/widgets/',
    });

    if (data instanceof Error) {
      return console.error('Could not update items in updateWidgets', data);
    }
    // TODO!!!!
    // We should update the state with the data we have instead of pulling it from the dB.
    return await getWidgets();
  } catch (err) {
    return console.error('Error in updateWidgets', err);
  }
};

export const deleteGridView = async (payload: {
  id: string;
  gridViews: any[];
  widgets: any[];
}) => {
  try {
    const { id, gridViews, widgets } = payload;
    if (!id || !gridViews || !widgets) {
      return console.error('Missing params in deleteGridView');
    }
    const filteredViews = gridViews.filter((gridView) => gridView.id !== id); // remove gridView
    // remove the grid view's ID from each widget (keys of widget.dashboards)
    const updatedWidgets = widgets.map((widget) => {
      return {
        ...widget,
        dashboards: Object.fromEntries(
          Object.entries(widget.dashboards).filter(([key, value]) => key !== id)
        ),
      };
    });
    await updateGridLayouts(filteredViews, null, null, true);
    await updateWidgets({ widgets: updatedWidgets });
  } catch (e) {
    return console.error('Error in deleteGridView', e);
  }
};
