import React, { useContext } from 'react';
import { getPagedProspects } from 'store/agile/tasks';
import LanguageContext from 'language-context';
import AgileKanbanBoardCard from './agile_kanban_board_card';
import Board from 'react-trello';
import Icon from 'components/icon';
import Tooltip from 'components/tooltip';

/**
 * Render a kanban board with deals and prospects.
 */
const AgileKanbanBoard = (props) => {
  const {
    addActivity,
    allProspectsReceived,
    columns,
    handleColumnDragEnd,
    handleItemDragEnd,
  } = props;
  const tc = useContext(LanguageContext);

  const _handleItemDragEnd = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    card
  ) => {
    if (
      !sourceLaneId ||
      !targetLaneId ||
      !card ||
      targetLaneId === 'prospects' ||
      targetLaneId === sourceLaneId
    ) {
      return false;
    }

    handleItemDragEnd(card, sourceLaneId, targetLaneId);
  };

  const _handleColumnDragEnd = (removedIndex, addedIndex, payload) => {
    if (
      !Number.isFinite(removedIndex) ||
      !Number.isFinite(addedIndex) ||
      !payload?.id
    ) {
      return false;
    }
    handleColumnDragEnd(removedIndex, addedIndex, payload);
  };

  return (
    <div className="agileKanbanBoardWrapper">
      <div className="agileKanbanBoardWrapper__agileKanbanBoard">
        <div
          className={
            'agileKanbanBoardWrapper__agileKanbanBoard__content ' +
            'columns' +
            columns.filter((num) => num.visible).length
          }
        >
          <Board
            components={{
              Card: AgileKanbanBoardCard,
            }}
            data={{
              lanes: columns
                .map((column) => {
                  if (column.visible) {
                    return {
                      ...column,
                      label: `${
                        column.items.length
                      } ${tc.aPiece.toLowerCase()}`,
                      cards: column.items
                        .map((item, i) => {
                          if (item.hide) {
                            return null;
                          } else {
                            // const listId = item.listId || '';
                            const id = item.prospectId
                              ? String(item.prospectId)
                              : item.id;
                            return {
                              ...item,
                              addActivity: addActivity, // Add function to props.
                              itemType: item.itemType,
                              dealId: item.itemType === 'deal' ? item.id : null,
                              prospectId: item.prospectId || null,
                              id,
                              // id: item.prospectId
                              //   ? item.prospectId.toString() //+ i
                              //   : item.id, // Add id so react-trello can add key property.
                            };
                          }
                        })
                        .filter((item) => item),
                    };
                  } else {
                    return null;
                  }
                })
                .filter((column) => column),
            }}
            draggable={true}
            handleDragEnd={_handleItemDragEnd}
            handleLaneDragEnd={_handleColumnDragEnd}
          />
          {allProspectsReceived ? null : (
            <div className="agileKanbanBoardWrapper__agileKanbanBoard__content__getMore">
              <Tooltip tooltipContent={tc.getMore}>
                <div
                  className="agileKanbanBoardWrapper__agileKanbanBoard__content__getMore__button"
                  onClick={getPagedProspects}
                >
                  <Icon val="load" />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgileKanbanBoard;
