import { store } from 'store';
import { idbHelper, request } from 'helpers';
import { rootActionTypes } from 'store/actions';
import { userActionTypes } from 'store/user/actions';
import { showFlashMessage } from 'store/flash_messages/tasks';
import { getColumnsData } from 'store/agile/tasks';
import { checkProspectLoadedData } from 'store/prospect/tasks';
import { setActivityFilter } from 'store/activity/tasks';
import history from 'router-history';
import settingsHelper from 'shared_helpers/settings_helper';
import * as text from 'text-content';

/**
 * Check if user session is alive on backend. If so, set user info to state.
 *
 * We lose redux state on page reloads, so this is hit when user object isn't in state anymore
 * to make sure if session is killed.
 */
export const checkSession = async () => {
  try {
    let user = await request({
      method: 'get',
      url: '/user/checkSession',
    });

    if (user instanceof Error || !user?.id) {
      return false;
    }

    store.dispatch({ type: userActionTypes.SET_USER_INFO, payload: user });

    if (!store.getState().activity.filter.users.includes(user.id)) {
      setActivityFilter({ type: 'user', val: user.id });
    }

    // Check and set some data.
    getUserColleagues(); // Get colleagues
    getUserConnections(); // Get colleagues at connected dealers
    getSettings();
    getDataIntegrationStatus();
    // getColumnsData({ loading: true });
    // setShowCampaign(); // Put back when we have a new active campaign.

    if (!store.getState().prospect.loading) {
      checkProspectLoadedData({}); // Get prospect data.
    }

    return true;
  } catch (err) {
    console.error('Error in checkSession', err);
    return false;
  }
};

/**
 * Get hashed and salted user id for helpcrunch.
 */
export const getHelpcrunchUUID = async () => {
  try {
    const uuid = await request({
      method: 'get',
      url: '/user/getgetHelpcrunchUUID/',
    });

    if (!uuid || uuid instanceof Error) {
      return null;
    }

    return uuid;
  } catch (err) {
    console.error('Error in getHelpcrunchUUID', err);
    return null;
  }
};

/**
 * Check if user/dealer has data integration.
 */
export const getDataIntegrationStatus = async () => {
  try {
    const data = await request({
      method: 'get',
      url: '/user/checkDataIntegrationStatus',
    });

    if (data instanceof Error) {
      return console.error('Error in getDataIntegrationStatus', data);
    }

    return store.dispatch({
      type: userActionTypes.SET_USER_DATA_INTEGRATION,
      payload: !!data,
    });
  } catch (err) {
    return console.error('Error in getDataIntegrationStatus', err);
  }
};

/**
 * Get data (user id) for restore link.
 *
 * @param payload.link
 */
export const getRestoreLinkData = async (payload) => {
  try {
    const data = await request({
      data: {
        link: payload.link,
      },
      method: 'get',
      url: '/user/getRestoreLinkData/',
    });

    if (!data || data instanceof Error) {
      return null;
    }

    return data;
  } catch (err) {
    console.error('Error in getRestoreLinkData', err);
    return null;
  }
};

/**
 * Get settings for logged in user.
 */
export const getSettings = async () => {
  try {
    const data = await request({
      method: 'get',
      url: '/settings/',
    });

    if (data instanceof Error || !data) {
      return console.error('Error in getSettings', data);
    }

    store.dispatch({
      type: userActionTypes.SET_USER_SETTINGS,
      payload: data,
    });
  } catch (err) {
    return console.error('Error in getSettings', err);
  }
};

/**
 * Return an object with user accesses.
 */
export const getUserAccess = () => {
  return {
    bigExcel: !!(
      Number.isFinite(store.getState()?.user?.info?.status) &&
      store.getState().user.info.status & settingsHelper.status.bigExcel
    ), // Large excel download.
    blockExcel: !!(
      Number.isFinite(store.getState()?.user?.info?.status) &&
      store.getState().user.info.status & settingsHelper.status.blockExcel
    ), // No excel download.
    customerData: !!store.getState().user.dataIntegration, // Display dealerSalesmenType & dealerSalesmen in Prospektera.
  };
};

/**
 * Get user colleagues on same dealer.
 */
export const getUserColleagues = async () => {
  try {
    let colleagues = await request({
      method: 'get',
      url: '/user/getUsersByDealerId',
    });

    if (colleagues instanceof Error) {
      return console.error('Error in getUserColleagues', colleagues);
    }

    colleagues = colleagues.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    return store.dispatch({
      type: userActionTypes.SET_USER_COLLEAGUES,
      payload: colleagues,
    });
  } catch (err) {
    return console.error('Error in getUserColleagues', err);
  }
};

/**
 * Get user connections, I.E. connected dealers and their users.
 */
export const getUserConnections = async () => {
  try {
    let connections = await request({
      method: 'get',
      url: '/user/hierarchy',
    });

    if (connections instanceof Error) {
      return console.error('Error in getUserColleagues', connections);
    }

    connections = connections.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    connections = connections.map((dealer) => {
      dealer.users = dealer.users.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      return dealer;
    });

    return store.dispatch({
      type: userActionTypes.SET_USER_CONNECTIONS,
      payload: connections,
    });
  } catch (err) {
    return console.error('Error in getUserColleagues', err);
  }
};

/**
 * Reset password
 *
 * @param payload.email
 */
export const resetPassword = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const data = await request({
      data: { email: payload.email },
      method: 'post',
      url: '/user/resetPassword/',
    });

    if (data instanceof Error) {
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    return showFlashMessage(tc.resetPasswordLinkSent, 'success');
  } catch (err) {
    return console.error('Error in resetPassword:', err, payload);
  }
};

/**
 * Save new password.
 *
 * @param payload.password
 */
export const savePassword = async (payload) => {
  try {
    return await request({
      data: { password: payload.password },
      method: 'put',
      url: '/user/savePassword',
    });
  } catch (err) {
    return console.error('Error in savePassword:', err);
  }
};

/**
 * Save new password from reset page.
 *
 * @param payload.link
 * @param payload.password
 * @param payload.userId
 */
export const savePasswordFromReset = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const data = await request({
      data: {
        link: payload.link,
        password: payload.password,
      },
      method: 'post',
      url: '/user/savePasswordFromReset/',
    });

    if (data instanceof Error) {
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    return showFlashMessage(tc.passwordIsUpdated, 'success');
  } catch (err) {
    console.error('Error in savePasswordFromReset:', err);
    return showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

/**
 * Send an application of interest.
 *
 * @param payload.company - string (optional)
 * @param payload.email - string
 * @param payload.orgNr - string
 * @param payload.name - string (optional)
 * @param payload.phone - string (optional)
 */
export const sendApplicationOfInterest = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload?.orgNr || !payload?.email) {
      return console.error(
        'Missing params in sendApplicationOfInterest',
        payload
      );
    }

    const data = await request({
      data: {
        company: payload.company,
        email: payload.email,
        name: payload.name,
        orgNr: payload.orgNr,
        phone: payload.phone,
      },
      method: 'post',
      url: '/user/applicationOfInterest/',
    });

    if (data instanceof Error) {
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    return showFlashMessage(tc.applicationOfInterestSent, 'success');
  } catch (err) {
    return console.error('Error in sendApplicationOfInterest:', err, payload);
  }
};

export const setModuleMode = async (val) => {
  if (val) {
    setNavigationStatus({
      val: 'hidden',
      skipUpdateSettings: true,
    });
  }
  store.dispatch({
    type: userActionTypes.SET_USER_MODULE_MODE,
    payload: val,
  });
};

/**
 * Update navigation status.
 *
 * @param payload.skipUpdateSettings
 * @param payload.val
 */
export const setNavigationStatus = async (payload) => {
  const user = store.getState().user;

  // Update store.
  store.dispatch({
    type: userActionTypes.SET_USER_NAVIGATION_STATUS,
    payload: payload.val,
  });

  // Update settings if conditions is filled.
  if (
    user.settings?.settings &&
    !user.responsiveMode &&
    !user.moduleMode &&
    user.navigationStatus !== 'hidden' &&
    !payload.skipUpdateSettings &&
    (payload.val === 'regular' || payload.val === 'minimize')
  ) {
    // We only update navigation status in settings when toggling between regular and minimized mode on larger/normal screen sizes.
    // When we're in responsive mode or module mode that has precedence and no changes to settings should be done.
    updateSettings({
      settings: {
        ...user.settings.settings,
        navigation: {
          ...user.settings.settings.navigation,
          status: payload.val,
        },
      },
    });
  }
};

export const setResponsiveMode = async (val) => {
  store.dispatch({
    type: userActionTypes.SET_USER_RESPONSIVE_MODE,
    payload: val,
  });
};

/**
 * Set which settings page and/or section should be active.
 * (Currently we don't use the settingsActivePage value anywhere,
 * but we might want to change style etc. for active page so keep it.)
 *
 * @param payload.page - string
 * @param payload.section - string
 */
export const setSettingsActivePageOrSection = (payload) => {
  if (payload.hasOwnProperty('page')) {
    store.dispatch({
      type: userActionTypes.SET_USER_SETTINGS_ACTIVE_PAGE,
      payload: payload.page,
    });
  }

  if (payload.hasOwnProperty('section')) {
    store.dispatch({
      type: userActionTypes.SET_USER_SETTINGS_ACTIVE_SECTION,
      payload: payload.section,
    });
  }
};

/**
 * Find out if a campaign should be hidden or displayed.
 * Use when we have a campaign active.
 * Update params to backend, also update backend.
 */
export const setShowCampaign = async () => {
  try {
    const data = await request({
      data: {
        campaign: 'easterEggHunt2022',
        limit: 5,
      },
      method: 'get',
      url: '/settings/showCampaign/',
    });

    if (data instanceof Error) {
      return console.error('Error in setShowCampaign', data);
    }

    store.dispatch({
      type: userActionTypes.SET_USER_SHOW_CAMPAIGN,
      payload: data.showCampaign,
    });
  } catch (err) {
    console.error('Error in setShowCampaign', err);
  }
};

export const setShowCampaignInfo = (val) => {
  store.dispatch({
    type: userActionTypes.SET_USER_SHOW_CAMPAIGN_INFO,
    payload: !!val,
  });
};

export const setMapControlId = (id) => {
  store.dispatch({
    type: userActionTypes.SET_USER_MAP_CONTROL_ID,
    payload: id,
  });
};

export const setShowSettings = (val) => {
  store.dispatch({
    type: userActionTypes.SET_USER_SHOW_SETTINGS,
    payload: !!val,
  });
};

/**
 * Give GDPR consent.
 */
export const updateGdpr = async () => {
  try {
    const user = await request({
      method: 'get',
      url: '/user/updategdpr',
    });

    store.dispatch({ type: userActionTypes.SET_USER_INFO, payload: user });

    getUserColleagues(); // Get colleagues
    getUserConnections(); // Get colleagues at connected dealers
    checkProspectLoadedData({}); // Get prospect data.

    return setTimeout(() => {
      history.push('/');
    }, 2000);
  } catch (err) {
    return console.error('Error in updateGdpr', err);
  }
};

/**
 * Save new settings.
 * Only for settings in mongo "settings" collection, not password, language etc.
 *
 * @param payload.settings - object
 * @param payload.skipGetSettings - bool (optional)
 */
export const updateSettings = async (payload) => {
  try {
    await request({
      data: { settings: payload.settings },
      method: 'put',
      url: '/settings',
    });

    if (!payload.skipGetSettings) {
      await getSettings();
    }
  } catch (err) {
    return console.error('Error in updateSettings', err);
  }
};

/**
 * Update user row.
 * Only update a selected range of properties, everything shouldn't be updated here (like password).
 * But feel free to add on.
 *
 * @param payload.img
 * @param payload.lang
 * @param payload.phone
 */
export const updateUser = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    const user = await request({
      data: {
        changes: payload,
      },
      method: 'put',
      url: '/user',
    });

    if (user instanceof Error) {
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    store.dispatch({ type: userActionTypes.SET_USER_INFO, payload: user });
    return showFlashMessage(tc.changesSaved, 'success');
  } catch (err) {
    console.error('Error in updateUser', err);
    return showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

/**
 * Send login request, set user data to store state.
 *
 * @param credentials.email (string)
 * @param credentials.password (string)
 */
export const userLogin = async (credentials) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !credentials ||
      (credentials && !credentials.email) ||
      (credentials && !credentials.password)
    ) {
      console.error('Missing params in userLogin', credentials);
      return history.push('/');
    }

    let user = await request({
      data: credentials,
      method: 'post',
      url: '/login',
    });

    if (user instanceof Error || !user?.id) {
      if (user.response.status === 500) {
        showFlashMessage(tc.couldNotLogin, 'info');
        return { err: 'max_login' }
      }
      return showFlashMessage(tc.couldNotLogin, 'info');
    }

    store.dispatch({ type: userActionTypes.SET_USER_INFO, payload: user });
    history.push('');

    // Set and get some data.
    setActivityFilter({ type: 'user', val: user.id });
    getUserColleagues();
    getUserConnections();
    getSettings();
    getDataIntegrationStatus();
    getColumnsData({ loading: true });
    // setShowCampaign(); // Put back when we have a new active campaign.

    // Give the other requests a chance to finish before retrieving this data
    // its heavy lifting and dashboard might do a lot of backend calls.
    setTimeout(() => {
      checkProspectLoadedData({});
    }, 1000);

    return true;
  } catch (err) {
    console.error('Error in userLogin', err);
    return showFlashMessage(tc.couldNotLogin, 'info');
  }
};

/**
 * Send logout request.
 */
export const userLogout = async () => {
  try {
    await request({
      method: 'get',
      url: '/logout',
    })

    store.dispatch({ type: rootActionTypes.CLEAR_STATE });

    request({
      method: 'get',
      url: '/logoutClearCache',
    })

    setTimeout(() => {
      history.push('');
    }, 500);
    // return idbHelper.deleteDb();
  } catch (err) {
    return console.error('Error in userLogout', err);
  }
};
